import { RightOutlined } from '@ant-design/icons'
import { Button, Typography } from 'antd'
import React, { PropsWithChildren } from 'react'
import styled from 'styled-components'

type Props = {
  title?: string | React.ReactElement
  showAllLink?: string
} & PropsWithChildren

export const HomeSection: React.FC<Props> = ({ title, children, showAllLink }) => {
  return (
    <Wrapper>
      <Title>
        {title ? React.isValidElement(title) ? title : <Typography.Title level={4}>{title}</Typography.Title> : null}
        {showAllLink && (
          <Button type="link" href={showAllLink}>
            See All <RightOutlined />
          </Button>
        )}
      </Title>
      {children}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0 12px;
`

const Title = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;

  .ant-typography {
    margin: 0;
  }

  a.ant-btn {
    padding: 0;
  }
`
