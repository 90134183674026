import { gql, QueryHookOptions, useQuery } from '@apollo/client'
import { Query, QueryContactUsersArgs } from '../gen/models'

export const ContactFragment = gql`
  fragment ContactFragment on Contact {
    id
    name
    title
    email
  }
`

export const ContactUserFragment = gql`
  fragment ContactUserFragment on ContactUserWrapper {
    user {
      id
    }
    contact {
      ...ContactFragment
    }
  }
  ${ContactFragment}
`

const CONTACT_USERS_QUERY = gql`
  query contactUsers($searchText: String) {
    contactUsers(searchText: $searchText) {
      ...ContactUserFragment
    }
  }
  ${ContactUserFragment}
`

export function useContactUsersQuery(options: QueryHookOptions<Pick<Query, 'contactUsers'>, QueryContactUsersArgs>) {
  return useQuery(CONTACT_USERS_QUERY, options)
}
