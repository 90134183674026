import { Alert, Button, Col, Form, Input, Row, Space, Spin, message } from 'antd'
import FormItem from 'antd/es/form/FormItem'
import axios from 'axios'
import jwtDecode from 'jwt-decode'
import React, { useCallback, useEffect, useState } from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import styled from 'styled-components'
// @ts-ignore
import Env from '../../../api/gen/Env'
import { useDebounce } from '../../components/useDebounce'
import { PasswordPolicy, passwordRules } from './PasswordPolicy'

declare var _ps: any

const AGREEMENT_KEY = 'cx-by-curvo'
const apiBaseUrl = Env.api.replace('/graphql', '')

export const OnboardingForm: React.FC = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const token = searchParams.get('token')
  const [formInstance] = Form.useForm<any>()
  const [error, setError] = useState<string>()
  const [submitting, setSubmitting] = useState<boolean>(false)
  const [isAgree, setIsAgree] = useState(false)

  const firstName = Form.useWatch('firstName', formInstance)
  const lastName = Form.useWatch('lastName', formInstance)

  const debouncedFirstName = useDebounce(firstName, 500)
  const debouncedLastName = useDebounce(lastName, 500)

  useEffect(() => {
    _ps('set', 'custom_data', {
      first_name: debouncedFirstName,
      last_name: debouncedLastName,
    })
  }, [debouncedFirstName, debouncedLastName])

  useEffect(() => {
    _ps('create', '721e69ab-720f-4525-af86-304abdd0a676', {
      dynamic: true,
    })

    _ps('load', AGREEMENT_KEY, {
      container_selector: 'pactsafe-contract',
      signer_id_selector: 'email',
    })

    _ps.on('all', () => {
      if (_ps.getByKey(AGREEMENT_KEY) && _ps.getByKey(AGREEMENT_KEY).allChecked()) {
        setIsAgree(true)
      } else {
        setIsAgree(false)
      }
    })

    return () => {
      _ps.off()
      _ps('remove')
    }
  }, [])

  useEffect(() => {
    if (!token) {
      setError('The invitation link is invalid!')
      return
    }
    setSubmitting(true)
    axios
      .post(`${apiBaseUrl}/non-login/aperture/onboarding/prepare`, {
        onboardingToken: token,
      })
      .then(resp => {
        if (!resp.data.access) {
          try {
            const payload: { email: string; firstName: string; lastName: string } = jwtDecode(token)
            if (!payload.email) {
              setError('The invitation link is invalid!')
              return
            }
            formInstance.setFieldsValue({ ...payload })
          } catch {
            setError('The invitation link is invalid!')
            return
          }
        } else {
          navigate('/auth')
        }
      })
      .catch(e => {
        console.error(e)
        setError('The invitation link is invalid!')
      })
      .finally(() => {
        setSubmitting(false)
      })
  }, [token, formInstance, navigate])

  const handleSubmit = useCallback(
    (values: any) => {
      setSubmitting(true)
      axios
        .post(`${apiBaseUrl}/non-login/aperture/onboarding`, {
          onboardingToken: token,
          ...values,
        })
        .then(() => {
          message.success({ content: "Welcome to Curvo's Project Aperture!" })
          navigate('/auth')
        })
        .catch(e => setError(e.message))
        .finally(() => {
          setSubmitting(false)
        })
    },
    [token, navigate],
  )

  return (
    <Form
      form={formInstance}
      onFinish={handleSubmit}
      disabled={!!error || submitting}
      layout="vertical"
      style={{ width: '100%', display: 'flex' }}>
      {submitting && (
        <LoadingWrapper>
          <Spin spinning />
        </LoadingWrapper>
      )}
      <Space size={16} direction="vertical" style={{ width: '100%' }}>
        {error && <Alert type="error" message={error} />}
        <StyledFormItem name="email" rules={[{ required: true, message: 'Please input your email' }]} label="Email">
          <Input id="email" disabled placeholder="Email" />
        </StyledFormItem>
        <Row gutter={16}>
          <Col span={12}>
            <StyledFormItem
              name="firstName"
              rules={[{ required: true, whitespace: true, message: 'Please input your first name' }]}
              label="First Name">
              <Input placeholder="First Name" />
            </StyledFormItem>
          </Col>
          <Col span={12}>
            <StyledFormItem
              name="lastName"
              rules={[{ required: true, whitespace: true, message: 'Please input your last name' }]}
              label="Last Name">
              <Input placeholder="Last Name" />
            </StyledFormItem>
          </Col>
        </Row>
        <StyledFormItem
          name="provider"
          rules={[{ required: true, whitespace: true, message: 'Please input your provider' }]}
          label="Provider">
          <Input placeholder="Provider" />
        </StyledFormItem>
        <StyledFormItem
          name="position"
          rules={[{ required: true, whitespace: true, message: 'Please input your role or position' }]}
          label="Role or Position">
          <Input placeholder="Role or Position" />
        </StyledFormItem>
        <PasswordFormItem
          label="Password"
          name="password"
          required
          rules={[
            () => ({
              validator(_, value) {
                const normalizedValue = value ? value.trim() : null
                const failed = passwordRules.filter(r => !new RegExp(r.regex).test(normalizedValue))
                if (
                  failed.length > 0 &&
                  (failed.some(r => r.message === passwordRules[0].message) ||
                    failed.filter(r => r.message !== passwordRules[0].message).length > 1)
                ) {
                  return Promise.reject(new Error(failed.map(f => f.message).join('|')))
                }
                return Promise.resolve()
              },
            }),
          ]}>
          <Input.Password placeholder="Password" />
        </PasswordFormItem>
        <StyledFormItem
          name="confirmPassword"
          dependencies={['password']}
          required
          rules={[
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve()
                }
                return Promise.reject(new Error('Confirm Password does not match!'))
              },
            }),
          ]}
          label="Confirm Password">
          <Input.Password placeholder="Re-enter your password" />
        </StyledFormItem>
        <Form.Item noStyle shouldUpdate>
          {({ getFieldError }) =>
            getFieldError('password').length > 0 && <PasswordPolicy errors={getFieldError('password')} />
          }
        </Form.Item>
        <TermAndPolicyAgreement id="pactsafe-contract" />
        <StyledFormItem>
          <Button
            disabled={!!error || !isAgree}
            type="primary"
            htmlType="submit"
            style={{ width: '100%', marginTop: '16px' }}>
            Create My Account
          </Button>
        </StyledFormItem>
        <div style={{ textAlign: 'center', width: '100%' }}>
          Already have an account? <Link to="/auth">Log in</Link>
        </div>
      </Space>
    </Form>
  )
}

const StyledFormItem = styled(FormItem)`
  width: 100%;
  margin-bottom: 0;

  .ant-input-disabled {
    color: var(--color-neutral-90);
  }
`

const PasswordFormItem = styled(StyledFormItem)`
  .ant-form-item-explain {
    display: none;
  }
`

const TermAndPolicyAgreement = styled.div`
  a {
    color: var(--color-neutral-90);
  }

  .ps-contract {
    padding-bottom: 0.5rem !important;
  }

  .ps-checkbox-container {
    font-size: 1rem !important;
  }
`

const LoadingWrapper = styled.div`
  background-color: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
`
