import React, { useState, useContext } from 'react'
import lodash from 'lodash'
import * as reactRouterDom from 'react-router-dom'
import { Space, Button, Row, Col, Skeleton, Layout, Typography } from 'antd'
import styled from 'styled-components'
import { PlusOutlined } from '@ant-design/icons/lib'
import moment from 'moment'
import { BreadcrumbProps } from 'antd/es/breadcrumb'
import { Helmet } from 'react-helmet'
import { breadcrumbItemRender } from '../../../utils'
import { useOrganizationQuery, Organization, useCustomerQuery, MeContext } from '../../../api'
import { Card } from '../../components/Card'
import { UserWithRole } from '../../components/UserCommon'
import { OrganizationList } from '../../components/OrganizationCommon'
import { BaseManagePageLayout } from '../BaseManagePageLayout'
import { DraggableSidebar } from '../../components/DraggableSidebar'
import { OrganizationsTree } from '../../components/OrganizationsTree'
import { AddOrganizationToCustomerModal } from '../Client/forms/AddOrganizationToCustomerModal'
import { UserAvatar } from '../../components/UserAvatar'
import { PermissionPlaceholder } from '../../components/PermissionPlaceholder'
import { UpdateOrganizationModal } from './UpdateOrganizationModal'
import { OrganizationUsersModal } from './OrganizationUsersModal'

export const OrganizationDetailPage: React.FC = () => {
  const { data: meData } = useContext(MeContext)
  const { organizationId, customerId } = reactRouterDom.useParams<{ organizationId: string; customerId: string }>()
  const { data, loading } = useOrganizationQuery({
    variables: { input: { id: organizationId! } },
    skip: lodash.isNil(organizationId),
  })
  const navigate = reactRouterDom.useNavigate()

  const [showUpdateOrganizationModal, setShowUpdateOrganizationModal] = useState<{
    visible: boolean
    org?: Organization
  }>({
    visible: false,
    org: undefined,
  })
  const [organizationUsersModal, setOrganizationUsersModal] = useState<{
    visible: boolean
    selectedOrg?: Organization
  }>({ visible: false, selectedOrg: undefined })
  const [showNewOrganizationForm, setShowNewOrganizationForm] = useState(false)

  const organization = data && data.organization
  const subOrganizations = (organization && organization.subOrganizations) || []
  const topUsers = (organization && organization.topUsers) || []

  const me = meData && meData.me && meData.me.user

  const customerRequest = useCustomerQuery({
    variables: { input: { id: customerId! } },
    skip: lodash.isNil(customerId),
  })
  const customer = customerRequest?.data?.customer

  const topUserExtra = [
    <Button onClick={() => setOrganizationUsersModal({ visible: true, selectedOrg: organization })}>
      Manage Users
    </Button>,
  ]
  const organizationTitleExtras = [
    <>
      <Row>
        <AddSubOrgCol flex={1}>
          <PermissionPlaceholder permissionTarget="write" organizationId={organization?.id}>
            <Button
              key="add-sub-org"
              onClick={() => {
                setShowNewOrganizationForm(true)
              }}>
              Add Sub Organization
            </Button>
          </PermissionPlaceholder>
        </AddSubOrgCol>
      </Row>
      {organization && organization.topPowerUsers && organization.topPowerUsers.length ? (
        <Row>
          <TopPowerUsers>
            {organization.topPowerUsers.slice(0, 3).map(powerUser => (
              <reactRouterDom.Link to={`/manage/users/${powerUser.id}`}>
                <UserAvatar user={powerUser} size={32} />
              </reactRouterDom.Link>
            ))}
            <Button
              shape="circle"
              onClick={() => {
                navigate(`/manage/users?organizationId=${organizationId}`)
              }}>
              {organization.topPowerUsers.length > 3 ? `+${organization.topPowerUsers.length - 3}` : ''}
            </Button>
            <AddUserButton
              shape="circle"
              icon={<PlusOutlined />}
              onClick={() => setOrganizationUsersModal({ visible: true, selectedOrg: organization })}
            />
          </TopPowerUsers>
        </Row>
      ) : (
        ''
      )}
    </>,
  ]

  const breadcrumbOptions: BreadcrumbProps = {
    itemRender: breadcrumbItemRender,
    routes:
      customer && organization
        ? [
            {
              path: meData?.me.isAdmin ? '/manage/customer' : '',
              breadcrumbName: 'All Customers',
            },
            {
              path: `/manage/customer/${customer.id}`,
              breadcrumbName: customer.name,
            },
            ...organization.allParents.map(parent => ({
              path: `/manage/customer/${customer.id}/organization/${parent.id}`,
              breadcrumbName: parent.name,
            })),
            {
              path: `/manage/customer/${customer.id}/organization/${organization.id}`,
              breadcrumbName: organization.name,
            },
          ]
        : [],
  }

  return (
    <>
      <Helmet>
        <title>{organization?.name ?? 'Organization'}</title>
      </Helmet>
      <BaseManagePageLayout
        title={(organization && organization.name) || 'Organization'}
        extra={organizationTitleExtras}
        breadcrumb={breadcrumbOptions}
        subTitle={`System ∙ Updated ${organization ? moment(organization.updatedAt).calendar() : ''}`}
        sidebar={
          <DraggableSidebar title={customer?.name || ''}>
            {customer ? <OrganizationsTree customerId={customer.id} organizationId={organizationId} /> : ''}
          </DraggableSidebar>
        }>
        {/* <Row gutter={[16, 16]}> */}
        {/*   <Col span={6}> */}
        {/*     <Card extra={[<InfoCircleFilled />]}> */}
        {/*       <InformationLabel>Total Spend</InformationLabel> */}
        {/*       <InformationData>$636,838</InformationData> */}
        {/*       <InformationHighlight>Down 13.4% from 2018</InformationHighlight> */}
        {/*     </Card> */}
        {/*   </Col> */}
        {/*   <Col span={6}> */}
        {/*     <Card extra={[<InfoCircleFilled />]}> */}
        {/*       <InformationLabel>Total Spend</InformationLabel> */}
        {/*       <InformationData>$636,838</InformationData> */}
        {/*       <InformationHighlight>Down 13.4% from 2018</InformationHighlight> */}
        {/*     </Card> */}
        {/*   </Col> */}
        {/*   <Col span={6}> */}
        {/*     <Card extra={[<InfoCircleFilled />]}> */}
        {/*       <InformationLabel>Total Spend</InformationLabel> */}
        {/*       <InformationData>$636,838</InformationData> */}
        {/*       <InformationHighlight>Down 13.4% from 2018</InformationHighlight> */}
        {/*     </Card> */}
        {/*   </Col> */}
        {/*   <Col span={6}> */}
        {/*     <Card extra={[<InfoCircleFilled />]}> */}
        {/*       <InformationLabel>Total Spend</InformationLabel> */}
        {/*       <InformationData>$636,838</InformationData> */}
        {/*       <InformationHighlight>Down 13.4% from 2018</InformationHighlight> */}
        {/*     </Card> */}
        {/*   </Col> */}
        {/* </Row> */}
        <Row gutter={[16, 16]} style={{ marginBottom: '16px' }}>
          <Col span={16}>
            {loading ? (
              <Skeleton active={true} />
            ) : (
              <OrganizationInfoCard
                organization={organization}
                onUpdateButtonClick={() => setShowUpdateOrganizationModal({ visible: true, org: organization })}
              />
            )}
          </Col>
          <Col span={8}>
            {loading ? (
              <Skeleton active={true} />
            ) : (
              <Card title="Top Users" extra={topUserExtra} width="100%" height="100%">
                <Layout style={{ background: '#fff' }}>
                  <UserListSpace direction="vertical" size={24}>
                    {topUsers.map(user => (
                      <UserWithRole user={user} key={user.id} />
                    ))}
                  </UserListSpace>
                  <AllUsersLink
                    type="link"
                    onClick={() => setOrganizationUsersModal({ visible: true, selectedOrg: organization })}>
                    View all users
                  </AllUsersLink>
                </Layout>
              </Card>
            )}
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Typography.Title level={4}>Sub Organizations ({subOrganizations.length})</Typography.Title>
          </Col>
          <Col span={12}>
            <Button
              style={{ float: 'right' }}
              key="add-sub-organization"
              type="default"
              onClick={() => setShowNewOrganizationForm(true)}>
              Add Sub Organization
            </Button>
          </Col>
        </Row>
        {loading || !customer ? (
          <Skeleton active={true} />
        ) : (
          <OrganizationList
            customer={customer}
            organizations={subOrganizations}
            onAddNewOrganization={() => {
              setShowNewOrganizationForm(true)
            }}
            onAddUser={(org: Organization) => {
              setOrganizationUsersModal({ visible: true, selectedOrg: org })
            }}
            onEditOrganization={(org: Organization) => {
              setShowUpdateOrganizationModal({ visible: true, org })
            }}
          />
        )}
        {customer && (
          <UpdateOrganizationModal
            customer={customer}
            open={showUpdateOrganizationModal.visible}
            onCancel={() => setShowUpdateOrganizationModal({ visible: false, org: undefined })}
            organization={showUpdateOrganizationModal.org}
            onSuccess={() => {
              setShowUpdateOrganizationModal({ visible: false, org: undefined })
            }}
          />
        )}
        {organizationUsersModal.selectedOrg && me && (
          <OrganizationUsersModal
            customerId={me.customerId}
            organization={organizationUsersModal.selectedOrg}
            open={organizationUsersModal.visible}
            onCancel={() =>
              setOrganizationUsersModal({ visible: false, selectedOrg: organizationUsersModal.selectedOrg })
            }
            onSuccess={() =>
              setOrganizationUsersModal({ visible: false, selectedOrg: organizationUsersModal.selectedOrg })
            }
          />
        )}
        {customer && (
          <AddOrganizationToCustomerModal
            parentId={organization?.id}
            customer={customer}
            open={showNewOrganizationForm}
            onCancel={() => setShowNewOrganizationForm(false)}
            onFinished={() => {
              setShowNewOrganizationForm(false)
            }}
          />
        )}
      </BaseManagePageLayout>
    </>
  )
}

const OrganizationInfoCard: React.FC<{ organization?: Organization; onUpdateButtonClick: () => any }> = ({
  organization,
  onUpdateButtonClick,
}) => {
  const organizationInfoExtra = [
    <PermissionPlaceholder permissionTarget="write" organizationId={organization?.id}>
      <Button key="organization-update" onClick={onUpdateButtonClick}>
        Update
      </Button>
    </PermissionPlaceholder>,
  ]
  return (
    <Card title="Organization Info" width="100%" extra={organizationInfoExtra} height="100%">
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <InformationLabel>Address</InformationLabel>
          <InformationData>{organization && organization.address}</InformationData>
        </Col>
        <Col span={12}>
          <InformationLabel>Total Users</InformationLabel>
          <InformationData>{(organization && organization.totalUsers) || '0'}</InformationData>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <InformationLabel>Phone Number</InformationLabel>
          <InformationData>{(organization && organization.phoneNumber) || 'N/A'}</InformationData>
        </Col>
      </Row>
      {organization && organization.website ? (
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <InformationLabel>Website</InformationLabel>
            <InformationData>
              <a href={organization.website} target="_blank">
                {organization.website}
              </a>
            </InformationData>
          </Col>
        </Row>
      ) : (
        ''
      )}
    </Card>
  )
}

const InformationLabel = styled.div`
  text-transform: uppercase;
  font-size: 10px;
`

const InformationData = styled.div`
  font-size: 14px;
  font-weight: 600;
`

const UserListSpace = styled(Space)`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
`

// const InformationHighlight = styled.div`
//   font-size: 12px;
//   color: #00cc99;
// `

const AllUsersLink = styled(Button)`
  margin-top: 30px;
  text-align: left;
`

const TopPowerUsers = styled(Col)`
  .ant-avatar {
    margin-right: 2px;
  }
`

const AddUserButton = styled(Button)`
  margin-left: 5px;
`

const AddSubOrgCol = styled(Col)`
  text-align: right;
  margin-bottom: 10px;
`
