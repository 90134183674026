import { useAbility } from '@casl/react'
import { message } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { ContractFilterStatus, MeContext, OrderDirection, UserPermissionFeature, useContractsQuery } from '../../../api'
import { AbilityContext } from '../../../api/components/Can'
import { useCreateSupportTicketMutation } from '../../../api/hooks/support-ticket'
import { CreateItemCard } from '../components/CreateItemCard'
import { HomeCarousel } from '../components/HomeCarousel'
import { HomeSection } from '../components/HomeSection'
import { ContractCard } from './ContractCard'

const SUPPORT_TICKET_KEY = 'home_contract_st_requested'

export const ContractsSection: React.FC = () => {
  const navigate = useNavigate()
  const { data: meData } = useContext(MeContext)
  const ability = useAbility(AbilityContext)
  const hasPermission = ability.can('access', UserPermissionFeature.Contracts)
  const userSupportTicketKey = `${SUPPORT_TICKET_KEY}_${meData?.me.user.id}`
  const [requested, setRequested] = useState<boolean | null>(null)

  const { data, loading } = useContractsQuery({
    variables: {
      input: {
        status: ContractFilterStatus.Active,
        sort: {
          field: 'c.updatedAt',
          direction: OrderDirection.Desc,
        },
        limit: 8,
      },
    },
    skip: !hasPermission,
    fetchPolicy: 'network-only',
  })
  const [createSupportTicket, { loading: ticketLoading }] = useCreateSupportTicketMutation({
    onCompleted: () => {
      window.localStorage.setItem(userSupportTicketKey, Date.now().toString())
      setRequested(true)
      message.success(
        'We acknowledged your request. Customer Success team will contact you to provide more information.',
      )
    },
    onError: () => {
      message.error('Your request was failed. Please check your network or contact our support to help you fix it.')
    },
  })

  useEffect(() => {
    if (!hasPermission && requested === null) {
      setRequested(!!window.localStorage.getItem(userSupportTicketKey))
    }
  }, [hasPermission, requested, userSupportTicketKey])

  return (
    <HomeSection
      title="Contracts"
      showAllLink={hasPermission && data?.contracts.length ? '/analytics/contracts' : undefined}>
      <HomeCarousel
        loading={loading}
        items={hasPermission ? data?.contracts || [] : []}
        renderItem={contract => <ContractCard contract={contract} />}
        slidesToShow={2}
        extraItem={
          <CreateItemCard
            title="Create a Contract"
            description={
              requested
                ? 'We acknowledged your request. Customer Success team will contact you to provide more information.'
                : 'Track spend, savings, rebate & tier eligibility by adding contract information.'
            }
            loading={ticketLoading}
            requested={!!requested}
            onClick={() => {
              if (hasPermission) {
                navigate('/analytics/contracts/create')
              } else {
                createSupportTicket({ variables: { input: { subject: 'Request for access to Contracts on CX' } } })
              }
            }}
          />
        }
      />
    </HomeSection>
  )
}
