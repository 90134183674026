import { gql, QueryHookOptions, useQuery } from '@apollo/client'
import { Query, QueryAllowedGicTypeOnesArgs, QueryAllowedGicTypeTwosArgs, QueryGicsArgs } from '../gen/models'

export const GicFragment = gql`
  fragment GicFragment on Gic {
    id
    name
  }
`

export const GicTypeOneFragment = gql`
  fragment GicTypeOneFragment on GicTypeOne {
    id
    name
  }
`

export const GicTypeTwoFragment = gql`
  fragment GicTypeTwoFragment on GicTypeTwo {
    id
    name
  }
`

const GICS_QUERY = gql`
  query gics($searchText: String) {
    gics(searchText: $searchText) {
      ...GicFragment
    }
  }
  ${GicFragment}
`

export const useGicsQuery = (options: QueryHookOptions<Pick<Query, 'gics'>, QueryGicsArgs>) => {
  return useQuery(GICS_QUERY, options)
}

const ALLOWED_GIC_TYPE_ONES_QUERY = gql`
  query allowedGicTypeOnes($gicId: Int!) {
    allowedGicTypeOnes(gicId: $gicId) {
      ...GicTypeOneFragment
    }
  }
  ${GicTypeOneFragment}
`

export const useAllowedGicTypeOnesQuery = (
  options: QueryHookOptions<Pick<Query, 'allowedGicTypeOnes'>, QueryAllowedGicTypeOnesArgs>,
) => {
  return useQuery(ALLOWED_GIC_TYPE_ONES_QUERY, options)
}

const ALLOWED_GIC_TYPE_TWOS_QUERY = gql`
  query allowedGicTypeTwos($typeOneId: String!) {
    allowedGicTypeTwos(typeOneId: $typeOneId) {
      ...GicTypeTwoFragment
    }
  }
  ${GicTypeTwoFragment}
`

export const useAllowedGicTypeTwosQuery = (
  options: QueryHookOptions<Pick<Query, 'allowedGicTypeTwos'>, QueryAllowedGicTypeTwosArgs>,
) => {
  return useQuery(ALLOWED_GIC_TYPE_TWOS_QUERY, options)
}
