import { gql, MutationHookOptions, QueryHookOptions, useMutation, useQuery } from '@apollo/client'
import {
  BasketInsightsArgs,
  Maybe,
  Mutation,
  MutationActivateSourcingPlanArgs,
  MutationAddSourcingPlanBasketArgs,
  MutationAddSourcingPlanBasketStakeholderArgs,
  MutationAddSourcingPlanStakeholderArgs,
  MutationCancelCategoryConsultRequestArgs,
  MutationDeleteSourcingPlanArgs,
  MutationHideSourcingPlanBasketArgs,
  MutationPinSourcingPlanBasketArgs,
  MutationRemoveSourcingPlanBasketArgs,
  MutationRemoveSourcingPlanBasketStakeholderArgs,
  MutationRemoveSourcingPlanStakeholderArgs,
  MutationRequestCategoryConsultArgs,
  MutationSendBasketStakeholdersSurveyArgs,
  MutationSendSourcingPlanStakeholdersSurveyArgs,
  MutationSnoozeSourcingPlanBasketArgs,
  MutationStartSourcingPlanArgs,
  MutationUpdateSourcingPlanArgs,
  MutationUpdateSourcingPlanBasketArgs,
  MutationUpdateSourcingPlanBasketStakeholderArgs,
  MutationUpdateSourcingPlanStakeholderArgs,
  Query,
  QuerySourcingPlanArgs,
  QuerySourcingPlanBasketBaseArgs,
  QuerySourcingPlanBasketBasesArgs,
  QuerySourcingPlansArgs,
} from '../gen/models'
import { SimpleBasketFragment } from './baskets'
import { ContactFragment } from './contact'
import { PowerUserListFragment } from './users'

export const SourcingPlanFragment = gql`
  fragment SourcingPlanFragment on SourcingPlan {
    id
    customerId
    authorId
    name
    description
    status
    savingsGoal
    targetStart
    targetCompletion
    facilities
  }
`

export const BasketLikelihoodFragment = gql`
  fragment BasketLikelihoodFragment on BasketLikelihood {
    customerId
    catalogId
    totalSpend
    curvo25
    curvo50
    curvo75
    curvo90
    percentiles
    priceScore
    targetPriceScore
    priceScoreLikelihoods
  }
`

export const SourcingPlanBasketFragment = gql`
  fragment SourcingPlanBasketFragment on SourcingPlanBasket {
    id
    sourcingPlanId
    basketId
    totalSpend
    targetSpend
    targetSavings
    type
    support
    snoozeToDate
    hide
    pin
  }
`

export const SourcingPlanStakeholderFragment = gql`
  fragment SourcingPlanStakeholderFragment on SourcingPlanStakeHolder {
    id
    user {
      firstName
      lastName
      title
      email
    }
    contact {
      ...ContactFragment
    }
    sourcingPlanId
    role
    support
    supportDate
    supportSurveyDate
  }
  ${ContactFragment}
`

export const SourcingPlanBasketStakeholderFragment = gql`
  fragment SourcingPlanBasketStakeholderFragment on SourcingPlanBasketsStakeholders {
    id
    user {
      firstName
      lastName
      title
      email
    }
    contact {
      ...ContactFragment
    }
    sourcingPlanBasketId
    role
    support
    supportSurveyDate
    supportDate
  }
  ${ContactFragment}
`

export const SourcingPlanBasketBaseFragment = gql`
  fragment SourcingPlanBasketBaseFragment on SourcingPlanBasketBase {
    inProgressSourcingPlan {
      id
      name
      facilities
      status
    }
    basket {
      ...SimpleBasketFragment

      likelihoods {
        ...BasketLikelihoodFragment
      }
      insights {
        sourcingPlanSuppliers {
          targetPercentile
          updatedAt
        }
        totalSpend
      }
    }
    sourcingPlanBasket {
      ...SourcingPlanBasketFragment
    }
  }
  ${SimpleBasketFragment}
  ${BasketLikelihoodFragment}
  ${SourcingPlanBasketFragment}
`

export const CategoryConsultRequestFragment = gql`
  fragment CategoryConsultRequestFragment on CategoryConsultRequest {
    id
    targetSpendLikelihood
    estimatedComplexity
    stakeholderSupport
    suppliers
    status
  }
`

const SOURCING_PLANS_QUERY = gql`
  query sourcingPlans($input: GetSourcingPlansByCriteriaInput) {
    sourcingPlans(input: $input) {
      ...SourcingPlanFragment
    }
  }
  ${SourcingPlanFragment}
`

export function useSourcingPlansQuery(options: QueryHookOptions<Pick<Query, 'sourcingPlans'>, QuerySourcingPlansArgs>) {
  return useQuery(SOURCING_PLANS_QUERY, options)
}

const CREATE_SOURCING_PLAN_MUTATION = gql`
  mutation startSourcingPlan($input: StartSourcingPlanInput!) {
    startSourcingPlan(input: $input) {
      ...SourcingPlanFragment
    }
  }
  ${SourcingPlanFragment}
`

export function useStartSourcingPlanMutation(
  options?: MutationHookOptions<Pick<Mutation, 'startSourcingPlan'>, MutationStartSourcingPlanArgs>,
) {
  return useMutation(CREATE_SOURCING_PLAN_MUTATION, options)
}

const UPDATE_DRAFT_SOURCING_PLAN_MUTATION = gql`
  mutation updateSourcingPlan($input: UpdateSourcingPlanInput!) {
    updateSourcingPlan(input: $input) {
      ...SourcingPlanFragment
    }
  }
  ${SourcingPlanFragment}
`

export function useUpdateSourcingPlanMutation(
  options?: MutationHookOptions<Pick<Mutation, 'updateSourcingPlan'>, MutationUpdateSourcingPlanArgs>,
) {
  return useMutation(UPDATE_DRAFT_SOURCING_PLAN_MUTATION, options)
}

const SOURCING_PLAN_QUERY = gql`
  query sourcingPlan($id: String!) {
    sourcingPlan(id: $id) {
      ...SourcingPlanFragment
      support
      baskets {
        type
        targetSpend
        targetSavings
        totalSpend
        id
        basket {
          ...SimpleBasketFragment
        }
      }
      sharedUsers {
        id
        user {
          id
          email
        }
        type
      }
      stakeholders {
        ...SourcingPlanStakeholderFragment
      }
      author {
        powerUserList {
          ...PowerUserListFragment
        }
      }
      isEditable
    }
  }
  ${SourcingPlanFragment}
  ${SimpleBasketFragment}
  ${SourcingPlanStakeholderFragment}
  ${PowerUserListFragment}
`

export function useSourcingPlanQuery(options: QueryHookOptions<Pick<Query, 'sourcingPlan'>, QuerySourcingPlanArgs>) {
  return useQuery(SOURCING_PLAN_QUERY, options)
}

const SOURCING_PLAN_BASKETS_QUERY = gql`
  query sourcingPlanWithBaskets($id: String!, $showAll: Boolean, $facilities: [Int!]) {
    sourcingPlan(id: $id) {
      id
      baskets(showAll: $showAll) {
        ...SourcingPlanBasketFragment
        basket {
          ...SimpleBasketFragment
          likelihoods(facilityIds: $facilities) {
            ...BasketLikelihoodFragment
          }
          insights(facilityIds: $facilities) {
            totalSpend
          }
        }
      }
    }
  }
  ${SimpleBasketFragment}
  ${SourcingPlanBasketFragment}
  ${BasketLikelihoodFragment}
`

export function useSourcingPlanBasketsQuery(
  options: QueryHookOptions<
    Pick<Query, 'sourcingPlan'>,
    QuerySourcingPlanArgs & { showAll?: boolean; facilities?: Maybe<number[]> }
  >,
) {
  return useQuery(SOURCING_PLAN_BASKETS_QUERY, options)
}

const SOURCING_PLAN_BASKET_BASES_QUERY = gql`
  query sourcingPlanBasketBases($input: SourcingPlanBasketsQueryInput, $facilityIds: [Int!]) {
    sourcingPlanBasketBases(input: $input) {
      inProgressSourcingPlan {
        id
        name
        status
        facilities
      }
      basket {
        ...SimpleBasketFragment

        likelihoods(facilityIds: $facilityIds) {
          ...BasketLikelihoodFragment
        }
        insights(facilityIds: $facilityIds) {
          sourcingPlanSuppliers {
            targetPercentile
            updatedAt
          }
          totalSpend
          percentiles
          complexity {
            overallLevel
          }
        }
      }
      sourcingPlanBasket {
        ...SourcingPlanBasketFragment
      }
    }
  }
  ${SimpleBasketFragment}
  ${BasketLikelihoodFragment}
  ${SourcingPlanBasketFragment}
`

export function useSourcingPlanBasketBasesQuery(
  options?: QueryHookOptions<
    Pick<Query, 'sourcingPlanBasketBases'>,
    QuerySourcingPlanBasketBasesArgs & { facilityIds?: number[] | null }
  >,
) {
  return useQuery(SOURCING_PLAN_BASKET_BASES_QUERY, options)
}

const SOURCING_PLAN_BASKET_BASE_QUERY = gql`
  query sourcingPlanBasketBase($id: String!, $facilityIds: [Int!]) {
    sourcingPlanBasketBase(id: $id) {
      stakeholders {
        ...SourcingPlanBasketStakeholderFragment
      }
      activeConsultRequest {
        ...CategoryConsultRequestFragment
      }
      inProgressSourcingPlan {
        id
        name
        facilities
        status
      }
      sourcingPlans {
        id
        name
        facilities
        status
      }
      sourcingPlans {
        id
        name
        facilities
      }
      basket {
        ...SimpleBasketFragment
        likelihoods(facilityIds: $facilityIds) {
          ...BasketLikelihoodFragment
        }
        insights(facilityIds: $facilityIds, includePercentiles: true) {
          totalSpend
          percentiles
          complexity {
            overallLevel
            metrics {
              name
              level
              recordedMeasurement
            }
          }
          sourcingPlanSuppliers {
            targetPercentile
            suppliers {
              name
              isIncumbent
              isSuggested
              coverage
              estSavings
              totalQty
              numItems
              spend
            }
            updatedAt
          }
        }
      }
      sourcingPlanBasket {
        id
        totalSpend
        targetSpend
        targetSavings
        sourcingPlanId
        support
      }
    }
  }
  ${CategoryConsultRequestFragment}
  ${SimpleBasketFragment}
  ${SourcingPlanBasketStakeholderFragment}
  ${BasketLikelihoodFragment}
`

export function useSourcingPlanBasketBaseQuery(
  options: QueryHookOptions<
    Pick<Query, 'sourcingPlanBasketBase'> & { facilityIds?: number[] },
    QuerySourcingPlanBasketBaseArgs & BasketInsightsArgs
  >,
) {
  return useQuery(SOURCING_PLAN_BASKET_BASE_QUERY, options)
}

const ADD_BASKET_TO_SOURCING_PLAN_MUTATION = gql`
  mutation addSourcingPlanBasket($input: AddSourcingPlanBasketInput!) {
    addSourcingPlanBasket(input: $input) {
      ...SourcingPlanBasketFragment
    }
  }
  ${SourcingPlanBasketFragment}
`

export function useAddSourcingPlanBasketMutation(
  options?: MutationHookOptions<Pick<Mutation, 'addSourcingPlanBasket'>, MutationAddSourcingPlanBasketArgs>,
) {
  return useMutation(ADD_BASKET_TO_SOURCING_PLAN_MUTATION, options)
}

const UPDATE_SOURCING_PLAN_BASKET_MUTATION = gql`
  mutation updateSourcingPlanBasket($input: UpdateSourcingPlanBasketInput!) {
    updateSourcingPlanBasket(input: $input) {
      sourcingPlanBasket {
        ...SourcingPlanBasketFragment
      }
    }
  }
  ${SourcingPlanBasketFragment}
`

export function useUpdateSourcingPlanBasketMutation(
  options?: MutationHookOptions<Pick<Mutation, 'updateSourcingPlanBasket'>, MutationUpdateSourcingPlanBasketArgs>,
) {
  return useMutation(UPDATE_SOURCING_PLAN_BASKET_MUTATION, options)
}

const SOURCING_PLAN_ADD_CONTACT_MUTATION = gql`
  mutation addSourcingPlanStakeholder($input: AddSourcingPlanStakeholderInput!) {
    addSourcingPlanStakeholder(input: $input) {
      ...SourcingPlanStakeholderFragment
    }
  }
  ${SourcingPlanStakeholderFragment}
`

export function useAddSourcingPlanStakeholderMutation(
  options?: MutationHookOptions<Pick<Mutation, 'addSourcingPlanStakeholder'>, MutationAddSourcingPlanStakeholderArgs>,
) {
  return useMutation(SOURCING_PLAN_ADD_CONTACT_MUTATION, options)
}

const SOURCING_PLAN_UPDATE_STAKEHOLDER = gql`
  mutation updateSourcingPlanStakeholder($input: UpdateSourcingPlanStakeholderInput!) {
    updateSourcingPlanStakeholder(input: $input) {
      ...SourcingPlanStakeholderFragment
    }
  }
  ${SourcingPlanStakeholderFragment}
`

export function useUpdateSourcingPlanStakeholderMutation(
  options?: MutationHookOptions<
    Pick<Mutation, 'updateSourcingPlanStakeholder'>,
    MutationUpdateSourcingPlanStakeholderArgs
  >,
) {
  return useMutation(SOURCING_PLAN_UPDATE_STAKEHOLDER, options)
}

const SOURCING_PLAN_REMOVE_STAKEHOLDER = gql`
  mutation removeSourcingPlanStakeholder($input: RemoveSourcingPlanStakeholderInput!) {
    removeSourcingPlanStakeholder(input: $input) {
      ...SourcingPlanStakeholderFragment
    }
  }
  ${SourcingPlanStakeholderFragment}
`

export function useRemoveSourcingPlanStakeholderMutation(
  options?: MutationHookOptions<
    Pick<Mutation, 'removeSourcingPlanStakeholder'>,
    MutationRemoveSourcingPlanStakeholderArgs
  >,
) {
  return useMutation(SOURCING_PLAN_REMOVE_STAKEHOLDER, options)
}

const CATEGORY_ADD_CONTACT_MUTATION = gql`
  mutation addSourcingPlanBasketStakeholder($input: AddSourcingPlanBasketsStakeholdersInput!) {
    addSourcingPlanBasketStakeholder(input: $input) {
      ...SourcingPlanBasketStakeholderFragment
    }
  }
  ${SourcingPlanBasketStakeholderFragment}
`

export function useAddCategoryStakeholderMutation(
  options?: MutationHookOptions<
    Pick<Mutation, 'addSourcingPlanBasketStakeholder'>,
    MutationAddSourcingPlanBasketStakeholderArgs
  >,
) {
  return useMutation(CATEGORY_ADD_CONTACT_MUTATION, options)
}

const SOURCING_PLAN_BASKET_REMOVE_STAKEHOLDER = gql`
  mutation removeSourcingPlanBasketStakeholder($input: RemoveSourcingPlanBasketStakeholderInput!) {
    removeSourcingPlanBasketStakeholder(input: $input) {
      ...SourcingPlanBasketStakeholderFragment
    }
  }
  ${SourcingPlanBasketStakeholderFragment}
`

export function useRemoveSourcingPlanBasketStakeholderMutation(
  options?: MutationHookOptions<
    Pick<Mutation, 'removeSourcingPlanBasketStakeholder'>,
    MutationRemoveSourcingPlanBasketStakeholderArgs
  >,
) {
  return useMutation(SOURCING_PLAN_BASKET_REMOVE_STAKEHOLDER, options)
}

const SOURCING_PLAN_BASKET_UPDATE_STAKEHOLDER = gql`
  mutation updateSourcingPlanBasketStakeholder($input: UpdateSourcingPlanBasketStakeholderInput!) {
    updateSourcingPlanBasketStakeholder(input: $input) {
      ...SourcingPlanBasketStakeholderFragment
    }
  }
  ${SourcingPlanBasketStakeholderFragment}
`

export function useUpdateSourcingPlanBasketStakeholderMutation(
  options?: MutationHookOptions<
    Pick<Mutation, 'updateSourcingPlanBasketStakeholder'>,
    MutationUpdateSourcingPlanBasketStakeholderArgs
  >,
) {
  return useMutation(SOURCING_PLAN_BASKET_UPDATE_STAKEHOLDER, options)
}

const HIDE_SOURCING_PLAN_CATEGORY = gql`
  mutation hideSourcingPlanBasket($input: HideSourcingPlanInput!) {
    hideSourcingPlanBasket(input: $input) {
      ...SourcingPlanBasketBaseFragment
    }
  }
  ${SourcingPlanBasketBaseFragment}
`

export function useHideSourcingPlanBasket(
  options?: MutationHookOptions<Pick<Mutation, 'hideSourcingPlanBasket'>, MutationHideSourcingPlanBasketArgs>,
) {
  return useMutation(HIDE_SOURCING_PLAN_CATEGORY, options)
}

const SNOOZE_SOURCING_PLAN_CATEGORY = gql`
  mutation snoozeSourcingPlanBasket($input: SnoozeSourcingPlanInput!) {
    snoozeSourcingPlanBasket(input: $input) {
      ...SourcingPlanBasketBaseFragment
    }
  }
  ${SourcingPlanBasketBaseFragment}
`

export function useSnoozeSourcingPlanBasket(
  options?: MutationHookOptions<Pick<Mutation, 'snoozeSourcingPlanBasket'>, MutationSnoozeSourcingPlanBasketArgs>,
) {
  return useMutation(SNOOZE_SOURCING_PLAN_CATEGORY, options)
}

const REQUEST_CATEGORY_CONSULT_MUTATION = gql`
  mutation requestCategoryConsult($input: RequestCategoryConsultInput!) {
    requestCategoryConsult(input: $input) {
      ...CategoryConsultRequestFragment
    }
  }
  ${CategoryConsultRequestFragment}
`

export function useRequestCategoryConsultMutation(
  options?: MutationHookOptions<Pick<Mutation, 'requestCategoryConsult'>, MutationRequestCategoryConsultArgs>,
) {
  return useMutation(REQUEST_CATEGORY_CONSULT_MUTATION, options)
}

const CANCEL_CATEGORY_CONSULT_REQUEST_MUTATION = gql`
  mutation cancelCategoryConsultRequest($id: String!) {
    cancelCategoryConsultRequest(id: $id) {
      ...CategoryConsultRequestFragment
    }
  }
  ${CategoryConsultRequestFragment}
`

export function useCancelCategoryConsultRequestMutation(
  options?: MutationHookOptions<
    Pick<Mutation, 'cancelCategoryConsultRequest'>,
    MutationCancelCategoryConsultRequestArgs
  >,
) {
  return useMutation(CANCEL_CATEGORY_CONSULT_REQUEST_MUTATION, options)
}

const PIN_SOURCING_PLAN_CATEGORY = gql`
  mutation pinSourcingPlanBasket($input: PinSourcingPlanInput!) {
    pinSourcingPlanBasket(input: $input) {
      ...SourcingPlanBasketBaseFragment
    }
  }
  ${SourcingPlanBasketBaseFragment}
`

export function usePinSourcingPlanBasket(
  options?: MutationHookOptions<Pick<Mutation, 'pinSourcingPlanBasket'>, MutationPinSourcingPlanBasketArgs>,
) {
  return useMutation(PIN_SOURCING_PLAN_CATEGORY, options)
}

const SOURCING_PLAN_BASKET_SEND_STAKEHOLDER_SURVEY = gql`
  mutation sendBasketStakeholdersSurvey($input: SendBasketStakeholdersSurveyInput!) {
    sendBasketStakeholdersSurvey(input: $input) {
      ...SourcingPlanBasketStakeholderFragment
    }
  }
  ${SourcingPlanBasketStakeholderFragment}
`

export function useSendBasketStakeholderSurveyMutation(
  options?: MutationHookOptions<
    Pick<Mutation, 'sendBasketStakeholdersSurvey'>,
    MutationSendBasketStakeholdersSurveyArgs
  >,
) {
  return useMutation(SOURCING_PLAN_BASKET_SEND_STAKEHOLDER_SURVEY, options)
}

const SOURCING_PLAN_SEND_STAKEHOLDER_SURVEY = gql`
  mutation sendSourcingPlanStakeholdersSurvey($input: SendSourcingPlanStakeholdersSurveyInput!) {
    sendSourcingPlanStakeholdersSurvey(input: $input) {
      ...SourcingPlanStakeholderFragment
    }
  }
  ${SourcingPlanStakeholderFragment}
`

export function useSendSourcingPlanStakeholderSurveyMutation(
  options?: MutationHookOptions<
    Pick<Mutation, 'sendSourcingPlanStakeholdersSurvey'>,
    MutationSendSourcingPlanStakeholdersSurveyArgs
  >,
) {
  return useMutation(SOURCING_PLAN_SEND_STAKEHOLDER_SURVEY, options)
}

const GET_BASKET_STAKEHOLDER_SURVEY_EMAIL_PREVIEW = gql`
  query basketStakeholderSurveyPreview {
    basketStakeholderSurveyPreview
  }
`

export function useBasketStakeholderSurveyPreview(
  options?: QueryHookOptions<Pick<Query, 'basketStakeholderSurveyPreview'>>,
) {
  return useQuery(GET_BASKET_STAKEHOLDER_SURVEY_EMAIL_PREVIEW, options)
}

const GET_SP_STAKEHOLDER_SURVEY_EMAIL_PREVIEW = gql`
  query sourcingPlanSurveyEmailPreview {
    sourcingPlanSurveyEmailPreview
  }
`

export function useSourcingPlanStakeholderSurveyPreview(
  options?: QueryHookOptions<Pick<Query, 'sourcingPlanSurveyEmailPreview'>>,
) {
  return useQuery(GET_SP_STAKEHOLDER_SURVEY_EMAIL_PREVIEW, options)
}

const ACTIVATE_SOURCING_PLAN = gql`
  mutation activateSourcingPlan($sourcingPlanId: String!, $force: Boolean) {
    activateSourcingPlan(sourcingPlanId: $sourcingPlanId, force: $force) {
      ...SourcingPlanFragment
    }
  }
  ${SourcingPlanFragment}
`

export function useActivateSourcingPlanMutation(
  options?: MutationHookOptions<Pick<Mutation, 'activateSourcingPlan'>, MutationActivateSourcingPlanArgs>,
) {
  return useMutation(ACTIVATE_SOURCING_PLAN, options)
}

const DELETE_SOURCING_PLAN = gql`
  mutation deleteSourcingPlan($id: String!) {
    deleteSourcingPlan(id: $id) {
      ...SourcingPlanFragment
    }
  }
  ${SourcingPlanFragment}
`

export function useDeleteSourcingPlanMutation(
  options?: MutationHookOptions<Pick<Mutation, 'deleteSourcingPlan'>, MutationDeleteSourcingPlanArgs>,
) {
  return useMutation(DELETE_SOURCING_PLAN, options)
}

const REMOVE_CATEGORY_FROM_SOURCING_PLAN = gql`
  mutation removeSourcingPlanBasket($input: RemoveSourcingPlanBasketInput!) {
    removeSourcingPlanBasket(input: $input) {
      sourcingPlanId
    }
  }
`

export function useRemoveBasketFromSourcingPlan(
  options?: MutationHookOptions<Pick<Mutation, 'removeSourcingPlanBasket'>, MutationRemoveSourcingPlanBasketArgs>,
) {
  return useMutation(REMOVE_CATEGORY_FROM_SOURCING_PLAN, options)
}
