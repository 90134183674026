import { gql, MutationHookOptions, QueryHookOptions, useMutation, useQuery } from '@apollo/client'
import {
  Mutation,
  MutationCreateSavedMappingArgs,
  MutationDeleteSavedMappingArgs,
  MutationDeleteSavedSearchArgs,
  MutationDoAggregatedSearchArgs,
  MutationDoUploadAggSearchArgs,
  MutationDuplicateSearchArgs,
  MutationEditSavedMappingArgs,
  MutationEditSavedSearchArgs,
  MutationExportItemPoTransactionsArgs,
  MutationFetchMappingFieldsArgs,
  MutationSaveSearchArgs,
  MutationSearchByUploadedSearchIdArgs,
  MutationUploadSearchFileArgs,
  Query,
  QueryClinicalStudiesArgs,
  QueryDetailedItemArgs,
  QueryDoBasketSearchArgs,
  QueryFilterUploadedFileColumnArgs,
  QueryItemTransactionsArgs,
  QueryLastPurchaseDateArgs,
  QueryNormalizedSuppliersArgs,
  QuerySavedSearchArgs,
  QuerySavedSearchesArgs,
  QuerySearchNormalizedSuppliersArgs,
  QuerySimilarProductsArgs,
  QuerySimilarProductsManufacturersArgs,
  QueryUploadedFileKeyArgs,
} from '../gen/models'

export const SavedSearchFragment = gql`
  fragment SavedSearchFragment on SavedSearch {
    id
    name
    searchText
    pin
    isRecent
    resultCount
    updatedAt
    jsonAdvancedSearch
  }
`

export const ItemSpendFragment = gql`
  fragment ItemSpendFragment on ItemSpend {
    id
    tsId
    matchId
    vendor
    transactions
    gmdn
    itemName
    facilityName
    facilityItem
    vendorItem
    contract
    organization
    organizationItem
    manufacturer
    manufacturerItem
    description
    uom
    uomConversion
    impact12m
    actualImpact
    totalSpend
    totalQuantity
    currentPrice
    poNum
    gicId
    gicName
    typeOne
    typeTwo
    benchmarks {
      iqo25
      iqo50
      iqo75
      iqo90
      minUnitPrice
      maxUnitPrice
      percentiles
    }
    pricingTrends {
      metrics {
        priceDeltaPercentage
        priceDelta
      }
      marketTrend {
        priceDeltaPercentage
        lrSlope
        lrR2
      }
      pricing {
        x
        y
      }
      spend {
        x
        y
      }
      utilization {
        x
        y
      }
    }
  }
`

export const AggregatedItemSpendFragment = gql`
  fragment AggregatedItemSpendFragment on AggregatedItemSpend {
    id
    tsId
    matchId
    vendor
    transactions
    gmdn
    itemName
    facilityId
    facilityName
    facilityItem
    vendorItem
    contract
    organization
    organizationItem
    manufacturer
    manufacturerItem
    description
    uom
    uomConversion
    impact12m
    utilization
    toLastPurchaseDays
    firstPurchaseDate
    impact3m
    actualImpact
    totalSpend
    totalQuantity
    last12MonthsSpend
    last12MonthsQuantity
    currentPrice
    gicId
    gicName
    gudid {
      gudid
    }
    stan {
      id
    }
    commodity {
      medId
    }
    typeOne
    typeTwo

    esSupplierNormalized
    esProductNormalized
    esVendorNormalized
    esVendorItemNormalized
    esDescription
    curvoCategory
    poNums
    lastPurchaseDate
    benchmarks {
      iqo25
      iqo50
      iqo75
      iqo90
      minUnitPrice
      maxUnitPrice
      percentiles
    }
    l12mBenchmarks {
      iqo25
      iqo50
      iqo75
      iqo90
      percentiles
    }
    pricingTrends {
      metrics {
        priceDeltaPercentage
      }
      marketTrend {
        priceDeltaPercentage
        lrSlope
        lrR2
      }
      pricing {
        x
        y
      }
      spend {
        x
        y
      }
      utilization {
        x
        y
      }
    }
  }
`

export const ColumnFilterFragment = gql`
  fragment ColumnFilterFragment on ColumnFilter {
    benchmarkComparison
    benchmarkPercentile
    numberFrom
    numberTo
    dateFrom
    dateTo
    filterTerms
  }
`

export const ColumnAttributesFragment = gql`
  fragment ColumnAttributesFragment on ColumnAttributes {
    field
    pin
    hide
    searchOn
    sortBy
    filter {
      ...ColumnFilterFragment
    }
    type
  }
  ${ColumnFilterFragment}
`

export const SAVED_SEARCHES = gql`
  query savedSearches($input: SavedSearchesInput!) {
    savedSearches(input: $input) {
      total
      data {
        ...SavedSearchFragment
        searchFields {
          ...ColumnAttributesFragment
        }
      }
    }
    pinnedSavedSearches {
      ...SavedSearchFragment
    }
  }
  ${SavedSearchFragment}
  ${ColumnAttributesFragment}
`

export const useSavedSearchesQuery = (
  baseOptions: QueryHookOptions<
    Pick<Query, 'savedSearches'> & Pick<Query, 'pinnedSavedSearches'>,
    QuerySavedSearchesArgs
  >,
) => useQuery(SAVED_SEARCHES, baseOptions)

const SAVE_SEARCH = gql`
  mutation saveSearch($input: CreateSavedSearchInput!) {
    saveSearch(input: $input) {
      ...SavedSearchFragment
      searchFields {
        ...ColumnAttributesFragment
      }
      grouping
    }
  }
  ${SavedSearchFragment}
  ${ColumnAttributesFragment}
`

export const useSaveSearchMutation = (
  baseOptions?: MutationHookOptions<Pick<Mutation, 'saveSearch'>, MutationSaveSearchArgs>,
) => useMutation(SAVE_SEARCH, baseOptions)

const DO_AGGREGATED_SEARCH = gql`
  mutation doAggregatedSearch($input: CreateSavedSearchInput!, $pagination: Pagination) {
    doAggregatedSearch(input: $input, pagination: $pagination) {
      totalHits
      totalSpend
      hits {
        ...AggregatedItemSpendFragment
      }
      searchFieldsFilterTerms {
        field
        terms {
          term
          totalHits
        }
      }
      fullResponse
    }
  }
  ${AggregatedItemSpendFragment}
`

const EXPORT_AGGREGATED_SEARCH = gql`
  mutation exportAggregatedSearch($input: ExportAggregatedSearchInput!) {
    exportAggregatedSearch(input: $input) {
      id
    }
  }
`

export const useDoAggregatedSearchMutation = (
  baseOptions?: MutationHookOptions<Pick<Mutation, 'doAggregatedSearch'>, MutationDoAggregatedSearchArgs>,
) => useMutation(DO_AGGREGATED_SEARCH, baseOptions)

export const useExportAggregatedSearchMutation = (
  baseOptions?: MutationHookOptions<Pick<Mutation, 'exportAggregatedSearch'>, MutationDoAggregatedSearchArgs>,
) => useMutation(EXPORT_AGGREGATED_SEARCH, baseOptions)

const EXPORT_ITEM_PO_TRANSACTIONS_SEARCH = gql`
  mutation exportItemPoTransactions($input: ExportItemPoTransactionsInput!) {
    exportItemPoTransactions(input: $input) {
      id
    }
  }
`
export const useExportItemPoTransactionsMutation = (
  baseOptions?: MutationHookOptions<Pick<Mutation, 'exportItemPoTransactions'>, MutationExportItemPoTransactionsArgs>,
) => useMutation(EXPORT_ITEM_PO_TRANSACTIONS_SEARCH, baseOptions)

const DO_BASKET_SEARCH = gql`
  query doBasketSearch($input: BasketSearchInput!, $pagination: Pagination) {
    doBasketSearch(input: $input, pagination: $pagination) {
      totalHits
      hits {
        name
        numItems
      }
    }
  }
`

export const SUBSCRIBE_EXPORTED_SEARCH = gql`
  subscription ExportedSearch($id: String!) {
    exportedSearch(id: $id) {
      downloadUrl
    }
  }
`

export const SUBSCRIBE_UPLOAD_SEARCH = gql`
  subscription uploadSearch($id: String!) {
    uploadSearch(id: $id) {
      uploadedSearchId
    }
  }
`

export const useDoBasketSearchQuery = (
  baseOptions?: MutationHookOptions<Pick<Query, 'doBasketSearch'>, QueryDoBasketSearchArgs>,
) => useQuery(DO_BASKET_SEARCH, baseOptions)

const SPEND_DATA_FOR_VENDORS_QUERY = gql`
  query getSpendDataForVendors($dataset: String) {
    getSpendDataForVendors(dataset: $dataset) {
      customer_id
      customer_name
      facility_id
      facility_name
      curvo_category
      qtypurchase
      manufacturer
      manufacturer_item
      stan_gic_name
      stan_type_one
      stan_type_two
      stan_product_line
      stan_brand
      gudid_gmdn_name
      gudid_brand_name
      uom
      uom_conversion
      iqo25
      iqo50
      iqo75
      iqo90
      case_id
      surgeon
      construct_name
      construct_group
      procedure_type
    }
  }
`

export const useGetSpendDataForVendors = (baseOptions: QueryHookOptions<Pick<Query, 'getSpendDataForVendors'>>) =>
  useQuery(SPEND_DATA_FOR_VENDORS_QUERY, baseOptions)

const SAVED_SEARCH_QUERY = gql`
  query savedSearch($input: IDArgs!) {
    savedSearch(input: $input) {
      ...SavedSearchFragment
      searchFields {
        ...ColumnAttributesFragment
      }
      grouping
    }
  }
  ${SavedSearchFragment}
  ${ColumnAttributesFragment}
`

export const useSavedSearchQuery = (baseOptions: QueryHookOptions<Pick<Query, 'savedSearch'>, QuerySavedSearchArgs>) =>
  useQuery(SAVED_SEARCH_QUERY, baseOptions)

const DUPLICATE_SAVED_SEARCH = gql`
  mutation duplicateSavedSearch($input: IDArgs!) {
    duplicateSearch(input: $input) {
      ...SavedSearchFragment
    }
  }
  ${SavedSearchFragment}
`

export const useDuplicateSearchMutation = (
  baseOptions?: MutationHookOptions<Pick<Mutation, 'duplicateSearch'>, MutationDuplicateSearchArgs>,
) => useMutation(DUPLICATE_SAVED_SEARCH, baseOptions)

const DELETE_SAVED_SEARCH = gql`
  mutation deleteSavedSearch($input: IDArgs!) {
    deleteSavedSearch(input: $input) {
      ...SavedSearchFragment
    }
  }
  ${SavedSearchFragment}
`

export const useDeleteSearchMutation = (
  baseOptions?: MutationHookOptions<Pick<Mutation, 'deleteSavedSearch'>, MutationDeleteSavedSearchArgs>,
) => useMutation(DELETE_SAVED_SEARCH, baseOptions)

const EDIT_SAVED_SEARCH = gql`
  mutation editSavedSearch($input: UpdateSavedSearchInput!) {
    editSavedSearch(input: $input) {
      ...SavedSearchFragment
      searchFields {
        ...ColumnAttributesFragment
      }
      grouping
    }
  }
  ${SavedSearchFragment}
  ${ColumnAttributesFragment}
`

export const useUpdateSearchMutation = (
  baseOptions?: MutationHookOptions<Pick<Mutation, 'editSavedSearch'>, MutationEditSavedSearchArgs>,
) => useMutation(EDIT_SAVED_SEARCH, baseOptions)

const DETAILED_SEARCH = gql`
  query detailedItem($input: IDArgs!) {
    detailedItem(input: $input) {
      ...ItemSpendFragment
      gudid {
        manufacturer
        gudid
        description
        gmdnName
        gmdnTerms {
          gmdnptname
          gmdnptdefinition
        }
        brandName
        catalogNumber
        companyName
        versionModelNumber
        productCodes {
          productcode
          productcodename
        }
        submissionNumbers
      }
    }
  }
  ${ItemSpendFragment}
`

export const useQueryDetailedItem = (
  baseOptions?: QueryHookOptions<Pick<Query, 'detailedItem'>, QueryDetailedItemArgs>,
) => useQuery(DETAILED_SEARCH, baseOptions)

const ITEM_TRANSACTIONS = gql`
  query itemTransactions($input: DetailedSearchInput!, $pagination: Pagination) {
    itemTransactions(input: $input, pagination: $pagination) {
      totalHit
      hits {
        vendor
        vendorItem
        manufacturer
        mfgItem
        facDesc
        facilityItem
        datePurchase
        unitPrice
        qtyPurchase
        poNum
        uom
        uomConversion
      }
    }
  }
`

export const useQueryItemTransactions = (
  baseOptions?: QueryHookOptions<Pick<Query, 'itemTransactions'>, QueryItemTransactionsArgs>,
) => useQuery(ITEM_TRANSACTIONS, baseOptions)

const UPLOAD_SEARCH_FILE = gql`
  mutation uploadSearchFile($input: UploadSearchFileInput!) {
    uploadSearchFile(input: $input) {
      s3Key
      data {
        columnName
        previewData

        sheetName
        sheetIndex
      }
    }
  }
`

export const useUploadSearchFile = (
  baseOptions?: MutationHookOptions<Pick<Mutation, 'uploadSearchFile'>, MutationUploadSearchFileArgs>,
) => useMutation(UPLOAD_SEARCH_FILE, baseOptions)

const FETCH_MAPPING_FIELDS = gql`
  mutation fetchMappingFields($input: FetchMappingFieldsInput!) {
    fetchMappingFields(input: $input) {
      data {
        columnName
        previewData

        sheetName
        sheetIndex
      }
    }
  }
`

export const useFetchMappingFields = (
  baseOptions?: MutationHookOptions<Pick<Mutation, 'fetchMappingFields'>, MutationFetchMappingFieldsArgs>,
) => useMutation(FETCH_MAPPING_FIELDS, baseOptions)

const DO_UPLOAD_SEARCH = gql`
  mutation doUploadAggSearch($input: DoUploadSearchInput!) {
    doUploadAggSearch(input: $input) {
      id
    }
  }
`

export const useDoUploadSearchMutation = (
  baseOptions?: MutationHookOptions<Pick<Mutation, 'doUploadAggSearch'>, MutationDoUploadAggSearchArgs>,
) => useMutation(DO_UPLOAD_SEARCH, baseOptions)

const FILTER_COLUMN = gql`
  query filterUploadedFileColumn($input: FilterColumnInput!) {
    filterUploadedFileColumn(input: $input) {
      data
    }
  }
`

export const useFilterUploadedFileColumnQuery = (
  baseOptions?: QueryHookOptions<Pick<Query, 'filterUploadedFileColumn'>, QueryFilterUploadedFileColumnArgs>,
) => useQuery(FILTER_COLUMN, baseOptions)

const NORMALIZED_MANUFACTURERS = gql`
  query normalizedManufacturers {
    normalizedManufacturers {
      data
    }
  }
`

export const useQueryNormalizedManufacturers = (
  baseOptions?: QueryHookOptions<Pick<Query, 'normalizedManufacturers'>>,
) => useQuery(NORMALIZED_MANUFACTURERS, baseOptions)

const NORMALIZED_SUPPLIERS = gql`
  query normalizedSuppliers($input: [String!]!) {
    normalizedSuppliers(input: $input) {
      inputSupplier
      normalizedCurvo
      type
    }
  }
`

export const useQueryNormalizedSuppliers = (
  baseOptions?: QueryHookOptions<Pick<Query, 'normalizedSuppliers'>, QueryNormalizedSuppliersArgs>,
) => useQuery(NORMALIZED_SUPPLIERS, baseOptions)

const SAVED_MAPPINGS = gql`
  query savedMappings($input: GetSavedMappingInput!) {
    savedMappings(input: $input) {
      data {
        id
        name
        mappedFields
      }
    }
  }
`

export const useQuerySavedMappings = (baseOptions?: QueryHookOptions<Pick<Query, 'savedMappings'>>) =>
  useQuery(SAVED_MAPPINGS, baseOptions)

const SEARCH_BY_UPLOADED_SEARCH_ID = gql`
  mutation searchByUploadedSearchId($input: SearchByUploadedSearchIdInput!, $pagination: Pagination) {
    searchByUploadedSearchId(input: $input, pagination: $pagination) {
      totalHits
      hits {
        ...AggregatedItemSpendFragment
      }
      totalSpend
    }
  }
  ${AggregatedItemSpendFragment}
`

export const useSearchByUploadedSearchId = (
  baseOptions?: MutationHookOptions<Pick<Mutation, 'searchByUploadedSearchId'>, MutationSearchByUploadedSearchIdArgs>,
) => useMutation(SEARCH_BY_UPLOADED_SEARCH_ID, baseOptions)

const CREATE_SAVED_MAPPING = gql`
  mutation createSavedMapping($input: CreateSavedMappingInput!) {
    createSavedMapping(input: $input) {
      id
    }
  }
`

export const useCreateSavedMappingMutation = (
  baseOptions?: MutationHookOptions<Pick<Mutation, 'createSavedMapping'>, MutationCreateSavedMappingArgs>,
) => useMutation(CREATE_SAVED_MAPPING, baseOptions)

const UPDATE_SAVED_MAPPING = gql`
  mutation editSavedMapping($input: UpdateSavedMappingInput!) {
    editSavedMapping(input: $input) {
      id
    }
  }
`

export const useUpdateSavedMappingMutation = (
  baseOptions?: MutationHookOptions<Pick<Mutation, 'editSavedMapping'>, MutationEditSavedMappingArgs>,
) => useMutation(UPDATE_SAVED_MAPPING, baseOptions)

const DELETE_SAVED_MAPPING = gql`
  mutation deleteSavedMapping($input: DeleteSavedMappingInput!) {
    deleteSavedMapping(input: $input) {
      id
    }
  }
`

export const useDeleteSavedMappingMutation = (
  baseOptions?: MutationHookOptions<Pick<Mutation, 'deleteSavedMapping'>, MutationDeleteSavedMappingArgs>,
) => useMutation(DELETE_SAVED_MAPPING, baseOptions)

const UPLOADED_FILE_KEY = gql`
  query uploadedFileKey($input: UploadedFileKeyInput!) {
    uploadedFileKey(input: $input) {
      s3Key
    }
  }
`

export const useQueryUploadedFileKey = (
  baseOptions?: QueryHookOptions<Pick<Query, 'uploadedFileKey'>, QueryUploadedFileKeyArgs>,
) => useQuery(UPLOADED_FILE_KEY, baseOptions)

const LAST_PURCHASE_DATE = gql`
  query lastPurchaseDate {
    lastPurchaseDate {
      data {
        facilityId
        lastPurchaseDate
      }
    }
  }
`

export const useQueryLastPurchaseDate = (baseOptions?: QueryHookOptions<Pick<Query, 'lastPurchaseDate'>>) =>
  useQuery(LAST_PURCHASE_DATE, baseOptions)

const BASKET_LAST_PURCHASE_DATE = gql`
  query basketLastPurchaseDate($basketId: String!) {
    basketLastPurchaseDate(basketId: $basketId) {
      data {
        facilityId
        facilityName
        lastPurchaseDate
      }
    }
  }
`

export const useQueryBasketLastPurchaseDate = (baseOptions?: QueryHookOptions<Pick<Query, 'basketLastPurchaseDate'>>) =>
  useQuery(BASKET_LAST_PURCHASE_DATE, baseOptions)

const LAST_PURCHASE_DATE_BY_CONTEXT = gql`
  query lastPurchaseDateByContext($facilityIds: [Int!]) {
    lastPurchaseDate(facilityIds: $facilityIds) {
      data {
        facilityId
        lastPurchaseDate
        facilityName
      }
    }
  }
`

export const useQueryLastPurchaseDateByContext = (
  baseOptions?: QueryHookOptions<Pick<Query, 'lastPurchaseDate'>, QueryLastPurchaseDateArgs>,
) => useQuery(LAST_PURCHASE_DATE_BY_CONTEXT, baseOptions)

const SIMILAR_PRODUCTS = gql`
  query similarProducts($input: SimilarProductsInput!, $pagination: Pagination) {
    similarProducts(input: $input, pagination: $pagination) {
      totalHit
      suggestions
      hits {
        manufacturer
        manufacturerItem
        description
        gudid
        gudidBrand
        purchasedByFacilities
        submissionNumber
        uom
        uomConversion
        benchmarks {
          iqo25
          iqo50
          iqo75
          iqo90
          minUnitPrice
          maxUnitPrice
          percentiles
        }
      }
    }
  }
`

export const useQuerySimilarProducts = (
  baseOptions?: QueryHookOptions<Pick<Query, 'similarProducts'>, QuerySimilarProductsArgs>,
) => useQuery(SIMILAR_PRODUCTS, baseOptions)

const SIMILAR_PRODUCTS_MANUFACTURERS = gql`
  query similarProductsManufacturers($input: SimilarProductsManufacturersInput!) {
    similarProductsManufacturers(input: $input)
  }
`

export const useQuerySimilarProductsManufacturers = (
  baseOptions?: QueryHookOptions<Pick<Query, 'similarProductsManufacturers'>, QuerySimilarProductsManufacturersArgs>,
) => useQuery(SIMILAR_PRODUCTS_MANUFACTURERS, baseOptions)

const CLINICAL_STUDIES = gql`
  query clinicalStudies($input: ClinicalStudiesInput!, $pagination: Pagination) {
    clinicalStudies(input: $input, pagination: $pagination) {
      totalHit
      hits {
        nctId
        officialTitle
        completionDate
        studyType
        leadSponsorName
      }
    }
  }
`

export const useQueryClinicalStudies = (
  baseOptions?: QueryHookOptions<Pick<Query, 'clinicalStudies'>, QueryClinicalStudiesArgs>,
) => useQuery(CLINICAL_STUDIES, baseOptions)

const SEARCH_NORMALIZED_SUPPLIERS = gql`
  query searchNormalizedSuppliers($searchText: String!) {
    searchNormalizedSuppliers(searchText: $searchText) {
      normalizedCurvo
    }
  }
`

export const useSearchNormalizedSuppliers = (
  baseOptions?: QueryHookOptions<Pick<Query, 'searchNormalizedSuppliers'>, QuerySearchNormalizedSuppliersArgs>,
) => useQuery(SEARCH_NORMALIZED_SUPPLIERS, baseOptions)
