import { gql, MutationHookOptions, QueryHookOptions, useMutation, useQuery } from '@apollo/client'
import {
  Mutation,
  MutationAddInitiativeActivityArgs,
  MutationCreateNewInitiativeArgs,
  MutationRemoveInitiativeActivityArgs,
  Query,
  QueryInitiativeArgs,
  QueryInitiativesArgs,
} from '../../gen/models'

export const InitiativeFragment = gql`
  fragment InitiativeFragment on Initiative {
    id
    title
    comment
    medtech
    hospitalArea
    status
    isPublic
    valueElement
    contactFirstName
    contactLastName
    completedAt
    embeddedIssueUrl
    startedAt
    createdAt
    updatedAt
  }
`

const INITIATIVES_QUERY = gql`
  query initiatives($input: GetInitiativesByCriteriaInput!, $pagination: Pagination) {
    initiatives(input: $input, pagination: $pagination) {
      hits {
        ...InitiativeFragment
        interestedCount
        dealRequestCount
        interested
        network {
          id
          name
          logo
        }
      }
      totalHits
    }
  }
  ${InitiativeFragment}
`

export function useInitiativesQuery(options: QueryHookOptions<Pick<Query, 'initiatives'>, QueryInitiativesArgs>) {
  return useQuery(INITIATIVES_QUERY, options)
}

const CREATE_INITIATIVE_MUTATION = gql`
  mutation createNewInitiative($input: CreateInitiativeInput!) {
    createNewInitiative(input: $input) {
      ...InitiativeFragment
    }
  }
  ${InitiativeFragment}
`

export function useCreateNewInitiative(
  options: MutationHookOptions<Pick<Mutation, 'createNewInitiative'>, MutationCreateNewInitiativeArgs>,
) {
  return useMutation(CREATE_INITIATIVE_MUTATION, options)
}

export const INITIATIVES_SELECTORS_LIST_QUERY = gql`
  query initiativeSelectorsList {
    initiativeSelectorsList {
      valueElements
      hospitalAreas
    }
  }
`

export function useInitiativeSelectorsListQuery(options?: QueryHookOptions<Pick<Query, 'initiativeSelectorsList'>>) {
  return useQuery(INITIATIVES_SELECTORS_LIST_QUERY, options)
}

const INITIATIVE_DETAIL_QUERY = gql`
  query initiatives($id: String!) {
    initiative(id: $id) {
      ...InitiativeFragment
    }
  }
  ${InitiativeFragment}
`

export function useInitiativeDetailQuery(options: QueryHookOptions<Pick<Query, 'initiative'>, QueryInitiativeArgs>) {
  return useQuery(INITIATIVE_DETAIL_QUERY, options)
}

const ADD_INITIATIVE_ACTIVITY_MUTATION = gql`
  mutation addInitiativeActivity($input: InitiativeActivityInput!) {
    addInitiativeActivity(input: $input) {
      id
    }
  }
`

export function useAddInitiativeActivity(
  options?: MutationHookOptions<Pick<Mutation, 'addInitiativeActivity'>, MutationAddInitiativeActivityArgs>,
) {
  return useMutation(ADD_INITIATIVE_ACTIVITY_MUTATION, options)
}

const REMOVE_INITIATIVE_ACTIVITY_MUTATION = gql`
  mutation removeInitiativeActivity($input: InitiativeActivityInput!) {
    removeInitiativeActivity(input: $input)
  }
`

export function useRemoveInitiativeActivity(
  options?: MutationHookOptions<Pick<Mutation, 'removeInitiativeActivity'>, MutationRemoveInitiativeActivityArgs>,
) {
  return useMutation(REMOVE_INITIATIVE_ACTIVITY_MUTATION, options)
}
