import React, { useEffect, useState } from 'react'
import { Form, Input, Button, Row, message } from 'antd'
import { useNavigate } from 'react-router'
import { useUpdateMeMutation } from '../../api/hooks/users'
import { User } from '../../api'

type UserProfileInput = {
  firstName?: string
  lastName?: string
  phoneNumber?: string
  title?: string
  bio?: string
  linkedIn?: string
  facebook?: string
  twitter?: string
}

type Props = {
  user?: User
}

export const ProfileInformation: React.FC<Props> = ({ user }) => {
  const navigate = useNavigate()
  const onFinish = async (values: any) => {
    const input = {
      ...values,
    }
    user && user.id && (await updateMe({ variables: { input } }))
    console.info('Success:', values)
    message.success('Your profile has been updated.')
    user && user.id && navigate(`/users/${user.id}`)
  }

  const onFinishFailed = (errorInfo: any) => {
    console.info('Failed:', errorInfo)
  }
  const [form] = Form.useForm()
  const [newUserInput, setNewUserInput] = useState<UserProfileInput>()

  const [updateMe] = useUpdateMeMutation()

  useEffect(() => {
    const initialValues: UserProfileInput = user
      ? {
          firstName: user.firstName,
          lastName: user.lastName,
          phoneNumber: user.phoneNumber || undefined,
          title: user.title || undefined,
          bio: user.bio || undefined,
          linkedIn: user.linkedIn || undefined,
          facebook: user.facebook || undefined,
          twitter: user.twitter || undefined,
        }
      : {
          firstName: undefined,
          lastName: undefined,
          phoneNumber: undefined,
          title: undefined,
          bio: undefined,
          linkedIn: undefined,
          facebook: undefined,
          twitter: undefined,
        }

    form.resetFields()
    form.setFieldsValue(initialValues)
  }, [form, user])

  return (
    <Form
      form={form}
      name="basic"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      layout="vertical"
      onValuesChange={v => {
        setNewUserInput({
          ...newUserInput,
          ...v,
        })
      }}>
      <Row>
        <Form.Item
          label="First Name"
          name="firstName"
          rules={[{ required: true, message: 'Please input your first name' }]}
          style={{ width: '49%', float: 'left', marginRight: '13px' }}>
          <Input />
        </Form.Item>
        <Form.Item
          label="Last Name"
          name="lastName"
          rules={[{ required: true, message: 'Please input your last name!' }]}
          style={{ width: '49%', float: 'left' }}>
          <Input />
        </Form.Item>
      </Row>
      <Form.Item name="phoneNumber" label="Phone Number">
        <Input />
      </Form.Item>
      <Form.Item label="Title" name="title">
        <Input />
      </Form.Item>

      <Form.Item label="Bio" name="bio">
        <Input.TextArea />
      </Form.Item>

      <Form.Item label="Facebook" name="facebook">
        <Input />
      </Form.Item>

      <Form.Item label="Twitter" name="twitter">
        <Input />
      </Form.Item>

      <Form.Item
        label="LinkedIn"
        name="linkedIn"
        rules={[
          () => ({
            validator(_rule, value) {
              const regexp: RegExp = /^[a-zA-Z0-9-]+$/
              if (regexp.test(value)) {
                return Promise.resolve()
              }
              return Promise.reject('Invalid LinkedIn username')
            },
          }),
        ]}>
        <Input />
      </Form.Item>
      <Row>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Save
          </Button>
        </Form.Item>
      </Row>
    </Form>
  )
}
