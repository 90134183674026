import React, { useEffect, useState } from 'react'
import { Select } from 'antd'
import { useQuickSightGetGroups } from '../../api/hooks/quicksight'

export const UserQSGroupsSelector: React.FC<{
  value?: string[]
  onChange?: (value: string[]) => void
}> = ({ value, onChange }) => {
  const groupsRequest = useQuickSightGetGroups()
  const groups = groupsRequest.data?.quickSightGetGroups || []

  const [selectedGroups, setSelectedGroups] = useState<string[]>(value || [])

  useEffect(() => {
    setSelectedGroups(value || [])
  }, [value])

  return (
    <>
      <Select
        mode="tags"
        placeholder="Please select"
        onChange={val => {
          setSelectedGroups(val)
          onChange && onChange(val)
        }}
        style={{ width: '100%' }}
        options={groups.map(g => ({ value: g }))}
        value={selectedGroups}
      />
    </>
  )
}
