import { EditFilled, InteractionFilled, ReloadOutlined, UserSwitchOutlined } from '@ant-design/icons/lib'
import { Button, Col, Dropdown, Menu, Pagination, Popconfirm, Row, Skeleton, Statistic, message } from 'antd'
import { isNil } from 'lodash'
import moment from 'moment'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useNavigate } from 'react-router'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { MeContext, QueryUsersArgs, User, UserStatus } from '../../api'
import {
  useCustomerQuery,
  useQueryCustomers,
  useQueryUsers,
  useResendUserInviteMutation,
  useSetUserCloak,
  useToggleUserActivationMutation,
} from '../../api/hooks'
import { useRebuildQuicksightPermissionsMutation } from '../../api/hooks/dashboards'
import * as dt from '../../ui/design-tokens/tokens'
import { useGetQuery } from '../../utils'
import { MoreButton } from '../components/Buttons'
import { FiltersBar, FiltersBarConfig } from '../components/FiltersBar'
import { RoleTags } from '../components/RoleTags'
import { UserAvatar } from '../components/UserAvatar'
import { UserManagementModal } from '../components/UserManagementModal'
import { UserStatusTag } from '../components/UserStatusTag'
import { BaseManagePageLayout } from './BaseManagePageLayout'

export const Users: React.FC = () => {
  const [userManagementModalVisible, setUserManagementModalVisible] = useState(false)
  const [userManagementModalEdit, setUserManagementModalEdit] = useState<User>()
  const [currentPage, setCurrentPage] = useState(0)
  const [pageSize, setPageSize] = useState(20)
  const navigate = useNavigate()

  const query = useGetQuery()
  const [filtersSelection, setFiltersSelection] = useState<{
    [key: string]: string
  }>({
    customerId: query.get('customerId') ?? '',
    organizationId: query.get('organizationId') ?? '',
  })

  const { data: meData } = useContext(MeContext)
  const selectedCustomerId = useMemo(
    () =>
      filtersSelection.customerId ||
      (!meData?.me.isAdmin && (meData?.me.powerUserList || []).filter(p => p.isPowerUser).length > 0
        ? meData?.me.user.customerId || meData?.me.user.customer.id
        : undefined),
    [filtersSelection, meData],
  )

  const { data: customerData, loading: customerDataLoading } = useCustomerQuery({
    variables: { input: { id: selectedCustomerId! } },
    skip: isNil(selectedCustomerId),
  })

  const isPowerUser = (meData?.me.powerUserList || []).filter(org => org.isPowerUser).length > 0

  const queryUsersVars: QueryUsersArgs = useMemo(
    () => ({
      input: {
        customerId: filtersSelection.customerId,
        organizationId: filtersSelection.organizationId,
        status: filtersSelection.status as UserStatus,
        isCurvoAdmin: filtersSelection.role === 'admin' ? true : filtersSelection.role === 'user' ? false : undefined,
        searchText: filtersSelection.searchText,
      },
      pagination: {
        limit: pageSize,
        offset: pageSize * currentPage,
      },
    }),
    [filtersSelection, pageSize, currentPage],
  )
  const { data: usersData, loading: usersLoading } = useQueryUsers({
    variables: queryUsersVars,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    skip: !isPowerUser && !meData?.me.isAdmin,
  })

  const [updateCloak] = useSetUserCloak()
  const [rebuildQuicksightPermissions, { loading: rebuilding }] = useRebuildQuicksightPermissionsMutation()

  const users = useMemo(() => (usersData ? usersData.users.hits : []), [usersData])

  const customersRequest = useQueryCustomers({
    variables: { input: {} },
    skip: !meData?.me.isAdmin,
  })
  const customers = customersRequest.data ? customersRequest.data.customers : []

  const organizations = useMemo(
    () =>
      (selectedCustomerId
        ? customerData?.customer.organizations
        : meData?.me.powerUserList
            .filter(org => org.isPowerUser)
            .map(i => ({
              id: i.organizationId,
              name: i.organizationName!,
            }))) || [],
    [customerData, meData, selectedCustomerId],
  )

  const [resendInvite] = useResendUserInviteMutation()

  const filters: FiltersBarConfig[] = [
    ...(customers.length > 0
      ? [
          {
            name: 'customerId',
            label: 'Client',
            options: customers.map(customer => ({
              value: customer.id,
              label: customer.name,
            })),
          },
        ]
      : []),
    {
      name: 'organizationId',
      label: 'Organization',
      options: organizations.map(organization => ({
        value: organization.id,
        label: organization.name,
      })),
      loading: customerDataLoading,
    },
    {
      name: 'role',
      label: 'Role',
      options: [
        { value: 'admin', label: 'Admin' },
        { value: 'user', label: 'User' },
      ],
    },
    {
      name: 'status',
      label: 'Status',
      emptyLabel: 'Status',
      options: [
        { value: UserStatus.Active, label: UserStatus.Active },
        { value: UserStatus.Pending, label: UserStatus.Pending },
        { value: UserStatus.Inactive, label: UserStatus.Inactive },
      ],
    },
  ]

  const handleSetUserCloak = useCallback(
    async (cloakUserId: string) => {
      const success = await updateCloak({
        variables: { input: { cloakUserId } },
      })
      if (success) {
        window.location.href = '/'
      } else {
        message.error('Failed to cloak as selected user. Please try again.')
      }
    },
    [updateCloak],
  )

  const handleRebuildQuicksightPermissions = useCallback(
    async (userId?: string) => {
      const hideLoading = userId ? message.loading('Rebuilding user Quicksight permissions...') : undefined
      const resp = await rebuildQuicksightPermissions({
        variables: { userId },
      })
      if (resp.data?.rebuildQuicksightPermissions) {
        if (userId) {
          hideLoading && hideLoading()
          message.success('User quicksight permissions has been rebuilt successfully.')
        } else {
          message.success('Rebuilding user quicksight permissions has been queued.')
        }
      } else {
        message.error('Failed to rebuild user quicksight permissions. Please try again.')
      }
    },
    [rebuildQuicksightPermissions],
  )

  useEffect(() => {
    if (!isPowerUser && !meData?.me.isAdmin) {
      message.warn('You do not have permission to visit this page')
      navigate('/')
    }
  }, [isPowerUser, meData, navigate])

  return (
    <>
      <Helmet>
        <title>User Management</title>
      </Helmet>
      <BaseManagePageLayout
        title="User Management Page"
        extra={[
          <Button
            key="invite-user"
            type="primary"
            onClick={() => {
              setUserManagementModalVisible(true)
              setUserManagementModalEdit(undefined)
            }}>
            Invite User
          </Button>,
          <Popconfirm
            title={'Are you sure you want to rebuild QS Permissions?'}
            okText="Yes"
            cancelText="No"
            onConfirm={() => handleRebuildQuicksightPermissions()}>
            <Button key="rebuild-qs-permissions" loading={rebuilding}>
              Rebuild QS Permissions
            </Button>
          </Popconfirm>,
        ]}>
        <FiltersBar
          filters={filters}
          searchItem="users"
          selections={filtersSelection}
          onChange={(selections: any) => {
            const newSelections = {
              ...selections,
              ...(selections.customerId !== filtersSelection.customerId ? { organizationId: undefined } : {}),
            }
            setFiltersSelection(newSelections)
            setCurrentPage(0)
          }}
          style={{ paddingBottom: dt.SpaceM }}
        />
        <FloatLeft>
          <TopPagination
            total={usersData?.users.totalHits || 0}
            showSizeChanger
            current={currentPage + 1}
            defaultPageSize={20}
            pageSize={pageSize}
            pageSizeOptions={[10, 20, 50, 100]}
            onChange={(newCurrentPage, newPageSize) => {
              setCurrentPage(newCurrentPage - 1)
              setPageSize(newPageSize)
            }}
          />
        </FloatLeft>
        {usersLoading ? (
          <Skeleton active />
        ) : (
          users.map(user => {
            return (
              <Row
                key={user.id}
                style={{
                  backgroundColor: 'white',
                  padding: '15px',
                  marginBottom: '8px',
                }}
                align="middle">
                <Col flex={'72px'} style={{ padding: '5px', textAlign: 'center' }}>
                  <UserAvatar user={user} size={64} />
                </Col>
                <Col flex={12}>
                  <Row>
                    <Col span={6}>
                      <Link to={`/manage/users/${user.id}`}>
                        <a style={{ fontSize: '16px' }}>
                          {user.firstName} {user.lastName} <RoleTags user={user} />
                        </a>
                      </Link>
                      <div style={{ fontSize: '12px' }}>{user.email}</div>
                    </Col>
                    <Col span={6}>
                      <Statistic title="Organizations" value={user.userOrganizations.length || ''} />
                    </Col>
                    <Col span={6}>
                      <Statistic
                        title="Last Active"
                        value={user.lastActionAt ? moment(user.lastActionAt).calendar() : 'N/A'}
                      />
                    </Col>
                    <Col span={6}>
                      <div className="ant-statistic-title">Status</div>
                      <UserStatusTag user={user} />
                      {user.status === UserStatus.Pending && user.isManageable ? (
                        <Button
                          type="link"
                          style={{ fontSize: '12px' }}
                          onClick={() => {
                            resendInvite({
                              variables: { email: user.email },
                            }).then(() => {
                              message.success('New invitation has been sent to the user')
                            })
                          }}>
                          <strong>Resend Invite</strong>
                        </Button>
                      ) : (
                        ''
                      )}
                    </Col>
                  </Row>
                </Col>
                <Col flex={1} style={{ textAlign: 'center' }}>
                  {user.isManageable && (
                    <Dropdown
                      overlay={
                        <UserOptionsMenu
                          user={user}
                          setUserManagementModalVisible={setUserManagementModalVisible}
                          setUserManagementModalEdit={setUserManagementModalEdit}
                          queryUsersVars={queryUsersVars}
                          setUserCloak={handleSetUserCloak}
                          rebuildQuicksightPermissions={handleRebuildQuicksightPermissions}
                          isAdmin={meData?.me.isAdmin ?? false}
                        />
                      }
                      trigger={['click']}>
                      <MoreButton />
                    </Dropdown>
                  )}
                </Col>
              </Row>
            )
          })
        )}
        <FloatLeft>
          <Pagination
            total={usersData?.users.totalHits || 0}
            showSizeChanger
            current={currentPage + 1}
            defaultPageSize={20}
            pageSize={pageSize}
            pageSizeOptions={[10, 20, 50, 100]}
            onChange={(newCurrentPage, newPageSize) => {
              setCurrentPage(newCurrentPage - 1)
              setPageSize(newPageSize)
            }}
          />
        </FloatLeft>
        <UserManagementModal
          visible={userManagementModalVisible}
          onCancel={() => setUserManagementModalVisible(false)}
          user={userManagementModalEdit}
          onFinished={() => {
            setUserManagementModalVisible(false)
            setUserManagementModalEdit(undefined)
          }}
          defaultCustomerId={selectedCustomerId}
        />
      </BaseManagePageLayout>
    </>
  )
}

const UserOptionsMenu: React.FC<{
  user: User
  setUserManagementModalVisible: (b: boolean) => any
  setUserManagementModalEdit: (u: User) => any
  setUserCloak: (userId: string) => void
  rebuildQuicksightPermissions: (userId?: string) => void
  queryUsersVars: QueryUsersArgs
  isAdmin: boolean
}> = ({
  user,
  setUserManagementModalEdit,
  setUserManagementModalVisible,
  setUserCloak,
  rebuildQuicksightPermissions,
  queryUsersVars,
  isAdmin,
}) => {
  const [toggleUser] = useToggleUserActivationMutation(queryUsersVars)

  return (
    <Menu
      className="alt-menu"
      onClick={e => {
        console.info(e)
      }}>
      <Menu.Item
        key="edit"
        icon={<EditFilled />}
        onClick={() => {
          setUserManagementModalVisible(true)
          setUserManagementModalEdit(user)
        }}>
        Edit User
      </Menu.Item>
      {user.status !== UserStatus.Pending ? (
        <Menu.Item
          key="activeToggle"
          icon={<InteractionFilled />}
          style={{ color: user.status === UserStatus.Active ? 'red' : '' }}
          onClick={() => {
            toggleUser({ variables: { input: { userId: user.id } } }).then(() => {
              message.success(`User has been ${user.status === UserStatus.Active ? 'deactivated' : 'activated'}`)
            })
          }}>
          {user.status === UserStatus.Active ? 'Deactivate' : 'Activate'} User
        </Menu.Item>
      ) : (
        ''
      )}
      {isAdmin && (
        <>
          {!user.isSupplierUser && (
            <Menu.Item key="cloak" icon={<UserSwitchOutlined />} onClick={() => setUserCloak(user.id)}>
              Cloak
            </Menu.Item>
          )}
          <Menu.Item key="qsPermission" icon={<ReloadOutlined />} onClick={() => rebuildQuicksightPermissions(user.id)}>
            Rebuild Quicksight Permissions
          </Menu.Item>
        </>
      )}
    </Menu>
  )
}

const TopPagination = styled(Pagination)`
  margin-bottom: 1em;
`

const FloatLeft = styled.div`
  display: flex;
  justify-content: flex-end;
`
