import { ValueFormatterParams } from '@ag-grid-community/core'
import { BreadcrumbProps } from 'antd/es/breadcrumb/Breadcrumb'
import numbro from 'numbro'
import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import moment from 'moment'
import { Pagination } from './api'

export const breadcrumbItemRender: BreadcrumbProps['itemRender'] = (route, _params, routes) => {
  const last = routes.indexOf(route) === routes.length - 1
  return last || !route.path ? <span>{route.breadcrumbName}</span> : <Link to={route.path}>{route.breadcrumbName}</Link>
}

export const useGetQuery = () => {
  return new URLSearchParams(useLocation().search)
}

export const arrayChunk = <T extends unknown>(inputArray: Array<T>, chunkSize: number): T[][] =>
  inputArray
    .map((_, i) => (i % chunkSize === 0 ? inputArray.slice(i, i + chunkSize) : undefined))
    .filter(Boolean) as T[][]

export function currencyFormatter(params: ValueFormatterParams) {
  return params.value && formatCurrency(params.value)
}

export function formatCurrency(n?: number | string | null, mantissa = 2) {
  return formatCurrencyWCustomDefault('-', false, mantissa)(n)
}

export const getPercentileLabel = (percentile: number) => {
  if (percentile > 10 && percentile < 20) {
    return `${percentile}th`
  } else if (percentile % 10 === 1) {
    return `${percentile}st`
  } else if (percentile % 10 === 2) {
    return `${percentile}nd`
  } else if (percentile % 10 === 3) {
    return `${percentile}rd`
  }
  return `${percentile}th`
}

export const formatCurrencyWCustomDefault =
  (defaultValue: string = '-', trimMantissa: boolean = false, mantissa = 2) =>
  (n?: number | string | null) => {
    if (n === null || n === undefined || n === '' || Number.isNaN(n)) {
      return defaultValue
    }
    return numbro(n).format({ prefix: '$', thousandSeparated: true, mantissa, trimMantissa })
  }

export function formatNumber(n: number) {
  return Math.round(n)
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

export function formatDate(value?: Date | string | null) {
  return value ? new Date(value).toLocaleDateString('en-us') : ''
}

export function utcDateFormatter({ value }: Pick<ValueFormatterParams, 'value'>) {
  return value ? moment(value).utcOffset(0).format('MM/DD/YYYY') : ''
}

export function monthFormatter({ value }: Pick<ValueFormatterParams, 'value'>) {
  return value ? moment(value).utcOffset(0).format('MMM YYYY') : ''
}

export function dateFormatter({ value }: ValueFormatterParams) {
  return formatDate(value)
}

export async function parallelHandler<T>(
  promiseFunction: (index: number, prev: T) => Promise<T>,
  maxConnections: number,
  hasNextFn: (index: number, prev: T) => boolean,
) {
  const executors = Array(maxConnections).fill(Promise.resolve())

  const chainPromise = async (p: Promise<any>, index: number): Promise<any> => {
    p.catch(e => console.info(e))
    const prev = await p
    if (hasNextFn(index, prev)) {
      return chainPromise(promiseFunction(index, prev), index)
    }
    return prev
  }

  await Promise.all(executors.map(chainPromise).map(p => p.catch(e => console.info(e))))
}

export async function parallelChunkHandler<T, R>(
  data: T[],
  promiseFunction: (chunk: T[]) => Promise<R>,
  chunkSize = 250,
  maxConnections = 8,
) {
  let offset = 0
  let result: R[] = []
  await parallelHandler(
    async () => {
      offset += chunkSize
      if (offset - chunkSize < data.length) {
        const partialResult = await promiseFunction(data.slice(offset - chunkSize, offset))

        result = result.concat(partialResult)
      }
    },
    maxConnections,
    () => {
      return offset < data.length
    },
  )
  return result
}

export function getNumberWithOrdinal(n: number) {
  const s = ['th', 'st', 'nd', 'rd'],
    v = n % 100
  return n + (s[(v - 20) % 10] || s[v] || s[0])
}

export function roundFloat(n: number, decima: number) {
  return Math.round((n + Number.EPSILON) * Math.pow(10, decima)) / Math.pow(10, decima)
}

export function convertToLimitAndOffset(page: number, pageSize: number): Pagination {
  return { limit: pageSize, offset: (page - 1) * pageSize }
}
