import React from 'react'
import styled from 'styled-components'
import { Row, Col } from 'antd'
import { Card } from '../components/Card'
import { User } from '../../api'
import { UserOrganizationListItem } from '../Manage/User/UserOrganizationListItem'

type Props = {
  user: User
}

const ClientName = styled.div`
  font-size: 18px;
  font-weight: 600;
`

const InformationLabel = styled.div`
  text-transform: uppercase;
  font-size: 10px;
`

export const UserOrganizationsCard: React.FC<Props> = ({ user }) => {
  return (
    <Card title="Organizations List" width="100%" height="100%">
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <InformationLabel>Client</InformationLabel>
          <ClientName>{user.customer.name}</ClientName>
        </Col>
      </Row>
      <InformationLabel>Access</InformationLabel>
      {user.organizations.map(organization => (
        <UserOrganizationListItem
          organization={organization}
          isPowerUser={
            user.userOrganizations.find(({ organizationsId }) => organizationsId === organization.id)?.isPowerUser ||
            false
          }
        />
      ))}
    </Card>
  )
}
