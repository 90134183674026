import { gql, QueryHookOptions, useQuery, useSubscription, SubscriptionHookOptions, useLazyQuery } from '@apollo/client'
import { Query, QueryExportErrorsArgs, QueryUploadJobArgs, Subscription } from '../gen/models'

export const UploadJobFullFragment = gql`
  fragment UploadJobFullFragment on UploadJob {
    id
    jobType
    s3Key
    mappings
    queryString
    status
    message
    result_stats
    callbackUrl
    parameters
    errors
    uploaded_search_id
    createdAt
    updatedAt
    hasErrors
    currentProgress {
      total
      current
      message
    }
  }
`

export const UploadJobSubFragment = gql`
  fragment UploadJobSubFragment on UploadJob {
    id
    status
    message
    result_stats
    jobType
    createdAt
    updatedAt
    currentProgress {
      total
      current
      message
    }
  }
`

const EXPORT_HISTORIES = gql`
  query exportHistories {
    exportHistories {
      data {
        id
        s3Key
        updatedAt
        result_stats
      }
    }
  }
`

export const useQueryExportHistories = (baseOptions?: QueryHookOptions<Pick<Query, 'exportHistories'>>) =>
  useQuery(EXPORT_HISTORIES, baseOptions)

const UPLOAD_HISTORIES = gql`
  query uploadHistories {
    uploadHistories {
      data {
        id
        s3Key
        queryString
        uploaded_search_id
        updatedAt
        result_stats
      }
    }
  }
`

export const useQueryUploadHistories = (baseOptions?: QueryHookOptions<Pick<Query, 'uploadHistories'>>) =>
  useQuery(UPLOAD_HISTORIES, baseOptions)

export const UPDATED_JOB_SUBSCRIPTION = gql`
  subscription updatedJob($id: String!) {
    updatedJob(id: $id) {
      ...UploadJobSubFragment
    }
  }
  ${UploadJobSubFragment}
`

export const useUpdatedJobSubscription = (options: SubscriptionHookOptions<Pick<Subscription, 'updatedJob'>>) =>
  useSubscription(UPDATED_JOB_SUBSCRIPTION, options)

const EXPORT_ERRORS = gql`
  query exportErrors($input: IDArgs!) {
    exportErrors(input: $input)
  }
`

export const useLazyQueryExportErrors = (
  baseOptions?: QueryHookOptions<Pick<Query, 'exportErrors'>, QueryExportErrorsArgs>,
) => useLazyQuery(EXPORT_ERRORS, baseOptions)

const UPLOAD_JOB = gql`
  query uploadJob($id: IDArgs!) {
    uploadJob(id: $id) {
      ...UploadJobFullFragment
    }
  }
  ${UploadJobFullFragment}
`

export const useQueryUploadJob = (baseOptions?: QueryHookOptions<Pick<Query, 'uploadJob'>, QueryUploadJobArgs>) =>
  useQuery(UPLOAD_JOB, baseOptions)
