import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { useAuth0 } from '@auth0/auth0-react'
import { useAbility } from '@casl/react'
import { Button as AntdButton, Button, Dropdown, Layout, Menu } from 'antd'
import { ItemType, MenuItemGroupType } from 'antd/lib/menu/hooks/useItems'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { OfficialCurvoLogoSVG } from 'src/ui/svgs'
import styled from 'styled-components'
import { MeContext, UserPermissionFeature, useRemoveUserCloak } from '../../api'
import { AbilityContext } from '../../api/components/Can'
import { HelpMenu } from './HelpMenu'
import { UserHeaderSection } from './UserHeaderSection'

const navSections: NavigationSectionItemProps[] = [
  {
    group: 'At a Glance',
    subMenus: [
      {
        name: 'Market Baskets',
        path: '/analytics/baskets',
        ability: UserPermissionFeature.Baskets,
      },
      {
        name: 'Contracts',
        path: '/analytics/contracts',
        ability: UserPermissionFeature.Contracts,
      },
      {
        name: 'RFx',
        path: '/strategic-sourcing/opportunities',
        ability: UserPermissionFeature.Opportunities,
      },
      {
        name: 'Collaboration',
        path: '/aperture/collaboration',
        ability: UserPermissionFeature.ProjectAperture,
      },
    ],
  },
  {
    group: 'In Depth',
    subMenus: [
      {
        name: 'Data Explorer',
        path: '/analytics/spending',
        ability: UserPermissionFeature.Spending,
      },
      {
        name: 'Reports',
        path: '/analytics/reports/console',
        ability: UserPermissionFeature.Reports,
      },
      {
        name: 'Sourcing Plans',
        path: '/strategic-sourcing/sourcing-plans',
        ability: UserPermissionFeature.SourcingPlans,
      },
      {
        name: 'Project Tracker',
        path: '/strategic-sourcing/project-tracker',
        ability: UserPermissionFeature.ProjectTracker,
      },
      {
        name: 'CX Project Tracker',
        path: '/strategic-sourcing/cx-project-tracker',
        ability: UserPermissionFeature.CxProjectTracker,
      },
    ],
  },
  {
    group: 'Enrich',
    subMenus: [
      {
        name: 'My Queue',
        path: '/enrich',
        ability: UserPermissionFeature.Enrich,
      },
      {
        name: 'Team Queue',
        path: '/enrich/team-queue',
        ability: UserPermissionFeature.TeamQueue,
      },
      {
        name: 'Manage Catalog',
        path: '/enrich/catalog',
        ability: UserPermissionFeature.Catalog,
      },
      {
        name: 'Import Study',
        path: '/enrich/import-study',
        ability: UserPermissionFeature.ImportStudy,
      },
    ],
  },
]

const adminNavSections: NavigationSectionItemProps[] = [
  {
    group: 'Project Aperture',
    subMenus: [
      {
        name: 'PO Spend',
        path: '/project-aperture/spend',
      },
      {
        name: 'Case Spend',
        path: '/project-aperture/cases',
      },
      {
        name: 'Enloe filtered for ZB',
        path: '/project-aperture/enloe-zb',
      },
      {
        name: 'Trinity filtered for ZB',
        path: '/project-aperture/trinity-zb',
      },
    ],
  },
  {
    group: 'Analytics',
    subMenus: [
      {
        name: 'Reports',
        path: '/analytics/reports/console',
      },
    ],
  },
  {
    group: 'Manage',
    subMenus: [
      {
        name: 'Customers',
        path: '/manage/customer',
      },
      {
        name: 'Users',
        path: '/manage/users',
      },
      {
        name: 'Featured Contents',
        path: '/manage/homepage/featured-contents',
      },
    ],
  },
]

type MainSideBarProps = {}

export const MainSideBar: React.FC<MainSideBarProps> = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const { data } = useContext(MeContext)
  const ability = useAbility(AbilityContext)
  const { logout } = useAuth0()
  const [removeCloak] = useRemoveUserCloak()

  const [collapsed, setCollapsed] = useState(false)
  const [activeItem, setActiveItem] = useState<string>()

  const user = data && data.me && data.me.user
  const isAdmin = data && data.me && data.me.isAdmin
  const isPowerUser = (data?.me.powerUserList || []).filter(org => org.isPowerUser).length > 0

  const menuLogout = async () => {
    await logout()
  }

  const handleExitCloaking = async () => {
    await removeCloak()
    window.location.replace('/')
  }

  const filteredNavigationItems = useMemo(() => {
    if (isAdmin) {
      return adminNavSections
    }

    return navSections
      .map(n => ({
        ...n,
        subMenus: n.subMenus
          ?.filter(s => !s.ability || ability.can('access', s.ability))
          // Remove ProjectTracker menu item when cloaking due to unable to authorize with Appian using SSO
          .filter(s => !user?.cloakUserId || s.ability !== UserPermissionFeature.ProjectTracker),
      }))
      .filter(n => n.subMenus?.length)
  }, [ability, user?.cloakUserId, isAdmin])

  const mainMenuItems: ItemType[] = useMemo(
    () => [
      { label: 'Home', key: '/' },
      ...filteredNavigationItems.map(i => ({
        type: 'group',
        label: i.group,
        key: i.group,
        children: i.subMenus?.map(si => ({ label: si.name, key: si.path })),
      })),
    ],
    [filteredNavigationItems],
  )

  useEffect(() => {
    if (location.pathname === '/') {
      setActiveItem('/')
      return
    }

    const matchedMenu = mainMenuItems
      .flatMap(i => (i as MenuItemGroupType).children || [])
      .find(sub => sub?.key && location.pathname.includes(sub.key as string))

    if (matchedMenu) {
      setActiveItem(matchedMenu.key as string)
    }
  }, [mainMenuItems, location.pathname])

  return (
    <StyledSider trigger={null} collapsed={collapsed} collapsible collapsedWidth={24} width={165}>
      <LogoSection collapsed={collapsed}>
        <a href="/" hidden={collapsed}>
          <OfficialCurvoLogoSVG />
        </a>
        <Button
          icon={collapsed ? <RightOutlined /> : <LeftOutlined />}
          type="text"
          style={{ color: 'var(--color-neutral-0)' }}
          onClick={() => setCollapsed(c => !c)}
        />
      </LogoSection>
      <MenuWrapper hidden={collapsed}>
        <div>
          <MainMenu
            items={mainMenuItems}
            selectedKeys={activeItem ? [activeItem] : []}
            onClick={({ key }) => navigate(key)}
          />
          <BottomMenu>
            {user && (isAdmin || user.cloakUserId) ? <UserHeaderSection hideAvatar user={user} /> : null}
            <Dropdown
              placement="bottomRight"
              destroyPopupOnHide={true} // when menu is reopened, prevent focus from being on last focused item when keyboard navigating
              overlay={<HelpMenu />}
              trigger={['click']}>
              <HelpButton>Help &amp; Support</HelpButton>
            </Dropdown>
            {user && (
              <Button type="text" onClick={() => navigate(`/users/${user.id}`)}>
                Profile
              </Button>
            )}
            {isAdmin ? (
              <Button type="text" onClick={() => navigate('/manage/customer')}>
                Customers
              </Button>
            ) : (
              <Button type="text" onClick={() => navigate(`/manage/customer/${user?.customer.id}`)}>
                Settings
              </Button>
            )}
            {isPowerUser && (
              <Button type="text" onClick={() => navigate('/manage/users')}>
                Users
              </Button>
            )}
            {user?.cloakUserId && (
              <Button type="text" onClick={handleExitCloaking}>
                Exit Cloaking
              </Button>
            )}
            <Button type="text" onClick={menuLogout}>
              Log Out
            </Button>
          </BottomMenu>
        </div>
      </MenuWrapper>
    </StyledSider>
  )
}

type NavigationSectionItemProps = {
  group: string
  subMenus?: { name: string; path: string; ability?: UserPermissionFeature }[]
}

const StyledSider = styled(Layout.Sider)`
  display: flex;
  flex-direction: column;
  background: var(--color-neutral-90);

  > .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
    padding: 24px 0px;
    gap: 24px;
  }
`

const MenuWrapper = styled.div`
  display: flex;
  overflow: hidden auto;
  flex-grow: 1;

  > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    flex: 1 0 0;
    gap: 48px;
  }
`

const LogoSection = styled.div<{ collapsed: boolean }>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${props => (props.collapsed ? '0' : '0px 24px')};
  gap: 16px;
`

const MainMenu = styled(Menu)`
  width: 100%;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  border: none;

  li,
  ul {
    width: 100%;
  }

  .ant-menu-item {
    padding: 4px 24px;
    align-items: center;
    color: #ffffff;
    height: auto;
    line-height: 1.5em;
    margin: 0 !important;
  }

  .ant-menu-item-group-title {
    padding: 4px 24px;
    height: auto;
    line-height: 1.2em;
    color: var(--color-neutral-60);
  }

  &.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: var(--color-blue-50);
  }

  .ant-menu-item-active {
    color: #ffffff !important;
  }
`

const BottomMenu = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;
  padding: 0 24px;
  max-width: 100%;

  button:not(.ant-dropdown-trigger) {
    padding: 0;
    justify-content: start;
    color: #ffffff;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    height: auto;
  }

  button:hover {
    color: #ffffff;
  }
`

const HelpButton = styled(AntdButton)`
  background-color: ${props => props.theme.ColorNeutral90};
  color: ${props => props.theme.ColorNeutral40};
  border: 2px solid ${props => props.theme.ColorNeutral60};

  padding: 5px 8px;
  display: flex;
  gap: 8px;

  :hover {
    background-color: ${props => props.theme.ColorNeutral90};
    color: ${props => props.theme.ColorNeutral0};
    border: 2px solid ${props => props.theme.ColorNeutral80};
  }
  :focus-visible {
    background-color: ${props => props.theme.ColorNeutral80};
    color: ${props => props.theme.ColorNeutral0};
    border: 2px solid ${props => props.theme.ColorBlue50} !important;
  }
  :focus {
    background-color: ${props => props.theme.ColorNeutral80};
    color: ${props => props.theme.ColorNeutral0};
    border: 2px solid ${props => props.theme.ColorNeutral80};
  }
`
