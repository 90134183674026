import React from 'react'
import { Routes, Route } from 'react-router-dom'
import { Layout } from 'antd'
import { Profile } from '../Users/Profile'
import { UserProfileView } from '../Users/UserProfileView'
import { AdminGuardedRoute } from '../components/AdminGuardedRoute'
import { ClientManagementPage } from './Client/ClientMangementPage'
import { ClientDetailPage } from './Client/ClientDetailPage'
import { Users } from './Users'
import { ManageUserPage } from './ManageUserPage'
import { OrganizationDetailPage } from './Organization/OrganizationDetailPage'
import { HomepageFeaturedContent } from './HomepageFeaturedContent'

export const ManagePage: React.FC = () => {
  return (
    <Layout.Content>
      <Routes>
        <Route
          path="/customer"
          element={
            <AdminGuardedRoute>
              <ClientManagementPage />
            </AdminGuardedRoute>
          }
        />
        <Route path="/customer/:customerId" element={<ClientDetailPage />} />
        <Route path="/customer/:customerId/organization/:organizationId" element={<OrganizationDetailPage />} />
        <Route path="/users" element={<Users />} />
        <Route path="/users/:id" element={<ManageUserPage />} />
        <Route path="/homepage/featured-contents" element={<HomepageFeaturedContent />} />
      </Routes>
    </Layout.Content>
  )
}

export const UserPage: React.FC = () => {
  return (
    <Layout.Content>
      <Routes>
        <Route path="/:id/edit" element={<Profile />} />
        <Route path="/:id" element={<UserProfileView />} />
      </Routes>
    </Layout.Content>
  )
}
