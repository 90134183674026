export const ColorNeutral0 = '#ffffff'
export const ColorNeutral10 = '#f6f7f9'
export const ColorNeutral20 = '#f3f5f8'
export const ColorNeutral30 = '#eceef3'
export const ColorNeutral40 = '#d9dce2'
export const ColorNeutral50 = '#bdc2ca'
export const ColorNeutral60 = '#8f96a3'
export const ColorNeutral70 = '#6f7785'
export const ColorNeutral80 = '#293347'
export const ColorNeutral90 = '#172237'
export const ColorBlue10 = '#f0f5ff'
export const ColorBlue20 = '#e5edff'
export const ColorBlue50 = '#3377ff'
export const ColorBlue70 = '#135BEC'
export const ColorGreen10 = '#f1fdf9'
export const ColorGreen50 = '#10a882'
export const ColorGreen70 = '#12735A'
export const ColorRed10 = '#fef5f7'
export const ColorRed50 = '#f91f1f'
export const ColorRed70 = '#BD2828'
export const ColorOrange10 = '#fff4f0'
export const ColorOrange40 = '#f8804f'
export const ColorOrange50 = '#f4642a'
export const ColorOrange70 = '#D34E17'
export const ColorAccentDaybreakBlue10 = '#e6f7ff'
export const ColorAccentDaybreakBlue50 = '#40a9ff'
export const ColorAccentDaybreakBlue70 = '#096dd9'
export const ColorAccentDustRed10 = '#fff1f0'
export const ColorAccentDustRed50 = '#ff4d4f'
export const ColorAccentDustRed70 = '#cf1322'
export const ColorAccentVolcano10 = '#fff2e8'
export const ColorAccentVolcano50 = '#ff7a45'
export const ColorAccentVolcano70 = '#d4380d'
export const ColorAccentSunsetOrange10 = '#fff7e6'
export const ColorAccentSunsetOrange50 = '#ffa940'
export const ColorAccentSunsetOrange70 = '#d46b08'
export const ColorAccentGold10 = '#fffbe6'
export const ColorAccentGold50 = '#ffc53d'
export const ColorAccentGold70 = '#d48806'
export const ColorAccentSunriseYellow10 = '#feffe6'
export const ColorAccentSunriseYellow50 = '#fadb14'
export const ColorAccentSunriseYellow70 = '#d4b106'
export const ColorAccentLime10 = '#fcffe6'
export const ColorAccentLime50 = '#a0d911'
export const ColorAccentLime70 = '#7cb305'
export const ColorAccentPolarGreen10 = '#f6ffed'
export const ColorAccentPolarGreen50 = '#73d13d'
export const ColorAccentPolarGreen70 = '#389e0d'
export const ColorAccentCyan10 = '#e6fffb'
export const ColorAccentCyan50 = '#36cfc9'
export const ColorAccentCyan70 = '#08979c'
export const ColorAccentGeekBlue10 = '#f0f5ff'
export const ColorAccentGeekBlue50 = '#597ef7'
export const ColorAccentGeekBlue70 = '#1d39c4'
export const ColorAccentPurple10 = '#f9f0ff'
export const ColorAccentPurple50 = '#9254de'
export const ColorAccentPurple70 = '#531dab'
export const ColorAccentMagenta10 = '#fff0f6'
export const ColorAccentMagenta50 = '#f759ab'
export const ColorAccentMagenta70 = '#c41d7f'
export const Shadow1 =
  '0 0.1875rem 0.375rem -0.25rem #0000001f, 0 0.375rem 1rem #00000014, 0 0.5625rem 1.75rem .5rem #0000000d'
export const SpaceXxs = '0.25rem'
export const SpaceXs = '0.5rem'
export const SpaceS = '0.75rem'
export const SpaceM = '1rem'
export const SpaceL = '1.5rem'
export const SpaceXl = '2.5rem'
export const SpaceXxl = '4rem'
export const SpaceXxxl = '6rem'
export const BorderWidthThick = '2px'
export const BorderWidthDefault = '1px'
export const BorderWidthThin = '1px'
export const BorderRadiusDefault = '0.25rem'
export const BorderRadiusSmall = '0.125rem'
export const PageWidthFixedMin = '56rem'
export const PageWidthFixedMax = '75rem'
export const TextFontFamilyPrimary = "'IBM Plex Sans', 'Roboto', sans-serif"
export const TextFontFamilySecondary = "'IBM Plex Sans', 'Roboto', sans-serif"
export const TextBaseFontSize = '16px'
export const TextSmallRegularFontSize = '0.75rem'
export const TextSmallRegularFontFamily = "'IBM Plex Sans', 'Roboto', sans-serif"
export const TextSmallRegularFontWeight = 400
export const TextSmallRegularLineHeight = '1.25rem'
export const TextSmallMediumFontSize = '0.75rem'
export const TextSmallMediumFontFamily = "'IBM Plex Sans', 'Roboto', sans-serif"
export const TextSmallMediumFontWeight = 500
export const TextSmallMediumLineHeight = '1.25rem'
export const TextDefaultRegularFontSize = '0.875rem'
export const TextDefaultRegularFontFamily = "'IBM Plex Sans', 'Roboto', sans-serif"
export const TextDefaultRegularFontWeight = 400
export const TextDefaultRegularLineHeight = '1.375rem'
export const TextDefaultMediumFontSize = '0.875rem'
export const TextDefaultMediumFontFamily = "'IBM Plex Sans', 'Roboto', sans-serif"
export const TextDefaultMediumFontWeight = 500
export const TextDefaultMediumLineHeight = '1.375rem'
export const TextHeadingXsmallFontSize = '0.75rem'
export const TextHeadingXsmallFontFamily = "'IBM Plex Sans', 'Roboto', sans-serif"
export const TextHeadingXsmallFontWeight = 500
export const TextHeadingXsmallLineHeight = '1.23rem'
export const TextHeadingXsmallLetterSpacing = '0.03rem'
export const TextHeadingXsmallTextTransform = 'uppercase'
export const TextHeadingSmallFontSize = '1rem'
export const TextHeadingSmallFontFamily = "'IBM Plex Sans', 'Roboto', sans-serif"
export const TextHeadingSmallFontWeight = 500
export const TextHeadingSmallLineHeight = '1.5rem'
export const TextHeadingLargeFontSize = '1.25rem'
export const TextHeadingLargeFontFamily = "'IBM Plex Sans', 'Roboto', sans-serif"
export const TextHeadingLargeFontWeight = 500
export const TextHeadingLargeLineHeight = '1.75rem'
export const TextDisplaySmallFontSize = '1.25rem'
export const TextDisplaySmallFontFamily = "'IBM Plex Sans', 'Roboto', sans-serif"
export const TextDisplaySmallFontWeight = 400
export const TextDisplaySmallLineHeight = '1.75rem'
export const TextDisplayLargeFontSize = '1.5rem'
export const TextDisplayLargeFontFamily = "'IBM Plex Sans', 'Roboto', sans-serif"
export const TextDisplayLargeFontWeight = 400
export const TextDisplayLargeLineHeight = '2rem'
