import { Button, Card, Typography } from 'antd'
import React from 'react'
import { useNavigate } from 'react-router'
import { HomepageFeaturedContent, useUserQueryFeaturedContents } from 'src/api'
import styled from 'styled-components'
import { HomeCarousel } from './HomeCarousel'
import { HomeSection } from './HomeSection'

export const FeaturedContentCarousel: React.FC = () => {
  const { data, loading } = useUserQueryFeaturedContents({})
  return <FeaturedContentCarouselComponent items={data?.userHomepageFeaturedContents} loading={loading} />
}

export const FeaturedContentCarouselComponent: React.FC<{
  items?: (Omit<HomepageFeaturedContent, 'id' | 'priority'> & { id?: string | null })[]
  loading?: boolean
}> = ({ items, loading }) => {
  return (
    <HomeSection title="">
      <HomeCarousel
        dots
        infinite
        autoplaySpeed={5000}
        pauseOnFocus
        pauseOnHover
        loading={loading}
        items={items || []}
        renderItem={item => <FeaturedContentCard item={item} />}
        slidesToShow={1}
      />
    </HomeSection>
  )
}

const FeaturedContentCard: React.FC<{ item: Omit<HomepageFeaturedContent, 'id' | 'priority'> }> = ({ item }) => {
  const navigate = useNavigate()
  return (
    <FeaturedContentCardWrapper>
      <div className="fc-body">
        <div className="fc-text">
          <Typography.Title level={3}>{item.title}</Typography.Title>
          <div>{item.description}</div>
          {item.url ? (
            item.url.match(/^https?:\/\//) ? (
              <Button type="primary" href={item.url!} target="_blank">
                Learn More
              </Button>
            ) : (
              <Button type="primary" onClick={() => navigate(item.url!)}>
                Learn More
              </Button>
            )
          ) : (
            <></>
          )}
        </div>
        <div className="fc-image">{item.imageUrl ? <img alt={item.title + ' Image'} src={item.imageUrl} /> : null}</div>
      </div>
    </FeaturedContentCardWrapper>
  )
}

const FeaturedContentCardWrapper = styled(Card)`
  .fc-body {
    display: flex;
    height: 400px;
    justify-content: space-between;
    align-items: center;
  }
  .fc-image {
    flex: 1;
    img {
      height: 380px;
      float: right;
    }
  }
  .fc-text {
    display: flex;
    flex-direction: column;
    min-width: 300px;
    .ant-btn {
      width: 120px;
      margin-top: 16px;
    }
  }
`
