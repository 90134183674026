import { gql, QueryHookOptions, useQuery } from '@apollo/client'
import { Query, QuerySuggestedVendorsArgs, QueryPublicVendorsArgs } from '../gen/models'

const VendorFragment = gql`
  fragment VendorFragment on CustomerView {
    id
    name
    address
    address2
    city
    state
    zipCode
    website
    phoneNumber
    medicareId
    geoCode
    isActive
    normalizedName
    mainContact {
      email
      firstName
      lastName
    }
  }
`

const PUBLIC_VENDORS_QUERY = gql`
  query publicVendors($input: GetVendorsByCriteriaInput!, $pagination: Pagination, $sort: Sort) {
    publicVendors(input: $input, pagination: $pagination, sort: $sort) {
      hits {
        ...VendorFragment
      }
      totalHits
    }
  }
  ${VendorFragment}
`

export function usePublicVendorsQuery(
  baseOptions?: QueryHookOptions<Pick<Query, 'publicVendors'>, QueryPublicVendorsArgs>,
) {
  return useQuery(PUBLIC_VENDORS_QUERY, baseOptions)
}

const SUGGESTED_VENDORS_QUERY = gql`
  query suggestedVendors($input: GetSuggestedVendorsInput!) {
    suggestedVendors(input: $input) {
      hits {
        ...VendorFragment
      }
      totalHits
    }
  }
  ${VendorFragment}
`

export function useSuggestedVendorsQuery(
  baseOptions?: QueryHookOptions<Pick<Query, 'suggestedVendors'>, QuerySuggestedVendorsArgs>,
) {
  return useQuery(SUGGESTED_VENDORS_QUERY, baseOptions)
}
