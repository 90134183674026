import { ArrowDownOutlined, ArrowUpOutlined, MoreOutlined } from '@ant-design/icons'
import { Button, Card, Dropdown, message, Tag } from 'antd'
import { isNil } from 'lodash'
import React, { useCallback, useMemo } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Basket, BasketType, useArchiveBasketMutation, useUnarchiveBasketMutation } from '../../../api'
import * as dt from '../../../ui/design-tokens/tokens'
import { BasketListItemStatistics } from './BasketListItemStatistics'

type Props = {
  basket: Basket
  targetPercentile: number
  featuredTag?: string | null
  onCopyCurvoBasket?: (catalogId: string) => any
}

export const BasketListItem: React.FC<Props> = ({ basket, targetPercentile, onCopyCurvoBasket, featuredTag }) => {
  const [archiveBasket] = useArchiveBasketMutation()
  const [unarchiveBasket] = useUnarchiveBasketMutation()

  const featuredTagDisplay = useMemo(() => {
    switch (featuredTag) {
      case 'biggest_impact12m':
        return {
          color: 'var(--color-red-50)',
          borderColor: 'var(--color-red-50)',
          backgroundColor: 'transparent',
          element: (
            <>
              Price <ArrowUpOutlined />
            </>
          ),
        }
      case 'lowest_impact12m':
        return {
          color: 'var(--color-green-50)',
          borderColor: 'var(--color-green-50)',
          backgroundColor: 'transparent',
          element: (
            <>
              Price <ArrowDownOutlined />
            </>
          ),
        }
      case 'biggest_utilization':
        return {
          color: 'var(--color-red-50)',
          borderColor: 'var(--color-red-50)',
          backgroundColor: 'transparent',
          element: (
            <>
              Utilization <ArrowUpOutlined />
            </>
          ),
        }
      case 'lowest_utilization':
        return {
          color: 'var(--color-green-50)',
          borderColor: 'var(--color-green-50)',
          backgroundColor: 'transparent',
          element: (
            <>
              Utilization <ArrowDownOutlined />
            </>
          ),
        }
      case 'best_opportunity75':
        return {
          color: 'var(--color-green-50)',
          borderColor: 'var(--color-green-50)',
          backgroundColor: 'transparent',
          element: <>Top Opportunity</>,
        }
      case 'highest_total_spend':
        return {
          color: 'var(--color-green-50)',
          borderColor: 'var(--color-green-50)',
          backgroundColor: 'transparent',
          element: <>Top Spend</>,
        }
      default:
        return null
    }
  }, [featuredTag])

  const handleArchive = useCallback(
    () =>
      archiveBasket({ variables: { id: basket.id } })
        .then(() => message.success('Archived Basket: ' + basket.name))
        .catch(() => message.error("Something's wrong, please try again")),
    [basket, archiveBasket],
  )

  return (
    <ListItemCard>
      <div className="header">
        {isNil(basket.archivedAt) ? (
          <Link to={`/analytics/baskets/${basket.id}/angles`} className="basket-name" title={basket.name}>
            {basket.name}
          </Link>
        ) : (
          <span className="basket-name">{basket.name}</span>
        )}
        <div className="header-extra">
          {basket.type === BasketType.Custom && <CustomTag color={dt.ColorBlue70}>Custom</CustomTag>}
          {basket.type === BasketType.Contract && <CustomTag color={dt.ColorRed70}>Contract</CustomTag>}
          {basket.type === BasketType.Uploaded && <CustomTag color={dt.ColorAccentPurple70}>Uploaded</CustomTag>}
          {featuredTagDisplay && (
            <FeatureTag
              color={featuredTagDisplay.color}
              backgroundColor={featuredTagDisplay.backgroundColor}
              borderColor={featuredTagDisplay.borderColor}>
              {featuredTagDisplay.element}
            </FeatureTag>
          )}
          {basket.type === BasketType.Custom || basket.type === BasketType.Uploaded ? (
            <Dropdown
              menu={{
                items: [
                  ...(isNil(basket.archivedAt)
                    ? [
                        {
                          key: basket.id + 'archive',
                          label: 'Archive',
                          onClick: handleArchive,
                        },
                      ]
                    : []),
                  ...(onCopyCurvoBasket && basket.isCatalogBasket && !isNil(basket.catalogId)
                    ? [
                        {
                          key: basket.id + 'copy',
                          onClick: () => onCopyCurvoBasket(basket.catalogId!),
                          label: 'Copy',
                        },
                      ]
                    : []),
                  ...(!isNil(basket.archivedAt)
                    ? [
                        {
                          key: basket.id,
                          onClick: () =>
                            unarchiveBasket({ variables: { id: basket.id } })
                              .then(() => message.success('Unarchived Basket: ' + basket.name))
                              .catch(() => message.error("Something's wrong, please try again")),
                          label: 'Unarchive',
                        },
                      ]
                    : []),
                ],
              }}>
              <ActionButton icon={<MoreOutlined />} shape="circle" />
            </Dropdown>
          ) : null}
          {basket.type === BasketType.Catalog && onCopyCurvoBasket ? (
            <Dropdown
              menu={{
                items: [
                  ...(basket.isCatalogBasket && !isNil(basket.catalogId)
                    ? [{ key: basket.id + 'copy', onClick: () => onCopyCurvoBasket(basket.catalogId!), label: 'Copy' }]
                    : []),
                ],
              }}>
              <ActionButton icon={<MoreOutlined />} shape="circle" />
            </Dropdown>
          ) : null}
        </div>
      </div>
      <BasketListItemStatistics basket={basket} targetPercentile={targetPercentile} />
    </ListItemCard>
  )
}

const ListItemCard = styled(Card)`
  background: #fff;
  border-radius: 4px;
  margin-bottom: 16px;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    gap: 24px;

    .basket-name {
      font-size: 16px;
      line-height: 22px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .header-extra {
      display: flex;
      flex-direction: row;
      gap: 16px;
      text-wrap: nowrap;
    }
  }
`

const ActionButton = styled(Button)`
  border: none;
  width: 4px;
  height: 22px;
  min-width: 4px;
`

const CustomTag = styled(Tag)`
  font-weight: 500;
`

const FeatureTag = styled.div<{ backgroundColor: string; color: string; borderColor: string }>`
  display: flex;
  padding: 2px 10px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  gap: 4px;
  color: ${props => props.color};
  background-color: ${props => props.backgroundColor};
  border: 1px solid ${props => props.borderColor};
`
