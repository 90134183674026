import { AgGridReact, AgGridReactProps } from '@ag-grid-community/react'
import CurvoLogo from 'src/LogoColorDark.png'
import { UserPermissionFeature } from '../api'

export const CUSTOM_BASKET_PRODUCTS_COUNT_LIMIT = 15000
export const DOCUMENT_MAX_SIZE = 10 // in MB

export const ALL_ANALYZE_FEATURES = [
  UserPermissionFeature.Contracts,
  UserPermissionFeature.Baskets,
  UserPermissionFeature.Spending,
  UserPermissionFeature.Reports,
]
export const ALL_ACTIVATE_FEATURES = [UserPermissionFeature.Opportunities, UserPermissionFeature.SourcingPlans]
export const ALL_ACTIVATE_EXPLICIT_FEATURES = [
  UserPermissionFeature.ProjectTracker,
  UserPermissionFeature.CxProjectTracker,
]
export const ALL_ENRICH_FEATURES = [
  UserPermissionFeature.TeamQueue,
  UserPermissionFeature.Catalog,
  UserPermissionFeature.ImportStudy,
]

export const enum LaunchDarklyFeatures {
  priceDirection = 'priceDirection',
  l12mBenchmarks = 'l12mBenchmarks',
  similarProducts = 'data-explorer-similar-products',
  extendedSimilarProducts = 'data-explorer-similar-products-extended',
  clinicalRelevantStudies = 'data-explorer-clinical-relevant-studies',
  basketSmartRules = 'basketSmartRules',
  contractMarketDefinition = 'contract-market-definition',
}

export const GENERAL_INPUT_NAME_MAX_LENGTH = 75
export const BASKET_INPUT_NAME_MAX_LENGTH = 100
export const CURVO_XLSX_HEADER_COLOR = 'e26b09'

export const AgGridPropsForExcelExport = (gridRef: React.RefObject<AgGridReact>): AgGridReactProps => ({
  suppressCsvExport: true,
  excelStyles: [
    {
      id: 'header',
      alignment: {
        vertical: 'Center',
      },
      font: {
        bold: true,
      },
      interior: {
        color: CURVO_XLSX_HEADER_COLOR,
        pattern: 'Solid',
        patternColor: undefined,
      },
      borders: {
        borderTop: {
          color: '#000000',
          weight: 1,
        },
        borderBottom: {
          color: '#000000',
          weight: 1,
        },
        borderLeft: {
          color: '#000000',
          weight: 1,
        },
        borderRight: {
          color: '#000000',
          weight: 1,
        },
      },
    },
    {
      id: 'cell',
      borders: {
        borderTop: {
          color: '#000000',
          weight: 1,
        },
        borderBottom: {
          color: '#000000',
          weight: 1,
        },
        borderLeft: {
          color: '#000000',
          weight: 1,
        },
        borderRight: {
          color: '#000000',
          weight: 1,
        },
      },
    },
  ],
  defaultExcelExportParams: {
    prependContent: [
      [
        {
          data: {
            type: 'String',
            value: CurvoLogo, // see imageUtils
          },
          mergeAcross: 1,
        },
      ],
      [
        {
          data: {
            type: 'String',
            value: 'Copyright © Curvo Labs, Inc.',
          },
        },
      ],
    ],
    rowHeight: ({ rowIndex }) => (rowIndex === 1 ? 80 : 20),
    addImageToCell: (rowIndex, col, value) => {
      if (rowIndex === 1 && gridRef.current!.columnApi.getAllDisplayedColumns()[0].getColId() === col.getColId()) {
        return {
          image: {
            id: 'logo',
            base64: value,
            imageType: 'png',
            width: 268,
            height: 80,
            fitCell: false,
            position: {
              colSpan: 2,
              row: 1,
            },
          },
        }
      }
      return undefined
    },
  },
})
