import React from 'react'
import { Tag } from 'antd'
import { User } from '../../api/gen/models'

type Props = {
  user: User // TODO Replace with user entity type
}

export const RoleTags: React.FC<Props> = ({ user }) => {
  return (
    <Tag color={user.isCurvoAdmin ? '#fff5f1' : '#edeeef'} style={{ color: user.isCurvoAdmin ? '#f8804f' : '#172237' }}>
      {user.isCurvoAdmin ? 'admin' : 'user'}
    </Tag>
  )
}
