import { gql, QueryHookOptions, useQuery } from '@apollo/client'
import { Query } from '../gen/models'

const GET_STRING = gql`
  query getString {
    getString
  }
`

export const useGetString = (baseOptions?: QueryHookOptions<Pick<Query, 'getString'>>) => {
  return useQuery(GET_STRING, baseOptions)
}
