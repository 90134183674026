import { Form, message, Modal } from 'antd'
import { ModalProps } from 'antd/lib/modal'
import { omit } from 'lodash'
import React from 'react'
import { Customer, Organization, UpdateOrganizationInput, useUpdateOrganizationMutation } from '../../../api'
import { CreateEditOrganizationForm } from '../forms/CreateEditOrganizationForm'

type Props = ModalProps & {
  onSuccess?: (organization: Organization) => any
  onError?: (error: Error) => any
  organization?: Organization
  customer: Customer
}

export const UpdateOrganizationModal: React.FC<Props> = ({
  onSuccess,
  onError,
  organization,
  customer,
  ...modalProps
}) => {
  const [form] = Form.useForm()

  const [updateOrganization] = useUpdateOrganizationMutation()

  if (organization) {
    const updateOrg = {
      ...omit<Organization>(organization, [
        '__typename',
        'topUsers',
        'subOrganizations',
        'createdAt',
        'updatedAt',
        'totalUsers',
        'allParents',
        'topPowerUsers',
        'totalSubOrganizations',
        'organizationType',
      ]),
      parentId: organization.parentId || undefined,
    }
    form.resetFields()
    form.setFieldsValue(updateOrg)
  }

  const handleOnOk = async () => {
    try {
      await form.validateFields()
    } catch (e) {
      return
    }

    const input = form.getFieldsValue()
    updateOrganization({
      variables: {
        input: {
          ...input,
          id: organization?.id,
          facilityId: Number(input.facilityId) || undefined,
        } as UpdateOrganizationInput,
      },
    })
      .then(result => {
        message.success('Updated organization')
        if (onSuccess && result.data) {
          onSuccess(result.data.updateOrganization)
        }
      })
      .catch(e => {
        if (onError) {
          onError(e)
        } else {
          message.error(e.message)
        }
      })
  }

  return (
    <Modal {...modalProps} onOk={handleOnOk}>
      <CreateEditOrganizationForm
        parentId={organization?.parentId || undefined}
        form={form}
        customer={customer}
        isEditing
      />
    </Modal>
  )
}
