import React from 'react'
import { Form, Row, Col, InputNumber, Input } from 'antd'
import { FormInstance } from 'antd/lib/form'
import { Store } from 'antd/es/form/interface'
import FormItem from 'antd/es/form/FormItem'
import { StateSelector } from '../../components/selectors/StateSelector'

export const CreateEditClientForm: React.FC<{ form: FormInstance; onChange: (v: Store) => any }> = ({
  form,
  onChange,
}) => (
  <Form
    form={form}
    size="middle"
    layout="vertical"
    onValuesChange={v => {
      onChange(v)
    }}>
    <FormItem
      name="name"
      label="Client Name"
      rules={[
        {
          required: true,
          message: 'Please input the client name',
        },
      ]}>
      <Input />
    </FormItem>
    <FormItem
      label="Address 1"
      name="address"
      rules={[
        {
          required: true,
          message: 'Please input the address',
        },
      ]}>
      <Input />
    </FormItem>
    <FormItem label="Address 2" name="address2">
      <Input />
    </FormItem>
    <Row gutter={8}>
      <Col span={8}>
        <FormItem
          label="City"
          name="city"
          rules={[
            {
              required: true,
              message: 'Please input the city',
            },
          ]}>
          <Input />
        </FormItem>
      </Col>
      <Col span={8}>
        <FormItem
          label="State"
          name="state"
          rules={[
            {
              required: true,
              message: 'Please input the state',
            },
          ]}>
          <StateSelector />
        </FormItem>
      </Col>
      <Col span={8}>
        <FormItem
          label="Zip Code"
          name="zipCode"
          rules={[
            {
              required: true,
              message: 'Please input the zip code',
            },
          ]}>
          <Input />
        </FormItem>
      </Col>
    </Row>
    <FormItem label="Phone Number" name="phoneNumber">
      <Input />
    </FormItem>
    <FormItem label="Website" name="website">
      <Input />
    </FormItem>
    <FormItem label="Medicare ID" name="medicareId">
      <Input />
    </FormItem>
    <FormItem label="Geo Code" name="geoCode">
      <Input />
    </FormItem>
    <FormItem
      label="Max User"
      name="maxUser"
      rules={[
        {
          type: 'number',
          min: 1,
          required: true,
          message: 'Please input the number of maximum users',
        },
      ]}>
      <InputNumber />
    </FormItem>
  </Form>
)
