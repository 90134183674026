import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'
import { Button, Layout, Result } from 'antd'
import React, { Suspense, lazy, useContext } from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { ApolloClientWrapper, BackgroundJobProvider, MeContext, MeProvider } from '../api'
import { AbilityProvider } from '../api/components/Can'
import { PageLoading } from '../common/components/PageLoading'
import { Tracking } from '../tracking/Tracking'
import { CurvoConnectPage } from './CurvoConnect/CurvoConnectPage'
import { Home } from './Home/Home'
import { ManagePage, UserPage } from './Manage/ManagePage'
import { MainSideBar } from './components/MainSideBar'

type LegacyRedirectProps = {
  from: string
  to: string
}

const ActivateLazy = lazy(() => import('./Activate/Activate'))
const AnalyzeLazy = lazy(() => import('./Analyze/Analyze'))
const EnrichLazy = lazy(() => import('./Enrich/Enrich'))
const ProjectApertureLazy = lazy(() => import('./ProjectAperture/ProjectAperture'))

const LegacyRedirect: React.FC<LegacyRedirectProps> = ({ from, to }) => {
  const location = useLocation()
  const queryString = window.location.search
  return <Navigate to={`${location.pathname.replace(from, to)}${queryString || ''}`} />
}

export const MainRoutes: React.FC = () => {
  const { data } = useContext(MeContext)
  return (
    <Routes>
      <Route path="/auth" element={<Navigate to="/" />} />
      <Route path="/analyze/*" element={<LegacyRedirect from="/analyze" to="/analytics" />} />
      <Route path="/activate/*" element={<LegacyRedirect from="/activate" to="/strategic-sourcing" />} />
      <Route path="/manage/*" element={<ManagePage />} />
      <Route path="/project-aperture/*" element={<CurvoConnectPage />} />
      {/* TODO: This probably needs its own component */}
      <Route path="/users/*" element={<UserPage />} />

      <Route path="/enrich/*" element={<EnrichLazy />} />
      <Route path="/analytics/*" element={<AnalyzeLazy />} />
      <Route path="/strategic-sourcing/*" element={<ActivateLazy />} />
      <Route path="/aperture/*" element={<ProjectApertureLazy />} />
      {!data?.me?.isAdmin && (
        <>
          <Route path="/enrich/*" element={<EnrichLazy />} />
          <Route path="/analytics/*" element={<AnalyzeLazy />} />
          <Route path="/strategic-sourcing/*" element={<ActivateLazy />} />
        </>
      )}
      <Route path="/" element={<Home />} />
      <Route
        path="*"
        element={
          <Result
            status="404"
            title="404"
            subTitle="Sorry, the page you visited does not exist."
            extra={
              <Button type="primary" href="/">
                Back Home
              </Button>
            }
          />
        }
      />
    </Routes>
  )
}

export const Main: React.FC<{}> = () => {
  return (
    <ApolloClientWrapper>
      <MeProvider>
        <AbilityProvider>
          <BackgroundJobProvider>
            <Tracking />
            <Layout style={{ height: '100vh', overflow: 'hidden' }}>
              <MainSideBar />
              <Suspense fallback={<PageLoading />}>
                <MainContent>
                  <MainRoutes />
                </MainContent>
              </Suspense>
            </Layout>
          </BackgroundJobProvider>
        </AbilityProvider>
      </MeProvider>
    </ApolloClientWrapper>
  )
}

const MainContent = styled.div`
  flex: 1;
  overflow: auto;
`
