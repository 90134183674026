import { MutationHookOptions, gql, useMutation } from '@apollo/client'
import { Mutation, MutationCreateSupportTicketArgs } from '../gen/models'

const CREATE_SUPPORT_TICKET_MUTATION = gql`
  mutation createSupportTicket($input: SupportTicketInput!) {
    createSupportTicket(input: $input)
  }
`

export const useCreateSupportTicketMutation = (
  mutationHookOptions?: MutationHookOptions<Pick<Mutation, 'createSupportTicket'>, MutationCreateSupportTicketArgs>,
) => {
  return useMutation(CREATE_SUPPORT_TICKET_MUTATION, mutationHookOptions)
}
