import { CheckOutlined, PlusOutlined } from '@ant-design/icons'
import { Space, Spin, Typography } from 'antd'
import React from 'react'
import styled from 'styled-components'

export const CreateItemCard: React.FC<{
  title: string
  description?: string
  loading?: boolean
  requested?: boolean
  onClick?: CallableFunction
}> = ({ title, description, loading, requested, onClick }) => {
  return (
    <Wrapper onClick={() => onClick && !loading && !requested && onClick()} requested={requested}>
      <Space direction="vertical">
        <Typography.Text style={{ fontSize: 16 }}>{title}</Typography.Text>
        {description && <Typography.Text>{description}</Typography.Text>}
      </Space>
      <span className="extra-btn">
        {loading ? (
          <Spin />
        ) : requested ? (
          <CheckOutlined style={{ fontSize: 24 }} />
        ) : (
          <PlusOutlined style={{ fontSize: 24 }} />
        )}
      </span>
    </Wrapper>
  )
}

const Wrapper = styled.div<{ requested?: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: row;
  background: transparent;
  border-radius: 4px;
  border: 1px dashed var(--color-neutral-60);
  padding: 24px 48px 24px 24px;
  align-items: center;
  gap: 48px;
  cursor: ${props => (props.requested ? 'auto' : 'pointer')};

  :hover,
  :active {
    border: 1px solid var(--color-blue-70);

    .ant-typography {
      color: var(--color-blue-70);
    }

    .extra-btn {
      color: var(--color-blue-70);
    }
  }

  :active {
    background-color: var(--color-blue-10);
  }
`
