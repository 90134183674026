import * as H from 'history'
import React, { useContext, useEffect } from 'react'
import { useLocation } from 'react-router'
import { useLDClient } from 'launchdarkly-react-client-sdk'
import { MeContext } from '../api'
// @ts-ignore
import Env from '../api/gen/Env'
import { ErrorIgnoreBoundary } from '../common/components/ErrorBoundaries'

export const Tracking: React.FC = () => {
  const ldClient = useLDClient()
  const HEAP_APP_ID = '406735186'

  const location = useLocation()
  usePageViews(location)

  const { data: meData } = useContext(MeContext)

  useEffect(() => {
    if (meData?.me.user.id) {
      try {
        if (Env.useHeap) {
          heap.load(HEAP_APP_ID) // defined in public index.html
          heap.identify(meData.me.user.id)
          heap.addUserProperties({
            Name: `${meData.me.user.firstName} ${meData.me.user.lastName}`,
            Email: meData.me.user.email,
          })
        }
      } catch (e) {
        console.info(`Error while trying to identify the user ${JSON.stringify(e)}`)
      }
    }
  }, [meData])

  useEffect(() => {
    if (!ldClient || !meData?.me?.user.email) {
      return
    }

    ldClient.identify({
      kind: 'user',
      key: meData.me.user.email || 'anonymous',
    })
  }, [ldClient, meData?.me?.user.email])

  return <ErrorIgnoreBoundary />
}

function usePageViews(location: H.Location) {
  const { data } = useContext(MeContext)
  useEffect(() => {
    const rawWindow: any = window
    const _hsq = (rawWindow._hsq = rawWindow._hsq || [])
    if (!Env.useHubspot) {
      _hsq.push(['doNotTrack'])
      return
    }

    if (data?.me) {
      const identificationData = {
        email: data.me.user.email,
        curvox_created_at: new Date(data.me.user.createdAt).getTime(),
        curvox_customer_type: data.me.user.customer.type,
        curvox_last_action: new Date().getTime(),
        curvox_status_2: data.me.user.status,
      }
      _hsq.push(['identify', identificationData])
    }
    _hsq.push(['setPath', location.pathname])
    _hsq.push(['trackPageView'])
  }, [location.state, data, location.pathname])
}
