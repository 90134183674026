import { Card, Button, Drawer, Form, Input, message, Space, Skeleton, Empty, Alert } from 'antd'
import { MenuOutlined } from '@ant-design/icons'
import { isNil, max, omit } from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
  OnDragEndResponder,
} from '@hello-pangea/dnd'
import { Helmet } from 'react-helmet'
import styled from 'styled-components'
import { HomepageFeaturedContentUpdate, useBulkUpdateFeaturedContentsMutation, useQueryFeaturedContents } from 'src/api'
import { FormItemSingleImageUploadButton } from 'src/common/components/UploadArea'
import { CustomersSelector } from '../components/selectors/CustomersSelector'
import { FeaturedContentCarouselComponent } from '../Home/components/FeaturedContent'
import { BaseManagePageLayout } from './BaseManagePageLayout'

export const HomepageFeaturedContent: React.FC = () => {
  const [customerSearch, setCustomerSearch] = useState<string>()
  const [openCreate, setOpenCreate] = useState(false)
  // when this is true, users need to press Bulk Update to update the local changes
  const [needUpdating, setNeedUpdating] = useState(false)
  const [updates, setUpdates] = useState<HomepageFeaturedContentUpdate[]>([])
  const [updating, setUpdating] = useState(false)
  const [creating, setCreating] = useState(false)
  const [selectedCustomerId, setSelectedCustomerId] = useState<string>()

  const { data, refetch, loading } = useQueryFeaturedContents({
    variables: { input: { customerId: selectedCustomerId } },
  })
  const [bulkUpdateMutation] = useBulkUpdateFeaturedContentsMutation()
  const [formIns] = Form.useForm<HomepageFeaturedContentUpdate>()

  const handleCreateFeaturedContent = useCallback(
    (value: HomepageFeaturedContentUpdate) => {
      setOpenCreate(false)
      setUpdating(true)
      const maxPriority = max(updates.map(u => u.priority || -1)) || 0
      const maxGlobalPriority = max(updates.filter(u => isNil(u.customerId)).map(u => u.priority || -1)) || 0
      const newPriority = value.customerId ? maxPriority + 1 : maxGlobalPriority + 1000
      bulkUpdateMutation({
        variables: {
          input: {
            updates: [{ ...value, priority: value.id ? value.priority : newPriority }],
            customerId: selectedCustomerId,
            replaceAll: false,
          },
        },
      })
        .then(() => refetch())
        .then(() => message.success('Updated Featured Content'))
        .catch(() => message.error('Cannot update Featured Content'))
        .finally(() => {
          setUpdating(false)
          formIns.resetFields()
        })
    },
    [formIns, bulkUpdateMutation, selectedCustomerId, refetch, updates],
  )

  useEffect(() => {
    setUpdates(data?.homepageFeaturedContents || [])
  }, [data?.homepageFeaturedContents, selectedCustomerId])

  const handleOnDragEnd: OnDragEndResponder = useCallback(result => {
    if (result.destination) {
      setUpdates(old => {
        const newUpdates = [...old]
        if (result.destination) {
          let tmp = newUpdates[result.destination.index]
          newUpdates[result.destination.index] = newUpdates[result.source.index]
          newUpdates[result.source.index] = tmp
        }
        return newUpdates
      })
      setNeedUpdating(true)
    }
  }, [])

  const handleSubmit = useCallback(
    (v: HomepageFeaturedContentUpdate[]) => {
      let basePriority = 1000
      let customerPriority = 999
      const enrichedUpdates = v.map(row => {
        const priority = row.customerId ? basePriority - customerPriority : basePriority
        if (row.customerId) {
          customerPriority -= 1
        } else {
          basePriority += 1000
          customerPriority = 999
        }
        return omit<HomepageFeaturedContentUpdate>(
          {
            ...row,
            priority,
          },
          ['__typename', 'customer'],
        )
      })
      setUpdating(true)
      bulkUpdateMutation({
        variables: {
          input: {
            updates: enrichedUpdates as HomepageFeaturedContentUpdate[],
            customerId: selectedCustomerId,
            replaceAll: true,
          },
        },
      })
        .then(() => {
          setNeedUpdating(false)
          refetch()
        })
        .then(() => message.success('Updated Homepage Featured Contents'))
        .finally(() => setUpdating(false))
    },
    [bulkUpdateMutation, refetch, selectedCustomerId],
  )

  return (
    <>
      <Helmet>
        <title>Manage Homepage Featured Contents</title>
      </Helmet>

      <BaseManagePageLayout
        title="Featured Contents"
        extra={
          <Button
            loading={updating}
            onClick={() => {
              handleSubmit(updates)
            }}
            type="primary">
            Bulk Update
          </Button>
        }>
        {needUpdating ? (
          <Alert
            message="There are some changes. Please press the Bulk Update to save your work."
            type="info"
            showIcon
            style={{ marginBottom: '1em' }}
          />
        ) : null}
        <label>Select Customer: </label>
        <CustomersSelector
          style={{ width: 300 }}
          value={selectedCustomerId}
          placeholder="Global"
          onChange={v => setSelectedCustomerId(v as string)}
          labelInValue={false}
          showSearch
          searchValue={customerSearch}
          onSearch={v => setCustomerSearch(v)}
          allowClear
        />
        <WrapperDiv>
          <div className="edit-section">
            {loading ? <Skeleton active /> : null}
            {!loading && updates.length === 0 ? <Empty /> : null}
            <DragDropContext onDragEnd={handleOnDragEnd}>
              <Droppable droppableId="droppable">
                {provided => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {updates?.map((item, index) => (
                      <Draggable
                        isDragDisabled={!isNil(selectedCustomerId) && item.customerId !== selectedCustomerId}
                        key={item.id}
                        draggableId={item.id || item.title}
                        index={index}>
                        {(providedDraggable, snapshotDraggable) => (
                          <HomepageFeaturedContentItem
                            provided={providedDraggable}
                            snapshot={snapshotDraggable}
                            key={index}
                            editable={!(!isNil(selectedCustomerId) && item.customerId !== selectedCustomerId)}
                            value={item}
                            onRemove={() => {
                              setUpdates(old => old.filter((_, ui) => ui !== index))
                              setNeedUpdating(true)
                            }}
                            onEdit={() => {
                              formIns.setFieldsValue(updates[index])
                              setOpenCreate(true)
                              setCreating(false)
                            }}
                          />
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>

            <Button
              onClick={() => {
                setUpdates(old => [
                  ...old,
                  {
                    customerId: selectedCustomerId,
                  } as any,
                ])
                formIns.resetFields()
                formIns.setFieldsValue({
                  customerId: selectedCustomerId,
                })
                setOpenCreate(true)
                setCreating(true)
              }}
              type="primary">
              New Featured Content
            </Button>
            <Drawer
              extra={
                <Space>
                  <Button onClick={() => setOpenCreate(false)}>Cancel</Button>
                  <Button
                    type="primary"
                    onClick={() => {
                      formIns.submit()
                    }}>
                    {creating ? 'Add' : 'Update'}
                  </Button>
                </Space>
              }
              width={500}
              open={openCreate}
              onClose={() => {
                formIns.resetFields()
                formIns.setFieldsValue({})
                setOpenCreate(false)
              }}
              title="Create New Featured Content">
              <Form
                form={formIns}
                {...{
                  labelCol: { span: 5 },
                  wrapperCol: { span: 19 },
                }}
                onFinish={handleCreateFeaturedContent}>
                <Form.Item name={['id']} hidden>
                  <Input />
                </Form.Item>
                <Form.Item name={['title']} rules={[{ required: true }]} label="Title">
                  <Input maxLength={256} />
                </Form.Item>
                <Form.Item name={['description']} label="Description">
                  <Input maxLength={2000} />
                </Form.Item>
                <Form.Item hidden name={['priority']}>
                  <Input />
                </Form.Item>
                <Form.Item name={['url']} label="URL">
                  <Input />
                </Form.Item>
                <Form.Item name={['imageUrl']} label="Background">
                  <FormItemSingleImageUploadButton
                    listType="picture-card"
                    className="avatar-uploader"
                    fileType="image"
                    height={300}
                  />
                </Form.Item>
                <Form.Item name={['customerId']} label="Customer">
                  <CustomersSelector disabled labelInValue={false} allowClear placeholder="Global" />
                </Form.Item>
              </Form>
            </Drawer>
          </div>

          <div className="carousel-preview">
            <FeaturedContentCarouselComponent items={updates} loading={loading} />
          </div>
        </WrapperDiv>
      </BaseManagePageLayout>
    </>
  )
}

const WrapperDiv = styled.div`
  display: flex;
  .carousel-preview {
    width: 1082px;
    margin-left: 16px;
  }
  .edit-section {
    padding: 8px;
  }
`

const HomepageFeaturedContentItem: React.FC<
  React.HTMLAttributes<HTMLDivElement> & { provided: DraggableProvided; snapshot: DraggableStateSnapshot } & {
    onEdit: () => void
    onRemove: () => void
    value: HomepageFeaturedContentUpdate
    editable?: boolean
  }
> = ({ value, provided, editable, onEdit, onRemove }) => {
  return (
    <Card
      size="small"
      hoverable
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      style={{ ...provided.draggableProps.style, width: 500, marginBottom: '0.5em', marginTop: '0.5em' }}
      title={
        <div>
          {editable ? <MenuOutlined /> : ''} {value.title}
        </div>
      }
      extra={[
        <Button disabled={!editable} type="link" onClick={onEdit}>
          Edit
        </Button>,
        <Button disabled={!editable} type="link" onClick={onRemove}>
          Remove
        </Button>,
      ]}>
      <div>{!isNil(value?.customerId) ? 'Customer' : 'Global'}</div>
    </Card>
  )
}
