import { message } from 'antd'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { MeContext } from '../../api'

export const AdminGuardedRoute: React.FC<{ children?: React.ReactNode }> = props => {
  const { children: Component } = props
  const navigate = useNavigate()
  const { data: meData } = React.useContext(MeContext)

  const isNotAccessible = !!meData?.me && !meData?.me.isAdmin
  if (isNotAccessible) {
    message.warn({ key: 'admin-guard-route-warn', content: 'You do not have permission to visit this page' })
  }

  React.useEffect(() => {
    if (isNotAccessible) {
      navigate('/')
    }
  }, [isNotAccessible, navigate])

  return !isNotAccessible ? <>{Component}</> : <></>
}
