import React from 'react'
import styled from 'styled-components'
import { Space, Row, Col, Dropdown, Menu, Empty } from 'antd'
import { FolderFilled } from '@ant-design/icons'
import { ApartmentOutlined, EditOutlined, HomeFilled, MoreOutlined, PlusOutlined } from '@ant-design/icons/lib'
import { Link, useNavigate } from 'react-router-dom'
import { Customer, Organization } from '../../api/gen/models'
import { ContextMenuButton } from '../components/Buttons'
import { Card } from './Card'
import { UserAvatar } from './UserAvatar'
import { PermissionPlaceholder, usePermissionCheck } from './PermissionPlaceholder'

type OrganizationListProps = {
  customer: Customer
  organizations: Organization[]
  onAddNewOrganization: () => void
  onAddUser: (org: Organization) => void
  onEditOrganization: (org: Organization) => void
}

export const OrganizationList: React.FC<OrganizationListProps> = ({
  customer,
  organizations,
  onAddNewOrganization,
  onAddUser,
  onEditOrganization,
}) => {
  const navigate = useNavigate()

  return (
    <div>
      {organizations.length === 0 ? (
        <>
          <Row gutter={16} style={{ margin: '80px' }}>
            <Col span={8} />
            <Col span={8}>
              <Empty description="No Organizations" />
            </Col>
            <Col span={8} />
          </Row>
        </>
      ) : (
        <>
          {organizations.map(organization => (
            <OrganizationListItem
              key={organization.id}
              organization={organization}
              onClick={() => navigate(`/manage/customer/${customer?.id}/organization/${organization.id}`)}
              onAddNewOrganization={onAddNewOrganization}
              onAddUser={() => {
                onAddUser(organization)
              }}
              onEditOrganization={onEditOrganization}
            />
          ))}
        </>
      )}
    </div>
  )
}

type OrganizationListItemProps = {
  organization: Organization
  onClick?: () => any
  onAddNewOrganization: () => any
  onAddUser?: () => any
  onEditOrganization: (org: Organization) => any
}

export const OrganizationListItem: React.FC<OrganizationListItemProps> = ({
  onAddNewOrganization,
  onAddUser,
  onEditOrganization,
  organization,
  onClick,
}) => {
  const isSystem = !!organization.totalSubOrganizations

  const permission = usePermissionCheck({ organizationId: organization.id })

  const contextMenu = (
    <Menu theme="dark">
      {permission === 'curvoAdmin' && (
        <Menu.Item
          key="addOrganization"
          icon={<ApartmentOutlined />}
          onClick={e => {
            e.domEvent.stopPropagation()
            onAddNewOrganization()
          }}>
          Add Organization
        </Menu.Item>
      )}
      {onAddUser && (
        <Menu.Item
          key="addUser"
          icon={<PlusOutlined />}
          onClick={e => {
            onAddUser()
            e.domEvent.stopPropagation()
          }}>
          Add User
        </Menu.Item>
      )}

      {permission === 'curvoAdmin' && (
        <Menu.Item
          key="editOrg"
          icon={<EditOutlined />}
          onClick={e => {
            e.domEvent.stopPropagation()
            onEditOrganization(organization)
          }}>
          Edit Organization
        </Menu.Item>
      )}
    </Menu>
  )

  return (
    <StyledCardWrapper onClick={onClick}>
      <Row gutter={16} style={{ padding: '10px' }}>
        <Col span={18} style={{ display: 'flex' }}>
          <IconWrapper>{isSystem ? <FolderFilled /> : <HomeFilled />}</IconWrapper>
          <OrganizationDetailWrapper>
            <ParentsBreadCrumb>
              <div>
                {[
                  ...(organization.allParents ? organization.allParents.map(parent => parent.name) : []),
                  organization.name,
                ].join(' / ')}
              </div>
              {isSystem ? (
                <NumberOfSubOrganizationTag>{`${organization.totalSubOrganizations} Sub Organizations`}</NumberOfSubOrganizationTag>
              ) : (
                ''
              )}
            </ParentsBreadCrumb>
            <TypeWrapper>{organization.organizationType?.name}</TypeWrapper>
          </OrganizationDetailWrapper>
        </Col>
        <Col span={4}>
          {organization.topPowerUsers && organization.topPowerUsers.length ? (
            <>
              <AdminListTitle>admins</AdminListTitle>
              <Space size={4}>
                {organization.topPowerUsers &&
                  organization.topPowerUsers.slice(0, 3).map(powerUser => (
                    <Link
                      to={`/manage/users/${powerUser.id}`}
                      onClick={e => {
                        e.stopPropagation()
                      }}>
                      <UserAvatar user={powerUser} size={32} />
                    </Link>
                  ))}
                {organization.topPowerUsers.length > 3 ? `+${organization.topPowerUsers.length - 3}` : ''}
              </Space>
            </>
          ) : (
            ''
          )}
        </Col>
        <Col span={2}>
          <PermissionPlaceholder permissionTarget="write" organizationId={organization.id}>
            <MoreButtonWrapper>
              <Dropdown key={1} overlay={contextMenu} trigger={['click']}>
                <ContextMenuButton
                  shape="circle"
                  onClick={e => {
                    e.stopPropagation()
                  }}>
                  <MoreOutlined />
                </ContextMenuButton>
              </Dropdown>
            </MoreButtonWrapper>
          </PermissionPlaceholder>
        </Col>
      </Row>
    </StyledCardWrapper>
  )
}

const StyledCardWrapper = styled(Card)`
  margin-bottom: 1em;
  height: 92px;
`

const IconWrapper = styled.div`
  border-radius: 20px;
  width: 40px;
  height: 40px;
  background: #edeeef;
  text-align: center;
  margin-right: 16px;

  > span {
    line-height: 45px;
    font-size: 24px;
  }
`

const OrganizationDetailWrapper = styled.div`
  display: block;
  width: 100%;
`

const ParentsBreadCrumb = styled.div`
  font-size: 16px;
  color: #3b7cff;
  font-weight: 100;
  line-height: 20px;
  height: 20px;
  display: flex;
  width: 100%;
`

const TypeWrapper = styled.div`
  height: 20px;
  color: #172237;
  font-size: 14px;
  font-weight: 100;
  line-height: 20px;
`

const AdminListTitle = styled.div`
  opacity: 0.5;
  height: 14px;
  color: #172237;
  font-size: 10px;
  font-weight: 500;
  line-height: 14px;
  text-transform: uppercase;
  width: 100%;
`

const NumberOfSubOrganizationTag = styled.div`
  margin-left: 12px;
  height: 20px;
  color: #172237;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  padding: 0 8px;

  border-radius: 12px;
  background: #edeeef;
`

const MoreButtonWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  height: 100%;
`
