import {
  AllModules,
  IAggFuncParams,
  RowNode,
  ColumnApi,
  ColumnState,
  GridApi,
  ValueFormatterParams,
} from '@ag-grid-enterprise/all-modules'
import { AgGridColumnProps, AgGridReact } from '@ag-grid-community/react'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-balham.css'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import numbro from 'numbro'
import { Helmet } from 'react-helmet'
import { PageHeader } from 'antd'
import { currencyFormatter, formatCurrency } from '../../utils'
import { SpendDataForVendorsResult, useGetSpendDataForVendors } from '../../api'

const percentileAggregator = (params: IAggFuncParams) => {
  const agg = (nodes: RowNode[]): number => {
    return nodes.reduce((acc, row) => {
      if (row.childrenAfterFilter) {
        return acc + agg(row.childrenAfterFilter)
      }

      return acc + row.data.qtypurchase * row.data[params.colDef.field!]
    }, 0)
  }

  if (!params.colDef.field) {
    return 0
  }

  if (!params.rowNode.childrenAfterFilter) {
    return 0
  }

  return agg(params.rowNode.childrenAfterFilter)
}

const aggCurrencyFormatter = (params: ValueFormatterParams) => {
  if (params.node?.aggData) {
    return formatCurrency(params.value, 0)
  } else {
    return currencyFormatter(params)
  }
}

// aka "Basket Editor"
export const SpendForVendors: React.FC<{ dataset?: string }> = ({ dataset }) => {
  const [columnStates, setColumnStates] = useState<ColumnState[]>([])

  const spendData = useGetSpendDataForVendors({ variables: { dataset } })

  const gridData: SpendDataForVendorsResult[] = useMemo(() => {
    return spendData.data?.getSpendDataForVendors || []
  }, [spendData.data?.getSpendDataForVendors])

  const gridRef = useRef<AgGridReact & { api: GridApi; columnApi: ColumnApi }>(null)

  useEffect(() => {
    spendData.loading ? gridRef.current?.api?.showLoadingOverlay() : gridRef.current?.api?.hideOverlay()
  }, [spendData.loading, gridRef.current?.api])

  useEffect(() => {
    // sync columnStates and ag-grid columns
    if (gridRef.current && columnStates.length) {
      const columnApi = gridRef.current.columnApi
      // pin
      columnApi.setColumnsPinned(
        columnStates.filter(colState => colState.pinned).map(colState => colState.colId!),
        'left',
      )
      columnApi.setColumnsPinned(
        columnStates.filter(colState => !colState.pinned).map(colState => colState.colId!),
        '',
      )
      // show/hide
      columnApi.setColumnsVisible(
        columnStates.filter(colState => colState.hide).map(colState => colState.colId!),
        false,
      )
      columnApi.setColumnsVisible(
        [...columnStates.filter(colState => !colState.hide).map(colState => colState.colId!), 'actions'],
        true,
      )
    }
  }, [gridRef, columnStates])

  const gridColumns = useMemo(() => genGridColumns(dataset), [dataset])

  return (
    <>
      <Helmet>
        <title>Vendor Spend</title>
      </Helmet>
      <StyledLayout>
        <PageHeader title={'Spend'} />
        <Wrapper>
          <GridWrapper className="ag-theme-balham">
            <AgGridReact
              ref={gridRef}
              rowSelection="multiple"
              rowMultiSelectWithClick={true}
              rowData={gridData}
              defaultColDef={{ filter: true, resizable: true, sortable: true }}
              columnDefs={gridColumns}
              modules={AllModules}
              enableCharts={true}
              onGridColumnsChanged={s => {
                setColumnStates(s.columnApi.getColumnState())
              }}
              sideBar={true}
              onSelectionChanged={() => {}}
              aggFuncs={{ percSum: percentileAggregator }}
            />
          </GridWrapper>
        </Wrapper>
      </StyledLayout>
    </>
  )
}

const genGridColumns = (dataset?: string): AgGridColumnProps[] => [
  {
    colId: 'customer_name',
    field: 'customer_name',
    headerName: 'Organization',
    enableRowGroup: true,
  },
  {
    colId: 'facility_name',
    field: 'facility_name',
    headerName: 'Facility Name',
    enableRowGroup: true,
  },
  {
    colId: 'curvo_category',
    field: 'curvo_category',
    headerName: 'Category',
    enableRowGroup: true,
  },
  {
    colId: 'manufacturer',
    field: 'manufacturer',
    headerName: 'Manufacturer',
    enableRowGroup: true,
  },
  {
    colId: 'manufacturer_item',
    field: 'manufacturer_item',
    headerName: 'Manufacturer Item',
  },
  {
    colId: 'stan_gic_name',
    field: 'stan_gic_name',
    headerName: 'GIC',
    enableRowGroup: true,
  },
  {
    colId: 'stan_type_one',
    field: 'stan_type_one',
    headerName: 'Type One',
    enableRowGroup: true,
  },
  {
    colId: 'stan_type_two',
    field: 'stan_type_two',
    headerName: 'Type Two',
    enableRowGroup: true,
  },
  {
    colId: 'stan_product_line',
    field: 'stan_product_line',
    headerName: 'Product Line',
    enableRowGroup: true,
  },
  {
    colId: 'stan_brand',
    field: 'stan_brand',
    headerName: 'Brand',
    enableRowGroup: true,
  },
  {
    colId: 'gudid_gmdn_name',
    field: 'gudid_gmdn_name',
    headerName: 'GMDN',
    enableRowGroup: true,
  },
  {
    colId: 'gudid_brand_name',
    field: 'gudid_brand_name',
    headerName: 'GUDID Brand',
    enableRowGroup: true,
  },
  {
    colId: 'uom',
    field: 'uom',
    headerName: 'UoM',
  },
  {
    colId: 'uom_conversion',
    field: 'uom_conversion',
    headerName: 'UoM Conversion',
  },
  {
    colId: 'qtypurchase',
    field: 'qtypurchase',
    headerName: 'Total Quantity',
    valueFormatter: params => {
      return numbro(params.value).format({ thousandSeparated: true })
    },
    type: 'rightAligned',
    filter: 'agNumberColumnFilter',
    aggFunc: 'sum',
  },
  ...(dataset === 'cases'
    ? [
        {
          colId: 'case_id',
          field: 'case_id',
          headerName: 'Case ID',
          enableRowGroup: true,
        },
        {
          colId: 'surgeon',
          field: 'surgeon',
          headerName: 'Surgeon',
          enableRowGroup: true,
        },
        {
          colId: 'construct_name',
          field: 'construct_name',
          headerName: 'Construct Name',
          enableRowGroup: true,
        },
        {
          colId: 'construct_group',
          field: 'construct_group',
          headerName: 'Construct Group',
          enableRowGroup: true,
        },
        {
          colId: 'procedure_type',
          field: 'procedure_type',
          headerName: 'Procedure Type',
          enableRowGroup: true,
        },
      ]
    : []),
  {
    colId: 'iqo25',
    field: 'iqo25',
    headerName: '25th',
    valueFormatter: aggCurrencyFormatter,
    type: 'rightAligned',
    filter: 'agNumberColumnFilter',
    aggFunc: 'percSum',
  },
  {
    colId: 'iqo50',
    field: 'iqo50',
    headerName: '50th',
    valueFormatter: aggCurrencyFormatter,
    type: 'rightAligned',
    filter: 'agNumberColumnFilter',
    aggFunc: 'percSum',
  },
  {
    colId: 'iqo75',
    field: 'iqo75',
    headerName: '75th',
    valueFormatter: aggCurrencyFormatter,
    type: 'rightAligned',
    filter: 'agNumberColumnFilter',
    aggFunc: 'percSum',
  },
  {
    colId: 'iqo90',
    field: 'iqo90',
    headerName: '90th',
    valueFormatter: aggCurrencyFormatter,
    type: 'rightAligned',
    filter: 'agNumberColumnFilter',
    aggFunc: 'percSum',
  },
]

const GridWrapper = styled.div`
  flex: 1;

  .ag-header {
    background: ${props => props.theme.ColorNeutral30};
  }

  .ag-row-selected {
    background: ${props => props.theme.ColorBlue20};
  }

  .ag-row-hover {
    background: ${props => props.theme.ColorBlue10};
  }
`

const StyledLayout = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`

const Wrapper = styled.div`
  padding: 0 ${props => props.theme.SpaceL} ${props => props.theme.SpaceL} ${props => props.theme.SpaceL};
  flex: 1;
  display: flex;
  flex-direction: column;
`
export default SpendForVendors
