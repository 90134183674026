import { Layout } from 'antd'
import React from 'react'
import { Routes, Route } from 'react-router-dom'
import styled from 'styled-components'
import { AdminGuardedRoute } from '../components/AdminGuardedRoute'
import { SpendForVendors } from './SpendForVendors'

const { Content } = Layout

export const CurvoConnectPage: React.FC = () => {
  return (
    <ManagePageContainer>
      <Layout>
        <ManagePageContentContainer>
          <Routes>
            <Route
              path="/spend"
              element={
                <AdminGuardedRoute>
                  <SpendForVendors />
                </AdminGuardedRoute>
              }
            />
            <Route
              path="/cases"
              element={
                <AdminGuardedRoute>
                  <SpendForVendors dataset="cases" />
                </AdminGuardedRoute>
              }
            />
            <Route
              path="/enloe-zb"
              element={
                <AdminGuardedRoute>
                  <SpendForVendors dataset="enloe-zb" />
                </AdminGuardedRoute>
              }
            />
            <Route
              path="/trinity-zb"
              element={
                <AdminGuardedRoute>
                  <SpendForVendors dataset="trinity-zb" />
                </AdminGuardedRoute>
              }
            />
          </Routes>
        </ManagePageContentContainer>
      </Layout>
    </ManagePageContainer>
  )
}

const ManagePageContainer = styled(Layout)`
  display: flex;
  flex-direction: row;
  height: 100%;
`

const ManagePageContentContainer = styled(Content)``
