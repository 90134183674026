import React, { ReactNode, useState } from 'react'
import { Button } from 'antd'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import styled, { css } from 'styled-components'
import { ColorNeutral40 } from '../../ui/design-tokens/tokens'

type Props = {
  title: string
  backLink?: ReactNode
  children?: ReactNode
}

export const DraggableSidebar: React.FC<Props> = ({ title, children, backLink }) => {
  const [currentWidth, setCurrentWidth] = useState(264)
  const [collapsed, setCollapsed] = useState(false)

  return (
    <SidebarWrapper width={currentWidth}>
      {collapsed ? (
        ''
      ) : (
        <SidebarContent>
          {backLink ? <BackLinkWrapper>{backLink}</BackLinkWrapper> : ''}
          <SidebarHeader>{title}</SidebarHeader>
          {children}
        </SidebarContent>
      )}

      <DraggableDivWrapper>
        <CollapseButton
          sidebarCollapsed={collapsed}
          type="default"
          shape="circle"
          icon={collapsed ? <RightOutlined /> : <LeftOutlined />}
          onClick={() => {
            setCollapsed(!collapsed)
            setCurrentWidth(!collapsed ? 16 : 264)
          }}
        />
      </DraggableDivWrapper>
    </SidebarWrapper>
  )
}

const BackLinkWrapper = styled.div`
  font-size: 12px;
  margin-bottom: 24px;
`

const SidebarWrapper = styled.div<{ width: number }>`
  background: #fff;
  --scroll-bar: 0;
  scrollbar-width: thin;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  width: ${props => props.width}px;
  display: flex;
  flex-direction: row;
  transition: width 300ms cubic-bezier(0.2, 0, 0, 1) 0s;
  z-index: 4;
  border-right: 1px solid ${ColorNeutral40};
`

const SidebarContent = styled.div`
  padding: 24px;
  width: 100%;
  background: #fff;
  display: flex;
  flex-direction: column;
`

const DraggableDivWrapper = styled.div`
  width: 0;
  height: 100%;
  z-index: 5;
  cursor: ew-resize;
`

const CollapseButton = styled(Button)<{ sidebarCollapsed?: boolean }>`
  visibility: ${props => (!props.sidebarCollapsed ? 'hidden' : 'visible')};
  position: absolute !important;
  top: 100px;
  right: -31;
  transform: translate(-50%)
    ${props =>
      props.sidebarCollapsed
        ? css`
            transform: translate(-16px);
          `
        : ''};
  z-index: 5;
  opacity: 0;
  transition: 150ms;

  ${SidebarWrapper}:hover & {
    visibility: visible;
    opacity: 1;
  }
`

const SidebarHeader = styled.span`
  font-weight: bold;
`
