import { DocumentNode, useSubscription } from '@apollo/client'
import { message } from 'antd'
import React, { FC, createContext, useEffect, useMemo, useRef, useState } from 'react'
import { useLocation } from 'react-router'
import { SUBSCRIBE_EXPORTED_SEARCH, SUBSCRIBE_UPLOAD_SEARCH } from '..'

export type JobType = {
  id: string | undefined
  type: string | undefined
}

export enum JobTypesEnum {
  Export = 'Export',
  UploadSearch = 'UploadSearch',
}

type Props = {
  children?: React.ReactNode
}

type BackgroundJobContextProps = {
  setJob: (job: JobType) => void
}

const BackgroundJobContext = createContext<BackgroundJobContextProps | string>('')

export const BackgroundJobProvider: FC<Props> = ({ children }) => {
  const downloadRef = useRef<any>(null)
  const [job, setJob] = useState<JobType>({ id: undefined, type: JobTypesEnum.UploadSearch })
  const currentUrl = useLocation().pathname
  const subscriptionQuery = useMemo(() => {
    if (job.type === JobTypesEnum.Export) {
      return SUBSCRIBE_EXPORTED_SEARCH
    }
    if (job.type === JobTypesEnum.UploadSearch) {
      return SUBSCRIBE_UPLOAD_SEARCH
    }
    return
  }, [job.type])

  const { data: subscriptionRes } = useSubscription(subscriptionQuery as DocumentNode, {
    variables: {
      id: job.id,
    },
    skip: !job.id,
  })

  useEffect(() => {
    if (subscriptionRes && job.id) {
      switch (job.type) {
        case JobTypesEnum.Export:
          message.success({
            content: (
              <a
                ref={downloadRef}
                href={subscriptionRes.exportedSearch.downloadUrl}
                onClick={e => {
                  e.stopPropagation()
                  message.destroy('download-export')
                }}
                download>
                Exported data successfully! The file will be downloaded automatically. If not click here to download
                exported file!
              </a>
            ),
            key: 'download-export',
            onClick: () => {
              downloadRef.current.click()
            },
            duration: 5,
          })

          const tempLink = document.createElement('a')
          tempLink.href = subscriptionRes.exportedSearch.downloadUrl
          tempLink.setAttribute('download', '')
          tempLink.click()

          break
        case JobTypesEnum.UploadSearch:
          !currentUrl.includes('/analytics/spending') &&
            message.success({
              content: 'Searched by file successfully! Click the link on notification center to see the result!',
              key: 'upload-search',
              duration: 5,
            })
          break
      }
      setJob({ id: undefined, type: JobTypesEnum.UploadSearch })
    }
  }, [currentUrl, job.id, job.type, subscriptionRes])

  const value = { setJob }
  return <BackgroundJobContext.Provider {...{ value }}>{children}</BackgroundJobContext.Provider>
}

export function useExport() {
  const context = React.useContext(BackgroundJobContext)
  if (typeof context === 'string') {
    throw new Error(context)
  }
  return context
}
