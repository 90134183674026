import { HomeTwoTone } from '@ant-design/icons'
import { Button, Tag } from 'antd'
import React from 'react'
import styled from 'styled-components'
import { Organization } from '../../../api'

type OrganizationListProps = {
  organization: Organization
  isPowerUser: boolean
}

export const UserOrganizationListItem: React.FC<OrganizationListProps> = ({ organization, isPowerUser }) => {
  return (
    <InformationData>
      <Iconbutton type="default" shape="circle" icon={<HomeTwoTone />} />
      <a href="#">{organization.name}</a>
      <Tagbutton color={isPowerUser ? 'volcano' : '#BEBEBE'}>{isPowerUser ? 'Admin' : 'User'}</Tagbutton>
    </InformationData>
  )
}

const Iconbutton = styled(Button)`
  margin: 8px;
`

const Tagbutton = styled(Tag)`
  float: right;
  margin: 8px;
  width: 50px;
  text-align: center;
`

const InformationData = styled.div`
  font-size: 14px;
  font-weight: 600;
`
