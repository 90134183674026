import { DeleteOutlined, EnterOutlined, PlusOutlined } from '@ant-design/icons'
import { Avatar, Button, Col, Popconfirm, Radio, Row, Select, SelectProps, Space, Tag } from 'antd'
import { LabeledValue } from 'antd/lib/tree-select'
import moment from 'moment'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { useQueryUsers, User, UserOrganization } from '../../api'
import { UserAvatar } from './UserAvatar'
import { UserManagementModal } from './UserManagementModal'

type UserComponentProps = {
  user: User
}

export const UserWithAvatar: React.FC<UserComponentProps> = ({ user }) => (
  <UserWithAvatarWrapper>
    <Link to={`/manage/users/${user.id}`}>
      <UserAvatar user={user} size={32} />
    </Link>
    <div>
      <UserWithAvatarUsername to={`/manage/users/${user.id}`}>
        {user.firstName} {user.lastName}
      </UserWithAvatarUsername>
      <UserWithAvatarLastVisited>
        {user.lastActionAt
          ? `Last Visited ${user.lastActionAt && moment(user.lastActionAt).calendar()}`
          : 'No Visit Yet'}
      </UserWithAvatarLastVisited>
    </div>
  </UserWithAvatarWrapper>
)

export const UserWithRole: React.FC<UserComponentProps & { width?: string }> = ({ user, width = '100%' }) => (
  <UserWithRoleWrapper width={width}>
    <UserWithAvatar user={user} />
    <TagWrapper>
      <Tag color="orange">{user.isCurvoAdmin ? 'Admin' : 'User'}</Tag>
    </TagWrapper>
  </UserWithRoleWrapper>
)

type OrganizationUserItemListProps = UserComponentProps & {
  width?: string
  onDeleteButtonClicked: () => any
  onRoleChanged: (isAdmin: boolean) => any
  isAdmin?: boolean
  disabled?: boolean
}

export const OrganizationUserItemList: React.FC<OrganizationUserItemListProps> = ({
  user,
  isAdmin,
  onRoleChanged,
  onDeleteButtonClicked,
  disabled,
}) => (
  <Row>
    <Col span={14}>
      <UserWithAvatar user={user} />
    </Col>
    <Col span={8}>
      <OrganizationUserRoleRadioGroup
        name={`${user.id} role`}
        value={isAdmin ? 1 : 0}
        disabled={disabled}
        onChange={v => {
          onRoleChanged(!!v.target.value)
        }}>
        <RoleRadio value={1}>Admin</RoleRadio>
        <RoleRadio value={0}>General User</RoleRadio>
      </OrganizationUserRoleRadioGroup>
    </Col>
    <Col span={2}>
      <OrganizationUserBtnDeleteWrapper>
        <Popconfirm title="Do you want to remove this user?" onConfirm={onDeleteButtonClicked}>
          <RemoveUserButton shape="circle">
            <DeleteOutlined />
          </RemoveUserButton>
        </Popconfirm>
      </OrganizationUserBtnDeleteWrapper>
    </Col>
  </Row>
)

export const UserSearchInput: React.FC<{
  customerId?: string
  assignedUsers?: UserOrganization[]
  onSelect: (user: User) => any
}> = ({ customerId, assignedUsers, onSelect }) => {
  function onSearch(value: string) {
    setSearchText(value)
  }
  // Option has any type because there is no Option type available. Refer to ant design docs
  function handleSelect(_value: string, option: any) {
    const user = users.find(element => element.id === option.key)
    if (user === undefined) {
      return
    }
    onSelect(user)
  }

  const [userManagementModalVisible, setUserManagementModalVisible] = useState(false)
  const [userManagementModalEdit, setUserManagementModalEdit] = useState<User>()
  const [searchText, setSearchText] = useState('')
  const { data, loading } = useQueryUsers({ variables: { input: { customerId } } })
  const users = ((data && data.users.hits) || []).filter(
    user => !assignedUsers || !assignedUsers.find(assignedUser => assignedUser.usersId === user.id),
  )
  return (
    <>
      <Select
        showSearch
        loading={loading}
        style={{ width: '100%' }}
        onSelect={handleSelect}
        onSearch={onSearch}
        optionLabelProp="label"
        value={[]}
        filterOption={(input, opt) => {
          const lcInput = input.toLocaleLowerCase()
          const user: User = opt?.children.props.user
          return (
            user.email.indexOf(lcInput) >= 0 ||
            user.firstName.toLocaleLowerCase().indexOf(lcInput) >= 0 ||
            user.lastName.toLocaleLowerCase().indexOf(lcInput) >= 0
          )
        }}
        notFoundContent={
          <Button
            type="link"
            onClick={() => {
              setUserManagementModalVisible(true)
              setUserManagementModalEdit(undefined)
            }}>
            <PlusOutlined /> Invite New User {searchText}
          </Button>
        }>
        {users.map(user => (
          <Select.Option
            key={user.id}
            value={user.firstName + user.lastName + user.email}
            title={`${user.firstName} ${user.lastName} (${user.email})`}
            label={`${user.firstName} ${user.lastName} (${user.email})`}>
            <UserSelectOption user={user} onSelect={onSelect} />
          </Select.Option>
        ))}
      </Select>
      <UserManagementModal
        visible={userManagementModalVisible}
        onCancel={() => setUserManagementModalVisible(false)}
        user={userManagementModalEdit}
        onFinished={() => {
          setUserManagementModalVisible(false)
        }}
      />
    </>
  )
}

export const UserSelect: React.FC<
  {
    customerId?: string
    onFullSelect?: (user: User) => any
  } & SelectProps<LabeledValue>
> = ({ customerId, onFullSelect, ...props }) => {
  // Option has any type because there is no Option type available. Refer to ant design docs
  function handleSelect(_value: LabeledValue, option: any) {
    const user = users.find(element => element.id === option.key)
    if (user === undefined) {
      return
    }
    if (onFullSelect) {
      onFullSelect(user)
    }
  }

  const { data, loading } = useQueryUsers({ variables: { input: { customerId } } })
  const users = (data && data.users.hits) || []
  return (
    <>
      <Select showSearch loading={loading} onSelect={handleSelect} optionLabelProp="label" {...props}>
        {users.map(user => (
          <Select.Option
            key={user.id}
            value={user.id}
            title={`${user.firstName} ${user.lastName} (${user.email})`}
            label={`${user.firstName} ${user.lastName} (${user.email})`}>
            {`${user.firstName} ${user.lastName} (${user.email})`}
          </Select.Option>
        ))}
      </Select>
    </>
  )
}

const UserSelectOption: React.FC<{ user: User; onSelect: (user: User) => any }> = ({ user, onSelect }) => (
  <UserSelectOptionWrapper
    onClick={e => {
      e.stopPropagation()
      onSelect(user)
    }}>
    <UserSelectOptionAvatarWrapper>
      <Avatar />
    </UserSelectOptionAvatarWrapper>
    <UserSelectOptionInfoWrapper>
      <UserSelectOptionNameSpan>{`${user.firstName} ${user.lastName}`}</UserSelectOptionNameSpan>
      <span>{user.email}</span>
    </UserSelectOptionInfoWrapper>
    <UserSelectOptionSelectWrapper>
      <Button>
        Assign <EnterOutlined />
      </Button>
    </UserSelectOptionSelectWrapper>
  </UserSelectOptionWrapper>
)

const UserWithAvatarWrapper = styled(Space)``

const UserWithAvatarUsername = styled(Link)`
  width: 100%;
  color: #172237;
  font-weight: bold;
`

const UserWithAvatarLastVisited = styled.div`
  font-size: 12px;
  font-weight: 300;
`

const UserWithRoleWrapper = styled.div<{ width: string }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: ${props => props.width};
`

const TagWrapper = styled.div`
  margin-top: auto;
  margin-bottom: auto;
`

const UserSelectOptionWrapper = styled.div`
  height: 52px;
  padding: 8px;
  background: #fff;
  :hover {
    background: #7c6f64;
  }
  display: flex;
  flex-direction: row;
  cursor: default;
`

const UserSelectOptionInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

const UserSelectOptionAvatarWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 52px;
`

const UserSelectOptionSelectWrapper = styled.div`
  display: flex;
  align-items: center;
  justify: center;
`

const UserSelectOptionNameSpan = styled.span`
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
`

const OrganizationUserBtnDeleteWrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
`

const OrganizationUserRoleRadioGroup = styled(Radio.Group)`
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: center;
`

const RemoveUserButton = styled(Button)`
  min-width: 24px;
  width: 24px;
  height: 24px;
  font-size: 10px;
`

const RoleRadio = styled(Radio)`
  font-size: 10px;
`
