import React from 'react'
import { useParams, Link } from 'react-router-dom'
import { Row, Col, Tag, Button } from 'antd'
import { Helmet } from 'react-helmet'
import { useQueryUser } from '../../api/hooks/users'
import { UserInfoCard } from '../components/UserInfoCard'
import { UserOrganizationsCard } from '../components/UserOrganizationsCard'
import { BaseManagePageLayout } from '../Manage/BaseManagePageLayout'

export const UserProfileView: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const { data } = useQueryUser({ variables: { id: id! } })
  const user = data && data.user

  return user ? (
    <>
      <Helmet>
        <title>{`${user.firstName} ${user.lastName}`}</title>
      </Helmet>
      <BaseManagePageLayout
        title={`${user.firstName} ${user.lastName}`}
        tags={<Tag color="#108ee9">{user.status}</Tag>}
        extra={[
          <Button key="update-profile" danger>
            <Link to={`/users/${id}/edit`}>Update Profile</Link>
          </Button>,
        ]}>
        <Row gutter={[16, 16]}>
          <Col span={16}>
            <UserInfoCard user={user} />
          </Col>
          <Col span={8}>
            <UserOrganizationsCard user={user} />
          </Col>
        </Row>
      </BaseManagePageLayout>
    </>
  ) : (
    <></>
  )
}
