import React from 'react'
import { Select } from 'antd'
import { SelectProps } from 'antd/lib/select'
import { LabeledValue } from 'antd/lib/tree-select'
import { useQueryCustomers } from '../../../api'

const { Option } = Select

export const CustomersSelector: React.FC<SelectProps<LabeledValue | LabeledValue[] | string>> = ({
  searchValue,
  ...props
}) => {
  const { data, loading } = useQueryCustomers({ variables: { input: { searchText: searchValue } } })
  const customers = (data && data.customers) || []
  return (
    <Select filterOption={false} labelInValue {...props} loading={loading}>
      {customers.map(customer => (
        <Option title={customer.name} key={customer.id} value={customer.id}>
          {customer.name}
        </Option>
      ))}
    </Select>
  )
}
