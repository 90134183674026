import React from 'react'
import { Tag } from 'antd'
import { User, UserStatus } from '../../api'

type Props = {
  user: User // TODO Replace with user entity type
}

export const UserStatusTag: React.FC<Props> = ({ user }) => {
  const tagColor =
    user.status === UserStatus.Pending ? '#fff5f1' : user.status === UserStatus.Active ? '#3b7cff' : '#edeeef'
  const textColor =
    user.status === UserStatus.Pending ? '#ff7900' : user.status === UserStatus.Active ? '#fff' : '#172237'

  return (
    <Tag color={tagColor} style={{ color: textColor }}>
      {user.status.toLocaleLowerCase()}
    </Tag>
  )
}
