import { CloudUploadOutlined, InboxOutlined, UploadOutlined } from '@ant-design/icons'
import { Col, Row, Image, Button } from 'antd'
import Upload, { DraggerProps, RcFile, UploadFile, UploadProps } from 'antd/lib/upload'
import { UploadRequestOption } from 'rc-upload/lib/interface'
import axios from 'axios'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
  Mutation,
  UploadSearchResult,
  useGenS3DocumentUploadUrlMutation,
  useGenS3UploadImageUrlMutation,
} from '../../api'
import { DOCUMENT_MAX_SIZE } from '../constants'

const { Dragger } = Upload

export enum UploadStatusesEnum {
  Uploading = 'uploading',
  Pending = 'pending',
  Done = 'done',
  Error = 'error',
}

type ExtendedRcFile = RcFile & { s3Key: string; url: string; fileName: string }

type Props = {
  uploadStatus: UploadStatusesEnum
  onSetUploadStatus: (uploadStatus: UploadStatusesEnum) => void
  onUploadSuccess?: (fileInfo: ExtendedRcFile, uploadResult: UploadSearchResult) => void
  fileType: 'image' | 'document'
  sizeLimit?: number // unit: MB
  customText?: string
} & DraggerProps

/**
 * Upload a file to a temporary path in s3
 * Return s3 path as a param in `onUploadSuccess` callback
 */
export const S3UploadArea: React.FC<Props> = props => {
  const customRequest = useS3UploadCustomRequest(props)

  return <UploadAreaBase customRequest={customRequest} {...props} />
}

export const FormItemSingleImageUploadButton: React.FC<
  {
    onUploadSuccess?: (fileInfo: ExtendedRcFile, uploadResult: UploadSearchResult) => void
    fileType: 'image' | 'document'
    sizeLimit?: number // unit: MB
    customText?: string
    value?: string
    onChange?: (v?: string) => void
  } & DraggerProps
> = ({ value, onChange, customText }) => {
  const uploadBtnRef = useRef(null)
  const [fileList, setFileList] = useState<UploadFile<{ invitingVendorIds?: string[]; s3Key?: string }>[]>([])
  const [previewUrl, setPreviewUrl] = useState<string>()

  const [isUploading, setIsUploading] = useState(false)
  useEffect(
    () =>
      setFileList(old => {
        const originFileObj = old.find(o => o.response?.s3Key === value)?.originFileObj
        if (originFileObj) {
          getBase64(originFileObj).then(url => setPreviewUrl(url))
        }
        setPreviewUrl(value)
        return value
          ? [
              {
                uid: value,
                name: value,
                fileName: value,
                url: value,
                originFileObj,
              },
            ]
          : []
      }),
    [value],
  )

  return (
    <div>
      {previewUrl ? <Image src={previewUrl} width={300} /> : null}
      <S3UploadButton
        uploadRef={uploadBtnRef}
        sizeLimit={DOCUMENT_MAX_SIZE}
        fileType="image"
        maxCount={1}
        fileList={fileList}
        onChange={e => {
          setFileList(e.fileList)
          if (e.file.status === 'uploading') {
            setIsUploading(true)
            setFileList(e.fileList)
          } else if (e.file.status === 'error') {
            setFileList(e.fileList)
            setIsUploading(false)
          } else if (e.file.status === 'done') {
            setFileList(e.fileList)
            onChange && onChange(e.file.response?.s3Key)
            setIsUploading(false)
          } else if (e.file.status === 'removed') {
            onChange && onChange(undefined)
            setIsUploading(false)
          }
        }}
        showUploadList>
        <Button className="table-control" loading={isUploading}>
          <CloudUploadOutlined />
          {customText || 'Upload File'}
        </Button>
      </S3UploadButton>
    </div>
  )
}

const useS3UploadCustomRequest = ({
  onUploadSuccess,
  fileType,
  sizeLimit,
}: Pick<Props, 'onUploadSuccess' | 'fileType' | 'sizeLimit'>) => {
  const [genS3UploadUrlMutation] = useGenS3DocumentUploadUrlMutation()
  const [genS3UploadImageUrlMutation] = useGenS3UploadImageUrlMutation()

  const customRequest: UploadProps['customRequest'] = useCallback(
    async ({ file, onSuccess, onError, onProgress }: UploadRequestOption<any>) => {
      try {
        const rcFile = file as RcFile
        if (sizeLimit && rcFile.size > sizeLimit * 1024 * 1024) {
          throw new Error(`Invalid file size, please upload file less than ${sizeLimit}MB`)
        }
        const uploadUrlResult =
          fileType === 'document'
            ? await genS3UploadUrlMutation({ variables: { input: { fileName: rcFile.name, fileSize: rcFile.size } } })
            : await genS3UploadImageUrlMutation({
                variables: { input: { fileName: rcFile.name, fileSize: rcFile.size } },
              })
        if (uploadUrlResult.errors || !uploadUrlResult.data) {
          onError && onError((uploadUrlResult.errors || [])[0])
        }
        const uploadUrl =
          fileType === 'document'
            ? (uploadUrlResult.data! as Pick<Mutation, 'generateDocumentUploadUrl'>).generateDocumentUploadUrl
            : (uploadUrlResult.data! as Pick<Mutation, 'generateImageUploadUrl'>).generateImageUploadUrl
        const form = new FormData()
        const { hiddenInputs } = uploadUrl
        if (hiddenInputs) {
          Object.keys(hiddenInputs).forEach(key => form.set(key, hiddenInputs[key]))
        }
        form.set('file', rcFile)
        await axios.post(uploadUrl.uploadUrl, form, {
          onUploadProgress: progress => {
            const percent = Math.round((progress.loaded * 100) / (progress.total || 1))
            onProgress && onProgress({ percent })
          },
        })

        const updatedFile = {
          ...rcFile,
          uid: uploadUrl.s3Key,
          s3Key: uploadUrl.s3Key,
          url: uploadUrl.s3Key,
          name: rcFile.name,
          fileName: rcFile.name,
        }
        onSuccess && onSuccess(updatedFile, rcFile as unknown as any)
        onUploadSuccess && onUploadSuccess(updatedFile as any, rcFile as unknown as any)
      } catch (e) {
        console.info(e)
        onError && onError(new Error('Cannot upload'))
      }
    },
    [genS3UploadUrlMutation, onUploadSuccess, genS3UploadImageUrlMutation, fileType, sizeLimit],
  )

  return customRequest
}

export const S3UploadButton: React.FC<
  UploadProps & Pick<Props, 'onUploadSuccess' | 'fileType' | 'sizeLimit'> & { uploadRef?: React.MutableRefObject<any> }
> = ({ children, uploadRef, ...props }) => {
  const customRequest = useS3UploadCustomRequest(props)

  return (
    <Upload
      {...props}
      ref={r => {
        if (uploadRef) {
          uploadRef.current = r
        }
      }}
      accept={(props.fileType === 'document'
        ? ['doc', 'docx', 'pdf', 'csv', 'xlsx', 'xls', 'ppt', 'pptx']
        : ['jpg', 'jpeg', 'png']
      )
        .map(ext => '.' + ext)
        .join(',')}
      customRequest={customRequest}>
      {children || (
        <Button>
          <UploadOutlined /> Update Picture
        </Button>
      )}
    </Upload>
  )
}

export const UploadAreaBase: React.FC<{ customRequest: (props: any) => any } & Props> = ({
  customRequest,
  onSetUploadStatus,
  uploadStatus,
  customText,
  ...props
}) => {
  const draggerProps: DraggerProps = {
    name: 'file',
    multiple: false,
    onChange(info: any) {
      const { status } = info.file
      if (status === UploadStatusesEnum.Done) {
        onSetUploadStatus(UploadStatusesEnum.Done)
      } else if (status === UploadStatusesEnum.Error) {
        onSetUploadStatus(UploadStatusesEnum.Error)
      }
    },
    beforeUpload() {
      onSetUploadStatus(UploadStatusesEnum.Uploading)
      return true
    },
    className: uploadStatus === UploadStatusesEnum.Pending ? '' : 'hide-dragger',
    customRequest,
    ...props,
  }

  return (
    <>
      <Row>
        <Col span={24}>
          <Dragger {...draggerProps}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">Click or drag file to this area to upload</p>
            <p className="ant-upload-hint">
              {customText ||
                'Support for a single or bulk upload. Strictly prohibit from uploading company data or other band files'}
            </p>
          </Dragger>
        </Col>
      </Row>
    </>
  )
}

const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result as string)
    reader.onerror = error => reject(error)
  })
