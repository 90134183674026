import { Avatar } from 'antd'
import React, { useContext, useMemo } from 'react'
import { MeContext, User } from '../../api'

type Props = {
  size?: 16 | 32 | 64 | 128 | 256
  user?: Pick<User, 'avatarUrl' | 'firstName' | 'lastName'>
}

export const UserAvatar: React.FC<Props> = ({ size = 128, user: inputUser }) => {
  const { data } = useContext(MeContext)
  const user = useMemo(() => inputUser || data?.me.user, [data, inputUser])
  const fileWithoutExt = user?.avatarUrl?.split('.').slice(0, -1).join('.') || undefined
  return (
    <Avatar
      size={size}
      style={{ backgroundColor: '#f56a00', verticalAlign: 'middle' }}
      src={`${fileWithoutExt}_${size}.jpeg`}>
      {user?.avatarUrl ? null : (
        <span style={{ fontSize: `${size / 2}px` }}>{user ? user?.firstName[0] + user?.lastName[0] : ''}</span>
      )}
    </Avatar>
  )
}
