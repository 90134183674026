import { gql, QueryHookOptions, useMutation, useQuery } from '@apollo/client'
import {
  Mutation,
  MutationActivateCustomerArgs,
  MutationCreateCustomerArgs,
  MutationDeactivateCustomerArgs,
  MutationUpdateCustomerArgs,
  Query,
  QueryCustomerArgs,
  QueryCustomersArgs,
} from '../gen/models'
import { OrganizationFragment } from './organizations'
import { SimplifiedUserFragment } from './users'

const CustomerFragment = gql`
  fragment CustomerFragment on Customer {
    id
    name
    address
    stripeId
    address2
    city
    state
    zipCode
    website
    phoneNumber
    medicareId
    geoCode
    maxUser
    isActive
    type
  }
`

const CustomerWithStatsFragment = gql`
  fragment CustomerWithStatsFragment on Customer {
    ...CustomerFragment
    totalUsers
    totalOrganizations
    totalPowerUsers
    topUsers(isPowerUser: true) {
      ...SimplifiedUserFragment
    }
  }
  ${CustomerFragment}
  ${SimplifiedUserFragment}
`

const CUSTOMERS_WITH_STATS_QUERY = gql`
  query customersWithStats($input: GetCustomersByCriteriaInput!) {
    customersWithStats(input: $input) {
      ...CustomerWithStatsFragment
    }
  }
  ${CustomerWithStatsFragment}
`

export function useQueryCustomersWithStats(
  baseOptions?: QueryHookOptions<Pick<Query, 'customersWithStats'>, QueryCustomersArgs>,
) {
  return useQuery(CUSTOMERS_WITH_STATS_QUERY, baseOptions)
}

const CUSTOMERS_QUERY = gql`
  query customers($input: GetCustomersByCriteriaInput!) {
    customers(input: $input) {
      ...CustomerFragment
    }
  }
  ${CustomerFragment}
`

export function useQueryCustomers(baseOptions?: QueryHookOptions<Pick<Query, 'customers'>, QueryCustomersArgs>) {
  return useQuery(CUSTOMERS_QUERY, baseOptions)
}

const CREATE_CUSTOMER = gql`
  mutation createCustomer($input: CreateCustomerInput!) {
    createCustomer(input: $input) {
      ...CustomerWithStatsFragment
    }
  }
  ${CustomerWithStatsFragment}
`

export function useCreateCustomerMutation(variables?: QueryCustomersArgs) {
  return useMutation<Pick<Mutation, 'createCustomer'>, MutationCreateCustomerArgs>(CREATE_CUSTOMER, {
    refetchQueries: ['customers'],
    update: (cache, { data }) => {
      const cachedData = cache.readQuery<Pick<Query, 'customersWithStats'>>({
        query: CUSTOMERS_WITH_STATS_QUERY,
        variables,
      })
      const cachedCustomers = (cachedData && cachedData.customersWithStats) || []
      if (data && data.createCustomer) {
        cache.writeQuery({
          query: CUSTOMERS_WITH_STATS_QUERY,
          variables,
          data: { customersWithStats: cachedCustomers.concat(data.createCustomer) },
        })
      }
    },
  })
}

const CUSTOMER_QUERY = gql`
  query customer($input: IDArgs!) {
    customer(input: $input) {
      ...CustomerFragment
      topUsers {
        ...SimplifiedUserFragment
      }
      organizations {
        totalSubOrganizations
        ...OrganizationFragment
        allParents {
          id
          name
        }
        topPowerUsers {
          ...SimplifiedUserFragment
        }
      }
    }
  }
  ${CustomerFragment}
  ${OrganizationFragment}
  ${SimplifiedUserFragment}
`

export function useCustomerQuery(baseOptions?: QueryHookOptions<Pick<Query, 'customer'>, QueryCustomerArgs>) {
  return useQuery(CUSTOMER_QUERY, baseOptions)
}

const ACTIVATE_CUSTOMER = gql`
  mutation activateCustomer($input: CustomerActivationInput!) {
    activateCustomer(input: $input) {
      ...CustomerFragment
    }
  }
  ${CustomerFragment}
`
export function useActivateCustomerMutation() {
  return useMutation<Pick<Mutation, 'activateCustomer'>, MutationActivateCustomerArgs>(ACTIVATE_CUSTOMER)
}

const DEACTIVATE_CUSTOMER = gql`
  mutation deactivateCustomer($input: CustomerActivationInput!) {
    deactivateCustomer(input: $input) {
      ...CustomerFragment
    }
  }
  ${CustomerFragment}
`
export function useDeactivateCustomerMutation() {
  return useMutation<Pick<Mutation, 'deactivateCustomer'>, MutationDeactivateCustomerArgs>(DEACTIVATE_CUSTOMER)
}

const UPDATE_CUSTOMER = gql`
  mutation updateCustomer($input: UpdateCustomerInput!) {
    updateCustomer(input: $input) {
      ...CustomerFragment
    }
  }
  ${CustomerFragment}
`

export function useUpdateCustomerMutation() {
  return useMutation<Pick<Mutation, 'updateCustomer'>, MutationUpdateCustomerArgs>(UPDATE_CUSTOMER)
}
