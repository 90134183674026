import { Button, Col, message, Row, Tag } from 'antd'
import React, { useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import {
  useAdminResetUserPasswordMutation,
  useQueryUser,
  useToggleUserActivationMutation,
  Organization,
  UserOrganization,
  UserStatus,
} from '../../api'
import { PermissionPlaceholder } from '../components/PermissionPlaceholder'
import { UserInfoCard } from '../components/UserInfoCard'
import { UserManagementModal } from '../components/UserManagementModal'
import { UserOrganizationsCard } from '../components/UserOrganizationsCard'
import { BaseManagePageLayout } from './BaseManagePageLayout'

export const ManageUserPage: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const { data, refetch } = useQueryUser({ variables: { id: id! } })
  const user = data && data.user
  const routePaths = user
    ? [
        { path: 'index', breadcrumbName: 'User Management', href: '/manage/users' },
        { path: 'second-item', breadcrumbName: `${user.firstName} ${user.lastName}`, href: '' },
      ]
    : []

  const [deactivateUser] = useToggleUserActivationMutation()
  const [resetUserPassword] = useAdminResetUserPasswordMutation()
  const [organizationsPermissionModal, setOrganizationsPermissionModal] = useState<{
    visible: boolean
    organizations: Organization[]
    userOrganizations: UserOrganization[]
  }>({
    visible: false,
    organizations: [],
    userOrganizations: [],
  })

  const itemRender = (route: any, _params: any, _routes: any, _paths: string[]) => {
    const last = routePaths.indexOf(route) === routePaths.length - 1
    return last ? <span>{route.breadcrumbName}</span> : <Link to={route.href}>{route.breadcrumbName}</Link>
  }

  return user ? (
    <>
      <Helmet>
        <title>{user ? user.firstName + ' ' + user.lastName : 'User Profile'}</title>
      </Helmet>
      <BaseManagePageLayout
        title={user.firstName + ' ' + user.lastName}
        extra={[
          <PermissionPlaceholder permissionTarget="curvoAdmin">
            <Button
              key="deactivate-user"
              disabled={user.status === UserStatus.Pending}
              danger={user.status === UserStatus.Active}
              type={user.status === UserStatus.Inactive ? 'primary' : 'default'}
              onClick={() => {
                const params = { variables: { input: { userId: id! } } }
                deactivateUser(params).then(() => {
                  message.success(`User has been ${user.status === UserStatus.Active ? 'deactivated' : 'activated'}`)
                })
              }}>
              {user.status === UserStatus.Active ? 'Deactivate' : 'Activate'} User
            </Button>
          </PermissionPlaceholder>,
          <Button
            key="reset-password"
            type="default"
            onClick={() => {
              const params = { variables: { email: user.email } }
              resetUserPassword(params).then(() => {
                message.success('Email with password change instructions was sent to user.')
              })
            }}>
            Reset Password
          </Button>,
          <PermissionPlaceholder permissionTarget="curvoAdmin">
            <Button
              /*disabled={!user.organizations.length}*/
              onClick={() =>
                setOrganizationsPermissionModal({
                  visible: true,
                  organizations: user.organizations,
                  userOrganizations: user.userOrganizations,
                })
              }
              key="user-permissions"
              type="default">
              Edit User Permissions
            </Button>
          </PermissionPlaceholder>,
        ]}
        breadcrumb={{ routes: routePaths, itemRender }}
        tags={<Tag color="#108ee9">{user.status}</Tag>}>
        <UserManagementModal
          visible={organizationsPermissionModal.visible}
          onCancel={() => setOrganizationsPermissionModal({ visible: false, organizations: [], userOrganizations: [] })}
          user={user}
          onFinished={() => {
            refetch()
            setOrganizationsPermissionModal({ visible: false, organizations: [], userOrganizations: [] })
          }}
        />
        <Row gutter={[16, 16]}>
          <Col span={16}>
            <UserInfoCard user={user} />
          </Col>
          <Col span={8}>
            <UserOrganizationsCard user={user} />
          </Col>
        </Row>
      </BaseManagePageLayout>
    </>
  ) : (
    <></>
  )
}
