import { Checkbox, Col, Form, Input, message, Modal, Radio, Row, Select, Skeleton, Tree } from 'antd'
import FormItem from 'antd/es/form/FormItem'
import { ModalProps } from 'antd/es/modal'
import type { DataNode } from 'antd/es/tree'
import { isNil, uniq } from 'lodash'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import {
  CreateUserInput,
  InviteUserInput,
  MeContext,
  UpdateUserInput,
  useCustomerQuery,
  useDefaultUserPermissions,
  useInviteUserMutation,
  useQueryCustomers,
  useQueryUser,
  User,
  UserPermissionFeature,
  useUpdateUserMutation,
} from '../../api'
import { OrganizationsPermissionsSelector } from './OrganizationsPermissionsSelector'
import { ExternalAuthConnectionSelector } from './selectors/ExternalAuthConnectionSelector'
import { UserQSGroupsSelector } from './UserQSGroupsSelector'

type Props = {
  user?: User
  defaultCustomerId?: string
  onFinished: (user: User) => void
} & Pick<ModalProps, 'visible' | 'onCancel'>

export const UserManagementModal: React.FC<Props> = ({ user, visible, onCancel, onFinished, defaultCustomerId }) => {
  const { data: meData } = useContext(MeContext)
  const [newUserInput, setNewUserInput] = useState<UpdateUserInput | CreateUserInput>()
  const [form] = Form.useForm()
  const isTopUserValue = Form.useWatch('isTopUser', form)
  const isTopPowerUserValue = Form.useWatch('isTopPowerUser', form)
  const selectedCustomerId = Form.useWatch('customerId', form)

  const adminEditorPermission = useMemo(
    () => meData?.me.isAdmin || meData?.me.user.role === 'customer-success',
    [meData?.me],
  )

  const { data, loading: customersLoading } = useCustomerQuery(
    selectedCustomerId ? { variables: { input: { id: selectedCustomerId } } } : { skip: true },
  )
  const customer = data?.customer

  const organizations = useMemo(() => data?.customer.organizations || [], [data])
  const rootOrganizations = useMemo(() => organizations.filter(o => isNil(o.parentId)), [organizations])

  const customersRequest = useQueryCustomers({ variables: { input: { type: null } }, skip: !meData?.me.isAdmin })
  const customers = useMemo(
    () => (customersRequest.data ? customersRequest.data.customers : customer ? [customer] : []),
    [customersRequest, customer],
  )

  // User request to get org permissions
  const userRequest = useQueryUser(
    user && user.id ? { variables: { id: user.id }, fetchPolicy: 'network-only' } : { skip: true },
  )

  const [inviteUser, { loading: inviteUserLoading }] = useInviteUserMutation()
  const [updateUser, { loading: updateUserLoading }] = useUpdateUserMutation()

  useEffect(() => {
    form.setFieldsValue({ organizationIds: [] })
    const optionCustomer = customers.find(c => selectedCustomerId === c.id)
    const currentValue = form.getFieldValue('qsGroups') || []
    if (!optionCustomer) {
      return
    }

    if (
      !currentValue.length ||
      (currentValue.length === 1 && ['curvox_suppliers', 'curvox_users'].includes(currentValue[0]))
    ) {
      form.setFieldsValue({
        qsGroups: optionCustomer.type === 'supplier' ? ['curvox_suppliers'] : ['curvox_users'],
      })
    }
  }, [selectedCustomerId, customers, form])

  useEffect(() => {
    if (isTopUserValue) {
      form.setFields([
        {
          name: 'organizations',
          value: rootOrganizations.map(org => ({
            organizationId: org.id,
            isPowerUser: isTopPowerUserValue,
          })),
        },
      ])
    } else {
      if (userRequest.data) {
        form.setFields([
          {
            name: 'organizations',
            value: userRequest.data.user.userOrganizations.map(userOrg => ({
              organizationId: userOrg.organizationsId,
              isPowerUser: userOrg.isPowerUser,
            })),
          },
        ])
      }
    }
  }, [isTopUserValue, form, rootOrganizations, isTopPowerUserValue, userRequest.data])

  useEffect(() => {
    const initCustomer = customers.find(c => c.id === defaultCustomerId)

    form.resetFields()
    form.setFieldsValue(
      user
        ? {
            customerId: user.customer?.id || user.customerId,
            email: user.email,
            firstName: user.firstName,
            lastName: user.lastName,
            organizations: userRequest.data
              ? userRequest.data.user.userOrganizations.map(userOrg => ({
                  organizationId: userOrg.organizationsId,
                  isPowerUser: userOrg.isPowerUser,
                }))
              : [],
            permissions: userRequest.data?.user.permissions?.can.map(ability => ability.value),
            qsGroups: user.qsGroups || [],
            isTopUser: user.isTopUser,
            isTopPowerUser: user.isTopPowerUser,
          }
        : {
            customerId: initCustomer?.id || defaultCustomerId,
            email: undefined,
            firstName: undefined,
            lastName: undefined,
            organizations: [],
            qsGroups: initCustomer?.type === 'supplier' ? ['curvox_suppliers'] : ['curvox_users'],
          },
    )
  }, [form, user, visible, customers, userRequest.data, defaultCustomerId])

  const submit = async () => {
    try {
      await form.validateFields()
    } catch (e) {
      return
    }

    const { externalAuthConnectionId, ...rest } = form.getFieldsValue()

    const input = {
      ...(user && user.id ? { id: user.id } : {}),
      ...{ customerId: '', email: '', organizations: [] }, // This is just to avoid the compiler to complain
      ...rest,
      externalAuthConnectionId: externalAuthConnectionId?.value,
    }

    // Send to server
    try {
      const invitedUser =
        !user || !user.id ? await inviteUser({ variables: { input: input as InviteUserInput } }) : undefined
      if (invitedUser?.data?.inviteUser) {
        onFinished(invitedUser.data.inviteUser)
        message.success('Invite new user successfully.')
        return
      }
    } catch (e) {
      message.error(e.message)
    }

    const updatedUser =
      user && user.id ? await updateUser({ variables: { input: input as UpdateUserInput } }) : undefined
    if (updatedUser?.data?.updateUser) {
      onFinished(updatedUser.data.updateUser)
    }
  }

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      onOk={submit}
      confirmLoading={
        updateUserLoading || inviteUserLoading || customersLoading || customersRequest.loading || userRequest.loading
      }
      title={user ? 'Edit User' : 'Invite New User'}>
      {userRequest.loading ? (
        <Skeleton />
      ) : (
        <Form
          form={form}
          size="middle"
          layout="vertical"
          onValuesChange={v => {
            setNewUserInput({
              ...newUserInput,
              ...v,
            })
          }}>
          <FormItem
            name="customerId"
            label="Customer"
            rules={[
              {
                required: true,
                message: 'Please select a customer',
              },
            ]}>
            <Select showSearch filterOption optionFilterProp="label" disabled={!!defaultCustomerId || !!user}>
              {customers.map(c => (
                <Select.Option key={c.id} value={c.id} label={c.name}>
                  {c.name}
                </Select.Option>
              ))}
            </Select>
          </FormItem>
          <FormItem label="External Authentication System" name="externalAuthConnectionId">
            <ExternalAuthConnectionSelector allowClear labelInValue={false} />
          </FormItem>
          <FormItem
            label="E-Mail"
            name="email"
            rules={[
              {
                type: 'email',
                message: 'The input is not valid email!',
              },
              {
                required: true,
                message: 'Please input the user email',
              },
            ]}>
            <Input />
          </FormItem>
          <Row gutter={8}>
            <Col span={12}>
              <FormItem
                label="First Name"
                name="firstName"
                rules={[
                  {
                    required: true,
                    message: 'Please input the user First Name',
                  },
                ]}>
                <Input />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                label="Last Name"
                name="lastName"
                rules={[
                  {
                    required: true,
                    message: 'Please input the user Last Name',
                  },
                ]}>
                <Input />
              </FormItem>
            </Col>
          </Row>

          {adminEditorPermission ? (
            <Row gutter={8}>
              <Col span={12}>
                <FormItem name="isTopUser" valuePropName="checked">
                  <Checkbox>Top User</Checkbox>
                </FormItem>
              </Col>
              <Col span={12}>
                {isTopUserValue ? (
                  <FormItem name="isTopPowerUser" valuePropName="checked">
                    <Checkbox>Power User</Checkbox>
                  </FormItem>
                ) : null}
              </Col>
            </Row>
          ) : null}
          {selectedCustomerId && (
            <FormItem label="Organizations" name="organizations">
              <OrganizationsPermissionsSelector
                loading={customersLoading}
                disabled={isTopUserValue || customersLoading}
                organizations={organizations}
              />
            </FormItem>
          )}

          <FormItem label="QuickSight Groups" name="qsGroups">
            <UserQSGroupsSelector />
          </FormItem>

          {adminEditorPermission ? (
            <FormItem label="Permissions" name="permissions">
              <PermissionEditor loading={userRequest.loading} />
            </FormItem>
          ) : null}
        </Form>
      )}
    </Modal>
  )
}

const PermissionEditor: React.FC<{
  value?: UserPermissionFeature[]
  onChange?: (newValue: UserPermissionFeature[] | undefined) => void
  loading?: boolean
}> = ({ value, onChange, loading }) => {
  const [customizableState, setCustomizableState] = useState<string>('default')
  const [initCheck, setInitCheck] = useState(false)

  const { data: defaultUserPermissionsData, loading: defaultLoading } = useDefaultUserPermissions()

  const customizableOptions = [
    { label: 'Custom', value: 'custom' },
    { label: 'Default', value: 'default' },
  ]

  const checkedKeys = useMemo(() => userAbilitiesToCheckedKeys(value), [value])
  const defaultPermissionsKeys = useMemo(
    () => userAbilitiesToCheckedKeys(defaultUserPermissionsData?.defaultPermissions.can.map(v => v.value)),
    [defaultUserPermissionsData?.defaultPermissions],
  )

  useEffect(() => {
    if (
      !initCheck &&
      !loading &&
      !defaultLoading &&
      value &&
      JSON.stringify(userAbilitiesToCheckedKeys(value)) !== JSON.stringify(defaultPermissionsKeys)
    ) {
      setCustomizableState('custom')
      setInitCheck(true)
    }
  }, [value, defaultPermissionsKeys, loading, defaultLoading, initCheck])

  const treeData: DataNode[] = useMemo(() => {
    const currentValue =
      customizableState === 'default' ? defaultUserPermissionsData?.defaultPermissions.can.map(c => c.value) : value
    const allAnalyze = !!currentValue?.find(v => v === UserPermissionFeature.Analyze)
    const allActivate = !!currentValue?.find(v => v === UserPermissionFeature.Activate)
    const allEnrich = !!currentValue?.find(v => v === UserPermissionFeature.Enrich)

    return [
      {
        title: 'All permissions',
        key: 'all',
        checkable: false,
        children: [
          {
            title: allAnalyze ? 'Analytics (All)' : 'Analytics',
            key: 'Analyze',
            children: [
              ...['contracts', 'baskets', 'spending', 'reports'].map(i => ({
                title: i,
                key: i,
                disabled: allAnalyze,
              })),
            ],
          },
          {
            title: allActivate ? 'Strategic Sourcing (All)' : 'Strategic Sourcing',
            key: 'Activate',
            children: [
              ...['opportunities', 'sourcingPlans'].map(i => ({
                title: i,
                key: i,
                disabled: allActivate,
              })),
              {
                title: 'Explicit features',
                key: 'activate_explicit',
                checkable: false,
                children: [
                  {
                    title: 'Project Tracker',
                    key: 'projectTracker',
                  },
                  {
                    title: 'CX Project Tracker',
                    key: 'cxProjectTracker',
                  },
                ],
              },
            ],
          },
          {
            title: allEnrich ? 'Enrich (all)' : 'Enrich',
            key: 'Enrich',
            children: [
              ...['teamQueue', 'catalog', 'importStudy'].map(i => ({
                title: i,
                key: i,
                disabled: allEnrich,
              })),
            ],
          },
          {
            title: 'Project Aperture',
            key: 'ProjectAperture',
            children: [],
          },
        ],
      },
    ]
  }, [value, customizableState, defaultUserPermissionsData?.defaultPermissions])

  return (
    <div>
      <Radio.Group
        options={customizableOptions}
        value={customizableState}
        onChange={v => {
          setCustomizableState(v.target.value)
          if (v.target.value === 'default') {
            onChange && onChange([UserPermissionFeature.Null])
          } else {
            onChange && onChange(defaultUserPermissionsData?.defaultPermissions.can.map(i => i.value))
          }
        }}
        optionType="button"
        buttonStyle="solid"
      />
      <Tree
        disabled={customizableState === 'default'}
        checkStrictly
        onCheck={v => {
          const values = (
            v as {
              checked: string[]
              halfChecked: string[]
            }
          ).checked.filter(
            i => ['analyze', 'activate', 'enrich', 'activate_explicit'].indexOf(i) === -1,
          ) as UserPermissionFeature[]

          setCustomizableState(
            (
              v as {
                checked: string[]
                halfChecked: string[]
              }
            ).checked.find(i => i === UserPermissionFeature.Null)
              ? 'default'
              : 'custom',
          )

          onChange && onChange(values)
        }}
        checkable
        defaultExpandAll
        treeData={treeData}
        checkedKeys={customizableState === 'custom' ? checkedKeys : defaultPermissionsKeys}
      />
    </div>
  )
}

function userAbilitiesToCheckedKeys(value?: UserPermissionFeature[]) {
  return {
    checked: uniq([
      ...((value || []) as string[]),
      ...((value || []).find(v => v === 'Analyze') ? ['contracts', 'baskets', 'spending', 'reports'] : []),
      ...((value || []).find(v => v === 'Activate') ? ['opportunities', 'sourcingPlans'] : []),
      ...((value || []).find(v => v === 'Enrich') ? ['teamQueue', 'catalog', 'importStudy'] : []),
    ]),
    halfChecked: [
      ...((value || []).find(v => ['contracts', 'baskets', 'spending', 'reports'].indexOf(v) !== -1)
        ? ['Analyze']
        : []),
      ...((value || []).find(v => ['opportunities', 'sourcingPlans', 'projectTracker'].indexOf(v) !== -1)
        ? ['Activate']
        : []),
      ...((value || []).find(v => ['teamQueue', 'catalog', 'importStudy'].indexOf(v) !== -1) ? ['Enrich'] : []),
    ],
  }
}
