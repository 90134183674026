import { Carousel, CarouselProps, Skeleton } from 'antd'
import React from 'react'
import styled from 'styled-components'
import ArrowRight from './static/arrow-right.png'
import ArrowLeft from './static/arrow-left.png'

type Props<T> = {
  items: T[]
  getRowKey?: (item: T, index: number) => React.Key
  renderItem: (item: T, index: number) => React.ReactElement
  slidesToShow?: number
  loading?: boolean
  extraItem?: React.ReactElement | null
} & CarouselProps

export const HomeCarousel = <T extends {}>({
  items,
  renderItem,
  loading,
  slidesToShow = 3,
  getRowKey,
  extraItem,
  ...props
}: Props<T>) => {
  if (loading) {
    return <Skeleton active />
  }

  return (
    <CarouselWrapper
      arrows
      dots={false}
      infinite={false}
      {...props}
      prevArrow={
        <span>
          <img src={ArrowLeft} />
        </span>
      }
      nextArrow={
        <span>
          <img src={ArrowRight} />
        </span>
      }
      slidesToShow={slidesToShow}
      slidesToScroll={slidesToShow}>
      {items.map((item, index) => (
        <Item key={getRowKey ? getRowKey(item, index) : index}>{renderItem(item, index)}</Item>
      ))}
      {extraItem && <Item key="extra-item">{extraItem}</Item>}
    </CarouselWrapper>
  )
}

const CarouselWrapper = styled(Carousel)`
  margin: 0 -12px;

  .slick-list {
    display: flex;

    .slick-track {
      display: flex;
      flex-direction: row;
      align-items: stretch;

      .slick-slide {
        display: inline-flex;

        > div {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .slick-arrow {
    height: 100%;
    display: flex !important;
    top: 10px;
    align-items: center;

    :before,
    :after {
      content: none;
    }

    > img {
      height: 30px;
      width: 15px;
    }
  }

  .slick-disabled {
    visibility: hidden;
  }

  .slick-prev,
  .slick-prev:focus {
    font-size: 1.5em;
    left: -15px;
    z-index: 2;
    color: var(--corlor-neutral-60);
  }

  .slick-next,
  .slick-next:focus {
    font-size: 1.5em;
    right: -15px;
    z-index: 2;
    color: var(--corlor-neutral-60);
  }

  .slick-prev:hover,
  .slick-next:hover {
    color: black;
  }
`

const Item = styled.div`
  padding: 0 12px;
  height: 100%;

  > div {
    height: 100%;
  }
`
