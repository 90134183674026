import {
  DeploymentUnitOutlined,
  DollarCircleOutlined,
  SafetyCertificateOutlined,
  StarFilled,
  StarOutlined,
  SyncOutlined,
  TeamOutlined,
} from '@ant-design/icons'
import { Button, Card, Row } from 'antd'
import { format } from 'date-fns'
import React, { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import {
  Initiative,
  InitiativeActivityActionEnum,
  InitiativeStatusEnum,
  useAddInitiativeActivity,
  useRemoveInitiativeActivity,
} from '../../../api'

type Props = {
  initiative: Initiative
  noLogo?: boolean
}

export const InitiativeCard: React.FC<Props> = ({ initiative, noLogo }) => {
  const [interested, setInterested] = useState(initiative.interested)
  const [addInitiativeActivity, { loading: addingActivity }] = useAddInitiativeActivity()
  const [removeInitiativeActivity, { loading: removingActivity }] = useRemoveInitiativeActivity()

  const valueElements = useMemo(() => {
    if (!initiative.valueElement) {
      return null
    }
    return initiative.valueElement.map(v => {
      const icon = (function () {
        switch (v) {
          case 'Cost Savings':
            return <DollarCircleOutlined />
          case 'Clinical Impact':
            return <SafetyCertificateOutlined />
          default:
            return null
        }
      })()
      return (
        <TagItem title={v}>
          {icon}
          <span>{initiative.valueElement}</span>
        </TagItem>
      )
    })
  }, [initiative.valueElement])

  const handleFavorite = (value: boolean) => {
    if (addingActivity || removingActivity) {
      return
    }
    if (value) {
      addInitiativeActivity({
        variables: { input: { initiativeId: initiative.id, action: InitiativeActivityActionEnum.Interested } },
        onCompleted: () => {
          setInterested(true)
        },
      })
    } else {
      removeInitiativeActivity({
        variables: { input: { initiativeId: initiative.id, action: InitiativeActivityActionEnum.Interested } },
        onCompleted: () => {
          setInterested(false)
        },
      })
    }
  }

  useEffect(() => {
    setInterested(initiative.interested)
  }, [initiative.interested])

  const isCompleted = initiative.status === InitiativeStatusEnum.Completed
  const collaboratorCount = isCompleted ? initiative.dealRequestCount : initiative.interestedCount

  return (
    <WrapperCard interested={interested}>
      <CardBody>
        <Card.Meta
          title={
            <Title>
              <Link to={`/aperture/initiatives/${initiative.id}`} title={initiative.title || 'Initiative'}>
                {initiative.title || 'Initiative'}
              </Link>
              <Button
                loading={addingActivity || removingActivity}
                className="favorite-btn"
                icon={interested ? <StarFilled /> : <StarOutlined />}
                type="text"
                onClick={() => handleFavorite(!interested)}
              />
            </Title>
          }
          description={
            <CardDescription>
              <div>
                <div>{initiative.medtech}</div>
                <div>
                  {`${initiative.hospitalArea}, ${isCompleted ? 'Completed' : 'Started'} ${format(
                    new Date((isCompleted ? initiative.completedAt : initiative.startedAt) || initiative.createdAt),
                    'MMMM yyyy',
                  )}`}
                </div>
              </div>
              {!noLogo && initiative.network?.logo && <img src={initiative.network?.logo} alt="Initiative logo" />}
            </CardDescription>
          }
        />
        <div>
          <TagRow>
            <TagItem>
              <TeamOutlined />
              <span>{`${collaboratorCount} collaborator${collaboratorCount > 1 ? 's' : ''}`}</span>
            </TagItem>
          </TagRow>
          <TagRow>
            <TagItem title={(initiative.network && initiative.network.name) || undefined}>
              <DeploymentUnitOutlined />
              <span>{!initiative.network ? 'Public' : initiative.network.name}</span>
            </TagItem>
            {isCompleted && initiative.valueElement && valueElements}
            {!isCompleted && (
              <InProgessItem>
                <SyncOutlined />
                <span>In-Progress</span>
              </InProgessItem>
            )}
          </TagRow>
        </div>
      </CardBody>
    </WrapperCard>
  )
}

const WrapperCard = styled(Card)<{ interested: boolean }>`
  .ant-card-body {
    height: 100%;
  }

  .ant-card-meta-detail {
    .ant-card-meta-title {
      margin-bottom: 0;
    }
  }

  .favorite-btn {
    cursor: pointer;
    background-color: transparent;
    visibility: ${props => (props.interested ? 'visible' : 'hidden')};
    color: var(--color-accent-gold-50);

    &:hover {
      color: var(--color-accent-gold-70);
    }
  }

  &:hover {
    .favorite-btn {
      visibility: visible;
    }
  }
`

const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 24px;
  width: 100%;
  height: 100%;
`

const Title = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 6px;
  justify-content: space-between;

  a {
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

const TagRow = styled(Row)`
  gap: 5px 16px;
  min-height: 30px;
  flex-wrap: nowrap;
  width: 100%;
`

const TagItem = styled.div`
  display: flex;
  gap: 4px;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  color: var(--color-neutral-90);
  overflow: hidden;

  span {
    text-wrap: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`

const InProgessItem = styled(TagItem)`
  color: var(--color-blue-70);
  border: 1px solid var(--color-blue-70);
  border-radius: 15px;
  height: 30px;
  flex: 0 0 110px;
  align-items: center;
  justify-content: center;
`

const CardDescription = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  img {
    height: 45px;
  }
`
