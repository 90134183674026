import React from 'react'

/**
 * Catch error and silently render null instead
 */
export class ErrorIgnoreBoundary extends React.Component<any, { hasError?: boolean }> {
  constructor(props: any) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error: any, errorInfo: any) {
    // You can also log the error to an error reporting service
    console.error(error, errorInfo)
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <></>
    }

    return this.props.children
  }
}
