import React, { useEffect, useMemo, useState } from 'react'
import { Row, Col, Select, Input } from 'antd'
import { SearchOutlined } from '@ant-design/icons/lib'
import { debounce } from 'lodash'
import { BasketSort } from '../../api'
import * as dt from '../../ui/design-tokens/tokens'

export type FiltersBarConfig = {
  name: string
  label: string
  loading?: boolean
  emptyLabel?: string
  options: { value: any; label: string }[]
}

export type FiltersBarSortConfig = {
  fields: { key: any; label: string }[]
}

type Props = {
  filters: FiltersBarConfig[]
  sorting?: FiltersBarSortConfig
  sortingSelection?: BasketSort[]
  search?: boolean
  searchItem?: string
  onChange: (selections: any) => void
  onSortChange?: (sort: BasketSort[]) => void
  selections?: { [key: string]: string }
  loading?: boolean
  searchDebounceTimeout?: number
  searchText?: string
}

export const FiltersBar: React.FC<Props & React.HTMLAttributes<HTMLDivElement>> = ({
  style,
  filters,
  sorting,
  search = true,
  searchItem,
  searchText,
  onChange,
  selections,
  sortingSelection,
  onSortChange,
  loading,
  searchDebounceTimeout = 800,
}) => {
  const [_searchText, setSearchText] = useState<string>()
  const onSelectChange = (key: any) => (value: any) => {
    const newSelections = { ...selections, [key]: value }
    onChange(newSelections)
  }

  useEffect(() => {
    setSearchText(searchText)
  }, [searchText])

  const onSortChangeHndl = (changes: any) => {
    const [field, dir] = changes.split('#')
    // Sending an array so in the future this can be converted to multi-field sorting
    onSortChange && onSortChange([{ field, dir }])
  }

  const searchDebounce = useMemo(() => {
    const onSearch = (ss: { [key: string]: string } | undefined, e: any) => {
      const newSelections = { ...ss, searchText: e.target.value }
      onChange(newSelections)
    }
    return debounce(onSearch, searchDebounceTimeout)
  }, [onChange, searchDebounceTimeout])

  return (
    <Row style={style} className="crv-filters-bar">
      <Col flex={2}>
        {filters.map(filter => (
          <Select
            key={filter.name}
            loading={loading || filter.loading}
            showSearch
            placeholder={`Select ${filter.label}`}
            style={{ marginRight: '8px', width: 150 }}
            optionFilterProp="children"
            onChange={onSelectChange(filter.name)}
            filterOption
            value={filter.options.length && selections && selections[filter.name] ? selections[filter.name] : ''}>
            <Select.Option key="" value="">
              {filter.emptyLabel || `All ${filter.label}s`}
            </Select.Option>
            {filter.options.map(option => (
              <Select.Option key={option.value} value={option.value}>
                {option.label}
              </Select.Option>
            ))}
          </Select>
        ))}
        {sorting && sorting.fields.length ? (
          <Select
            key="sorting"
            placeholder={'Sort by'}
            style={{ marginRight: '8px' }}
            onChange={onSortChangeHndl}
            value={
              sortingSelection
                ? `${sortingSelection[0].field}#${sortingSelection[0].dir}`
                : `${sorting.fields[0].key}#asc`
            }>
            {sorting.fields.map(field =>
              ['ASC', 'DESC'].map(dir => {
                const value = `${field.key}#${dir}`
                return (
                  <Select.Option key={value} value={value}>
                    Sort by: {field.label} {dir}
                  </Select.Option>
                )
              }),
            )}
          </Select>
        ) : (
          ''
        )}
      </Col>
      {search ? (
        <Col flex={1}>
          <Input
            prefix={<SearchOutlined style={{ color: dt.ColorNeutral50 }} />}
            placeholder={`Search for ${searchItem}...`}
            onChange={e => {
              searchDebounce(selections, e)
              setSearchText(e.target.value)
            }}
            value={_searchText}
          />
        </Col>
      ) : (
        ''
      )}
    </Row>
  )
}
