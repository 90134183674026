import React from 'react'
import styled from 'styled-components'
import { Button as AntdButton } from 'antd'
import { ButtonProps } from 'antd/es/button'
import { MoreOutlined } from '@ant-design/icons'
import * as dt from '../../ui/design-tokens/tokens'

export type ButtonPropsType = {
  buttonType?: 'primary' | 'default'
}

export const Button = styled.button<ButtonPropsType>`
  height: 32px;
  padding-left: 10px;
  padding-right: 10px;
  line-height: 30px;
  font-weight: 600;
  color: #fff;

  background: ${({ buttonType }) => {
    return buttonType === 'primary' ? dt.ColorBlue50 : dt.ColorOrange50
  }};
  border: none;
  border-radius: 3px;
  cursor: pointer;
`

const MoreButtonWrapper = styled(AntdButton)`
  color: #172237;
  background-color: #edeeef;
  border-radius: 15px;
  padding: 0;
  width: 24px;
  height: 24px;
`

export const MoreButton: React.FC<ButtonProps> = props => (
  <MoreButtonWrapper {...props}>
    <MoreOutlined />
  </MoreButtonWrapper>
)

export const ContextMenuButton = styled(AntdButton)`
  border: 0;
  background-color: #edeeef;
  line-height: 1.2;
  width: 24px;
  height: 24px;
  min-width: 24px;
`
