import { gql, MutationHookOptions, useMutation } from '@apollo/client'
import { Mutation, MutationGenerateDocumentUploadUrlArgs, MutationGenerateImageUploadUrlArgs } from '../gen/models'

const GEN_S3_UPLOAD_IMAGE_URL_MUTATION = gql`
  mutation generateImageUploadUrl($input: UploadInput!) {
    generateImageUploadUrl(input: $input) {
      s3Key
      uploadUrl
      hiddenInputs
    }
  }
`

export const useGenS3UploadImageUrlMutation = (
  baseOptions?: MutationHookOptions<Pick<Mutation, 'generateImageUploadUrl'>, MutationGenerateImageUploadUrlArgs>,
) => useMutation(GEN_S3_UPLOAD_IMAGE_URL_MUTATION, baseOptions)

const GEN_S3_DOCUMENT_UPLOAD_URL_MUTATION = gql`
  mutation generateDocumentUploadUrl($input: UploadInput!) {
    generateDocumentUploadUrl(input: $input) {
      s3Key
      uploadUrl
      hiddenInputs
    }
  }
`

export const useGenS3DocumentUploadUrlMutation = (
  baseOptions?: MutationHookOptions<Pick<Mutation, 'generateDocumentUploadUrl'>, MutationGenerateDocumentUploadUrlArgs>,
) => useMutation(GEN_S3_DOCUMENT_UPLOAD_URL_MUTATION, baseOptions)
