import { Ability, AbilityBuilder } from '@casl/ability'
import { createContextualCan } from '@casl/react'
import { Spin } from 'antd'
import React, { createContext, useContext } from 'react'
import styled from 'styled-components'
import { User } from '../gen/models'
import { MeContext } from './MeContextProvider'

export const AbilityContext = createContext<Ability>(new Ability())

export const Can = createContextualCan(AbilityContext.Consumer)

export const AbilityProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  // User is always available here
  const { data, loading } = useContext(MeContext)

  if (!data?.me) {
    return <></>
  }

  return (
    <AbilityContext.Provider value={defineAbilitiesFor(data.me.user)}>
      {loading ? (
        <SpinWrapper>
          <Spin size="large" />
        </SpinWrapper>
      ) : (
        children
      )}
    </AbilityContext.Provider>
  )
}

const SpinWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

function defineAbilitiesFor(user: User) {
  const { can, cannot, rules } = new AbilityBuilder(Ability)

  user.permissions.can.forEach(ability => {
    can(ability.permissionType, ability.value)
  })
  user.permissions.cannot.forEach(ability => {
    cannot(ability.permissionType, ability.value)
  })

  return new Ability(rules)
}
