import { Button, Divider, Select } from 'antd'
import { SelectProps } from 'antd/lib/select'
import { LabeledValue } from 'antd/lib/tree-select'
import { isNil } from 'lodash'
import React, { RefObject, useEffect, useMemo } from 'react'
import { Organization, useOrganizationsQuery } from '../../../api'

const { Option } = Select

type Props = SelectProps<LabeledValue | LabeledValue[]> & {
  onChangeFull?: (selected?: Organization | Organization[] | null) => void
  onSelectFull?: (selected: Organization) => void
  facilityIdAsKey?: boolean
  customRef?: RefObject<any> | null
  allowSelectAll?: boolean
  skipValues?: string[]
  additionalValues?: LabeledValue[]
  onAdditionalValueSelected?: (value: LabeledValue) => void
  matchingOrganizationName?: string
  refetchCounter?: number
}

export const OrganizationsSelector: React.FC<Props> = ({
  searchValue,
  onChangeFull,
  onChange,
  onSelect,
  onSelectFull,
  facilityIdAsKey,
  customRef,
  allowSelectAll,
  skipValues,
  additionalValues,
  onAdditionalValueSelected,
  matchingOrganizationName,
  refetchCounter,
  ...props
}) => {
  const { data, loading, refetch } = useOrganizationsQuery({ variables: { input: { searchText: searchValue } } })
  const organizations = useMemo(
    () =>
      (data &&
        data.organizations &&
        data.organizations.filter(
          organization =>
            !!organization.facilityId &&
            !(skipValues || []).includes(facilityIdAsKey ? organization.facilityId.toString() : organization.id),
        )) ||
      [],
    [data, skipValues, facilityIdAsKey],
  )

  useEffect(() => {
    if (!isNil(refetchCounter)) {
      refetch()
    }
  }, [refetch, refetchCounter])

  useEffect(() => {
    if (matchingOrganizationName && !(props.value as LabeledValue | undefined)?.value && onSelectFull) {
      const matching = organizations.find(o => o.name === matchingOrganizationName)
      if (matching) {
        onSelectFull(matching)
      }
    }
  }, [matchingOrganizationName, props.value, organizations, onSelectFull])

  const handleSelectAll = () => {
    if (props.mode !== 'multiple') {
      return
    }
    if (onChangeFull) {
      onChangeFull(organizations)
      return
    }
    if (onChange) {
      const options = organizations.map(o => ({
        label: o.name,
        value: facilityIdAsKey ? o.facilityId!.toString() : o.id,
      }))
      onChange(options, options)
    }
  }

  return (
    <Select
      {...props}
      ref={customRef}
      showAction={['focus', 'click']}
      onChange={(...params) => {
        if (onChangeFull) {
          const selected =
            params[0] === null
              ? null
              : params[0] === undefined
              ? undefined
              : Array.isArray(params[0])
              ? (params[0] as LabeledValue[]).map(s => organizations.find(org => org.id === s.value)!)
              : organizations.find(org => org.id === (params[0] as LabeledValue).value)
          onChangeFull(selected)
          return
        }
        if (onChange) {
          onChange(...params)
        }
      }}
      onSelect={(a: LabeledValue, v: any) => {
        const selectedOrg = organizations.find(org => org.id === a.value)
        if (onSelectFull && selectedOrg) {
          onSelectFull(selectedOrg)
          return
        }
        const additionalValueSelected = additionalValues?.find(av => av.value === a.value)
        if (onAdditionalValueSelected && additionalValueSelected) {
          onAdditionalValueSelected(additionalValueSelected)
          return
        }
        if (onSelect) {
          onSelect(a, v)
        }
      }}
      loading={loading}
      labelInValue
      showSearch
      optionFilterProp="children"
      showArrow
      // eslint-disable-next-line react/no-unstable-nested-components
      dropdownRender={menu => (
        <>
          {allowSelectAll && props.mode === 'multiple' && (
            <>
              <Button type="text" block onClick={handleSelectAll}>
                Select All
              </Button>
              <Divider style={{ margin: '8px 0' }} />
            </>
          )}
          {menu}
        </>
      )}>
      {organizations.map(organization => (
        <Option
          title={organization.name}
          key={organization.id}
          value={facilityIdAsKey ? organization.facilityId!.toString() : organization.id}>
          {organization.name}
        </Option>
      ))}
      {additionalValues?.map(additionalValue => (
        <Option key={additionalValue.value} value={additionalValue.value}>
          {additionalValue.label}
        </Option>
      ))}
    </Select>
  )
}
