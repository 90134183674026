import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { Space } from 'antd'
import React from 'react'
import styled from 'styled-components'

export const passwordRules = [
  { regex: '.{8,}', message: 'At least 8 characters' },
  { regex: '[a-z]', message: 'Lower case letters (a-z)' },
  { regex: '[A-Z]', message: 'Upper case letters (A-Z)' },
  { regex: '[0-9]', message: 'Numbers (0-9)' },
  { regex: '[!@#$%^&*]', message: 'Special characters (e.g. !@#$%^&*)' },
]

export const PasswordPolicy: React.FC<{ errors?: string[] }> = ({ errors = [] }) => {
  return (
    <Wrapper>
      <div>Your password must contain:</div>
      <ul>
        <li>
          <PolicyItem
            pass={!errors[0]?.includes(passwordRules[0].message)}
            message={passwordRules[0].message}
            required
          />
        </li>
        <li>
          <PolicyItem message="At least 3 of the following:" pass={false} />
          <ul>
            {passwordRules.slice(1).map(r => (
              <li key={r.message}>
                <PolicyItem pass={!errors[0]?.includes(r.message)} message={r.message} />
              </li>
            ))}
          </ul>
        </li>
      </ul>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  border: 1px solid var(--color-neutral-20);
  padding: 8px;
  ul {
    padding-left: 16px;

    li {
      list-style: none;
      padding: 0 3px;
    }
  }
`

const PolicyItem: React.FC<{ pass: boolean; message: string; required?: boolean }> = ({ pass, message, required }) => (
  <Space style={pass ? { color: 'var(--color-green-70)' } : {}}>
    {pass ? (
      <CheckOutlined />
    ) : required ? (
      <CloseOutlined />
    ) : (
      <span style={{ width: '14px', display: 'inline-flex' }} />
    )}
    <span>{message}</span>
  </Space>
)
