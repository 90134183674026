import { ApolloLink, NextLink, Operation } from '@apollo/client'
import { isNil } from 'lodash'

const EPSILON = 100 // 100ms

/**
 * Create a Timeout link that trigger a `callbackFn` after `timeoutMs` exceeded
 */
export class ApolloTimeoutLink extends ApolloLink {
  private currentTimeoutId?: number
  private readonly localStorageKey: string
  constructor(
    private readonly callbackFn: () => any,
    private readonly timeoutMs: number,
    private readonly name: string,
  ) {
    super()
    this.localStorageKey = `TimeoutLink:${this.name}`
  }

  request(operation: Operation, forward?: NextLink | undefined) {
    if (!isNil(this.currentTimeoutId)) {
      clearTimeout(this.currentTimeoutId)
    }
    this.currentTimeoutId = setTimeout(() => {
      const lastTimeoutAtStr = localStorage.getItem(this.localStorageKey)
      const lastTimeoutAt = lastTimeoutAtStr ? parseInt(lastTimeoutAtStr, 10) : null
      if (!lastTimeoutAt || new Date().getTime() - lastTimeoutAt < this.timeoutMs + EPSILON) {
        return
      }

      this.callbackFn()
    }, this.timeoutMs)
    localStorage.setItem(this.localStorageKey, new Date().getTime().toString())
    return forward ? forward(operation) : null
  }
}
