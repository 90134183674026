import { ApolloClient, ApolloProvider, fromPromise, HttpLink, InMemoryCache, split } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { getMainDefinition } from '@apollo/client/utilities'
import { useAuth0 } from '@auth0/auth0-react'
import { createClient, Client as GraphQLWsClient } from 'graphql-ws'
import { isNil } from 'lodash'
import React, { useMemo, useRef } from 'react'

// @ts-ignore
import Env from '../gen/Env'
import { ApolloTimeoutLink } from './ApolloTimeoutLink'
import { handleCurvoError, hasUnauthenticatedError } from './errorHandler'

const AUTOLOGOUT_MS = 30000 * 60 * 1000 // 30 minutes

export const ApolloClientWrapper: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const { getAccessTokenSilently, logout } = useAuth0()
  const accessTokenRef = useRef<string | undefined>()
  const subscriptionClient = React.useRef<GraphQLWsClient | undefined>()

  const client = useMemo(() => {
    if (getAccessTokenSilently && logout) {
      const getHeader = async () => {
        if (isNil(accessTokenRef.current)) {
          accessTokenRef.current = await getAccessTokenSilently()
        }
        return { Authorization: `Bearer ${accessTokenRef.current}` }
      }

      const autologoutLink = new ApolloTimeoutLink(
        () => {
          logout()
        },
        AUTOLOGOUT_MS,
        'AutoLogout',
      )

      const httpLink = autologoutLink.concat(
        new HttpLink({
          uri: Env.api,
        }),
      )

      subscriptionClient.current = createClient({
        url: Env.api.replace('http', 'ws').replace('/graphql', '/subscriptions'),
        keepAlive: 10000,
        lazy: false,
        connectionParams: getHeader,
        connectionAckWaitTimeout: 5000,
      })

      const wsLink = new GraphQLWsLink(subscriptionClient.current)

      const splitLink = split(
        ({ query }: any) => {
          const definition = getMainDefinition(query)
          return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
        },
        wsLink,
        httpLink,
      )

      const authLink = setContext(async (_, { headers }) => {
        try {
          const authHeader = await getHeader()
          return {
            headers: {
              ...headers,
              ...authHeader,
            },
          }
        } catch (error) {
          // do nothing
          console.error(error)
          logout()
        }
        return {
          headers,
        }
      })

      const errorLink = onError(error => {
        if (hasUnauthenticatedError(error)) {
          const { operation, forward } = error
          return fromPromise(
            getAccessTokenSilently().catch(getAccessTokenErr => {
              // Handle token refresh errors e.g clear stored tokens, redirect to login
              console.error(getAccessTokenErr)
              logout()
              return ''
            }),
          )
            .filter(value => Boolean(value))
            .flatMap(at => {
              accessTokenRef.current = at
              const oldHeaders = operation.getContext().headers

              // modify the operation context with a new token
              operation.setContext({
                headers: {
                  ...oldHeaders,
                  Authorization: `Bearer ${at}`,
                },
              })

              // retry the request, returning the new observable
              return forward(operation)
            })
        } else {
          console.error('Original error', error)
          handleCurvoError(error)
        }
        return
      })

      return new ApolloClient({
        link: authLink.concat(errorLink).concat(splitLink),
        cache: new InMemoryCache(),
        resolvers: {},
      })
    }
    return undefined
  }, [getAccessTokenSilently, logout])

  if (!client) {
    return <>loading...</>
  }

  return <ApolloProvider client={client}>{children}</ApolloProvider>
}
