import { gql, MutationHookOptions, QueryHookOptions, useMutation, useQuery } from '@apollo/client'
import {
  Mutation,
  MutationAddNetworkMembersArgs,
  MutationArchiveNetworkArgs,
  MutationCreateNetworkWithUsersArgs,
  MutationLeaveNetworkArgs,
  MutationRemoveNetworkMemberArgs,
  MutationResendNetworkInvitationArgs,
  MutationUpdateNetworkMemberRoleArgs,
  Query,
  QueryInitiativeNetworkArgs,
} from '../../gen/models'

const MY_NETWORK_QUERY = gql`
  query myInitiativeNetworks {
    myInitiativeNetworks {
      id
      name
      extra {
        memberCount
        isAdmin
      }
      createdAt
      updatedAt
    }
  }
`

export function useMyInitiativeNetworks(options: QueryHookOptions<Pick<Query, 'myInitiativeNetworks'>>) {
  return useQuery(MY_NETWORK_QUERY, options)
}

const INITIATIVE_NETWORK_MEMBERS = gql`
  query initiativeNetwork($id: String!) {
    initiativeNetwork(id: $id) {
      id
      name
      createdAt
      updatedAt
      members {
        id
        role
        status
        user {
          firstName
          lastName
          avatarUrl
          email
          company
        }
        userId
        createdAt
        updatedAt
      }
    }
  }
`

export function useInitiativeNetworkMembers(
  options: QueryHookOptions<Pick<Query, 'initiativeNetwork'>, QueryInitiativeNetworkArgs>,
) {
  return useQuery(INITIATIVE_NETWORK_MEMBERS, options)
}

const CREATE_NETWORK_WITH_USERS_MUTATION = gql`
  mutation createNetworkWithUsers($input: CreateNetworkWithUsersInput!) {
    createNetworkWithUsers(input: $input) {
      id
      name
    }
  }
`

export function useCreateNetworkWithUsersMutation(
  options?: MutationHookOptions<Pick<Mutation, 'createNetworkWithUsers'>, MutationCreateNetworkWithUsersArgs>,
) {
  return useMutation(CREATE_NETWORK_WITH_USERS_MUTATION, options)
}

const LEAVE_INITIATIVE_NETWORK = gql`
  mutation leaveNetwork($id: String!) {
    leaveNetwork(id: $id)
  }
`

export function useLeaveInitiativeNetworkMutation(
  options?: MutationHookOptions<Pick<Mutation, 'leaveNetwork'>, MutationLeaveNetworkArgs>,
) {
  return useMutation(LEAVE_INITIATIVE_NETWORK, options)
}

const UPDATE_NETWORK_MEMBER_ROLE = gql`
  mutation updateNetworkMemberRole($input: UpdateNetworkMemberRoleInput!) {
    updateNetworkMemberRole(input: $input) {
      id
      role
      status
      user {
        firstName
        lastName
        avatarUrl
        email
        company
      }
      userId
      createdAt
      updatedAt
    }
  }
`

export function useUpdateNetworkMemberRoleMutation(
  options?: MutationHookOptions<Pick<Mutation, 'updateNetworkMemberRole'>, MutationUpdateNetworkMemberRoleArgs>,
) {
  return useMutation(UPDATE_NETWORK_MEMBER_ROLE, options)
}

const REMOVE_NETWORK_MEMBER = gql`
  mutation removeNetworkMember($input: RemoveNetworkMemberInput!) {
    removeNetworkMember(input: $input) {
      id
      role
      status
      user {
        firstName
        lastName
        avatarUrl
        email
        company
      }
      userId
      createdAt
      updatedAt
    }
  }
`

export function useRemoveNetworkMemberMutation(
  options?: MutationHookOptions<Pick<Mutation, 'removeNetworkMember'>, MutationRemoveNetworkMemberArgs>,
) {
  return useMutation(REMOVE_NETWORK_MEMBER, options)
}

const ADD_NETWORK_MEMBERS = gql`
  mutation addNetworkMembers($input: AddNetworkMemberInput!) {
    addNetworkMembers(input: $input) {
      id
      role
    }
  }
`

export function useAddNetworkMembersMutation(
  options?: MutationHookOptions<Pick<Mutation, 'addNetworkMembers'>, MutationAddNetworkMembersArgs>,
) {
  return useMutation(ADD_NETWORK_MEMBERS, options)
}

const ARCHIVE_NETWORK = gql`
  mutation archiveNetwork($id: String!) {
    archiveNetwork(id: $id) {
      id
    }
  }
`

export function useArchiveNetworkMutation(
  options?: MutationHookOptions<Pick<Mutation, 'archiveNetwork'>, MutationArchiveNetworkArgs>,
) {
  return useMutation(ARCHIVE_NETWORK, options)
}

const RESEND_NETWORK_INVITE = gql`
  mutation resendNetworkInvitation($userNetworkId: String!) {
    resendNetworkInvitation(userNetworkId: $userNetworkId)
  }
`

export function useResendNetworkInvitationMutation(
  options?: MutationHookOptions<Pick<Mutation, 'resendNetworkInvitation'>, MutationResendNetworkInvitationArgs>,
) {
  return useMutation(RESEND_NETWORK_INVITE, options)
}
