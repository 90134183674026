import { gql, QueryHookOptions, useMutation, useQuery, MutationHookOptions } from '@apollo/client'
import {
  Mutation,
  MutationAddOfferFavoriteArgs,
  MutationArchiveOpportunityArgs,
  MutationBulkUpdateOpportunityItemsPricingTargetArgs,
  MutationCheckAndGenerateOfferItemsArgs,
  MutationCheckExistVendorArgs,
  MutationDownloadVendorOfferArgs,
  MutationExportConsolidatedReportArgs,
  MutationInviteMoreVendorsArgs,
  MutationRefreshOpportunityEnrichmentArgs,
  MutationRemoveMessageTemplateArgs,
  MutationRemoveOfferFavoriteArgs,
  MutationRemoveOpportunityItemsArgs,
  MutationReopenSubmittedOfferArgs,
  MutationResendInviteArgs,
  MutationSaveDraftOpportunityArgs,
  MutationSaveMessageTemplateArgs,
  MutationSubmitOpportunityArgs,
  MutationUnarchiveOpportunityArgs,
  MutationUpdateDraftOpportunityArgs,
  MutationUpdateMessageTemplateArgs,
  MutationUpdateOpportunityAttachmentArgs,
  MutationUpdateOpportunityItemArgs,
  Offer,
  OfferOfferCreationInsightsArgs,
  OfferOfferInsightsArgs,
  OfferScenario,
  Query,
  QueryAwardPrioritiesArgs,
  QueryOfferArgs,
  QueryOfferDetailArgs,
  QueryOffersArgs,
  QueryOpportunityCustomizationArgs,
  QueryOpportunityInvitingVendorsArgs,
  QueryOpportunityScenariosArgs,
  QueryPreviewOpportunityEmailTemplateArgs,
  UpdateOpportunityInput,
} from '../gen/models'
import { ScenarioFragment } from './scenario'
import { TierRuleFragment } from './tiers'

export const OpportunityAttachmentFragment = gql`
  fragment OpportunityAttachmentFragment on OpportunityAttachment {
    id
    name
    description
    s3Key
    createdAt
    uploadedBy
    invitingVendorIds
    vendorCustomerId
    opportunityId
    isNew
    hidden
  }
`

export const OfferFragment = gql`
  fragment OfferFragment on Offer {
    id
    name
    description
    secretCode
    dueDate
    startDate
    spendDateFrom
    spendDateTo
    spendDateRange
    parentStatus
    status
    numberSKU
    totalSpend
    totalBasket
    totalVendor
    totalOffer
    isFavorite
    opportunityEmailTemplate
    opportunityEmailSubjectTemplate
    goal
    vendorResponse
    facilityIds
    keyContactId
    user {
      id
    }
    sharedUsers {
      user {
        id
        email
      }
      type
      validationError
    }
    skipZeroQuantityItems
  }
`

export const OFFERS_QUERY = gql`
  query offers($input: OffersInput!, $pagination: Pagination, $sort: Sort) {
    offers(input: $input, pagination: $pagination, sort: $sort) {
      hits {
        ...OfferFragment
        offerBasketInsights {
          tiers {
            baskets {
              offeredTotalProjected
              savingsTotal
            }
          }
        }
      }
      totalHits
    }
  }
  ${OfferFragment}
`

export function useOffersQuery(basicOptions: QueryHookOptions<Pick<Query, 'offers'>, QueryOffersArgs>) {
  return useQuery(OFFERS_QUERY, basicOptions)
}

const OFFER_QUERY = gql`
  query offer($id: String!, $basketIds: [String!], $spendDateRange: SpendDateRangeInput) {
    offer(id: $id) {
      ...OfferFragment
      attachments {
        ...OpportunityAttachmentFragment
      }
      baskets {
        id
        name
        numItems
        opportunitiesUsedIn
        isUploadBasket
        insights {
          totalSpend
          numItems
        }
      }
      offerCreationInsights(basketIds: $basketIds, spendDateRange: $spendDateRange) {
        numItems
        totalSpend
        iqo50
        iqo75
        iqo90
        normalizedVendors
      }
      invitingVendors {
        id
        name
        email
        officer
        joinedVendorCustomerId
      }
    }
  }
  ${OpportunityAttachmentFragment}
  ${OfferFragment}
`

export function useOpportunityDraftQuery(
  basicOptions: QueryHookOptions<Pick<Query, 'offer'>, QueryOfferArgs & OfferOfferInsightsArgs>,
) {
  return useQuery(OFFER_QUERY, basicOptions)
}

const OFFER_CREATION_INSIGHT_QUERY = gql`
  query offerCreationInsights($id: String!, $basketIds: [String!], $spendDateRange: SpendDateRangeInput) {
    offer(id: $id) {
      offerCreationInsights(basketIds: $basketIds, spendDateRange: $spendDateRange) {
        numItems
        totalSpend
        iqo50
        iqo75
        iqo90
        normalizedVendors
      }
      goal
      baskets {
        id
        name
        numItems
        insights(spendDateRange: $spendDateRange) {
          totalSpend
          numItems
        }
      }
    }
  }
`

export function useOfferCreationInsightsQuery(
  basicOptions: QueryHookOptions<Pick<Query, 'offer'>, QueryOfferArgs & OfferOfferCreationInsightsArgs>,
) {
  return useQuery(OFFER_CREATION_INSIGHT_QUERY, {
    ...basicOptions,
    fetchPolicy: basicOptions.fetchPolicy || 'no-cache',
  })
}

const OFFER_ITEMS_INSIGHTS_QUERY = gql`
  query offerItemsInsights($id: String!) {
    offer(id: $id) {
      offerInsights {
        basketItemsCount {
          basketId
          itemsCount
        }
      }
    }
  }
`

export function useOfferItemsInsightsQuery(basicOptions: QueryHookOptions<Pick<Query, 'offer'>, QueryOfferArgs>) {
  return useQuery(OFFER_ITEMS_INSIGHTS_QUERY, {
    ...basicOptions,
    fetchPolicy: basicOptions.fetchPolicy || 'no-cache',
  })
}

export const OPPORTUNITY_ATTACHMENTS = gql`
  query offerDetailWithAttachments($id: String!) {
    offerDetail(id: $id) {
      id
      name
      attachments {
        ...OpportunityAttachmentFragment
      }
      invitingVendors {
        id
        name
        email
        officer
        joinedVendorCustomerId
        joinedVendorCustomer {
          id
          name
        }
      }
    }
  }
  ${OpportunityAttachmentFragment}
`

export function useOpportunityAttachmentQuery(
  basicOptions: QueryHookOptions<Pick<Query, 'offerDetail'>, QueryOfferDetailArgs>,
) {
  return useQuery(OPPORTUNITY_ATTACHMENTS, basicOptions)
}

export const OPPORTUNITY_DETAIL = gql`
  query offerDetail($id: String!) {
    offerDetail(id: $id) {
      ...OfferFragment
      baskets {
        id
        name
        insights {
          totalSpend
          numItems
        }
        createdAt
        archivedAt
      }
      offerInsights {
        numItems
        projectedSpend
        projectedIqo25
        projectedIqo50
        projectedIqo75
        projectedIqo90
        normalizedVendors
        basketItemsCount {
          basketId
          itemsCount
        }
      }
      offerBasketInsights {
        offer {
          id
          name
          status
          customer {
            id
            name
          }
        }
        tiers {
          tier {
            id
            name
          }
          baskets {
            basket {
              name
            }
            coveredItems
            totalItems
            coveredPercentage
            incumbentTotalProjected
            offeredTotalProjected
            offeredPriceScore
            savingsTotal
            savingsPercentage
          }
        }
      }
      facilities {
        facilityId
        facilityName
        spendDateFrom
        spendDateTo
      }
    }
  }
  ${OfferFragment}
`

export const SCENARIO_OPPORTUNITY_DETAIL = gql`
  query scenarioOpportunityDetail($id: String!) {
    offerDetail(id: $id) {
      ...OfferFragment
      offerInsights {
        statsByVendor {
          vendor
          totalSpend
        }
      }
      baskets {
        id
        name
        insights {
          totalSpend
          numItems
        }
        createdAt
      }
      facilities {
        facilityId
        facilityName
        spendDateFrom
        spendDateTo
      }
    }
  }
  ${OfferFragment}
`

export function useOpportunityDetailQuery(
  basicOptions: QueryHookOptions<Pick<Query, 'offerDetail'>, QueryOfferDetailArgs>,
) {
  return useQuery(OPPORTUNITY_DETAIL, basicOptions)
}

export const OPPORTUNITY_INVITING_VENDORS = gql`
  query opportunityInvitingVendors($input: IDArgs!) {
    opportunityInvitingVendors(input: $input) {
      id
      name
      email
      officer
      joinedVendorCustomerId
      joinedVendorCustomer {
        id
        name
      }
      offers {
        id
        name
        status
        timeline {
          id
          status
          createdAt
          updatedAt
        }
        tierSummary {
          tier {
            booleanOperator
            id
            name
            rules {
              ...TierRuleFragment
            }
            rebate {
              metricType
              valueFirst
              periodType
            }
          }
          itemCoverage
          totalIncumbentSpend
          totalProjectedSpend
        }
      }
    }
  }
  ${TierRuleFragment}
`

export function useOpportunityInvitingVendorsQuery(
  basicOptions: QueryHookOptions<Pick<Query, 'opportunityInvitingVendors'>, QueryOpportunityInvitingVendorsArgs>,
) {
  return useQuery(OPPORTUNITY_INVITING_VENDORS, basicOptions)
}

export const OPPORTUNITY_SCENARIOS = gql`
  query opportunityScenarios($input: IDArgs!) {
    opportunityScenarios(input: $input) {
      ...ScenarioFragment
      stats {
        totalProjectedSpend
        totalIncumbentSpend
        totalSpendAfterRebate
        savings
        estimatedRebate
        totalItems
        coveredItems
        convertedItems
        excludedItems
        uncoveredItems
        validateTiers {
          tier {
            id
            name
            customer {
              name
            }
          }
          validation
          message
        }
      }
    }
  }
  ${ScenarioFragment}
`

export function useOpportunityScenariosQuery(
  basicOptions: QueryHookOptions<Pick<Query, 'opportunityScenarios'>, QueryOpportunityScenariosArgs>,
) {
  return useQuery(OPPORTUNITY_SCENARIOS, basicOptions)
}

export function useScenarioOpportunityDetailQuery(
  basicOptions: QueryHookOptions<Pick<Query, 'offerDetail'>, QueryOfferDetailArgs>,
) {
  return useQuery(SCENARIO_OPPORTUNITY_DETAIL, basicOptions)
}

export const OPPORTUNITY_PERMISSIONS = gql`
  query opportunityPermissions($id: String!) {
    offerDetail(id: $id) {
      ...OfferFragment
    }
  }
  ${OfferFragment}
`

export function useOpportunityPermissionQuery(
  basicOptions: QueryHookOptions<Pick<Query, 'offerDetail'>, QueryOfferDetailArgs>,
) {
  return useQuery(OPPORTUNITY_PERMISSIONS, basicOptions)
}

const CREATE_OPPORTUNITY_MUTATION = gql`
  mutation saveDraftOpportunity($input: CreateOpportunityInput!) {
    saveDraftOpportunity(input: $input) {
      ...OfferFragment
    }
  }
  ${OfferFragment}
`

export function useSaveDraftOpportunityMutation() {
  return useMutation<Pick<Mutation, 'saveDraftOpportunity'>, MutationSaveDraftOpportunityArgs>(
    CREATE_OPPORTUNITY_MUTATION,
    {},
  )
}

const UPDATE_OPPORTUNITY_MUTATION = gql`
  mutation updateDraftOpportunity($input: UpdateOpportunityInput!) {
    updateDraftOpportunity(input: $input) {
      id
    }
  }
`

export function useUpdateDraftOpportunityMutation() {
  return useMutation<Pick<Mutation, 'updateDraftOpportunity'>, MutationUpdateDraftOpportunityArgs>(
    UPDATE_OPPORTUNITY_MUTATION,
    {},
  )
}

const UPDATE_OPPORTUNITY_ATTACHMENTS_MUTATION = gql`
  mutation updateDraftOpportunityAttachments($input: UpdateOpportunityInput!) {
    updateDraftOpportunity(input: $input) {
      id
      name
      attachments {
        ...OpportunityAttachmentFragment
      }
      invitingVendors {
        name
        email
        officer
        joinedVendorCustomerId
      }
    }
  }
  ${OpportunityAttachmentFragment}
`

export function useUpdateOpportunityAttachmentsMutation() {
  return useMutation<
    Pick<Mutation, 'updateDraftOpportunity'>,
    { input: Pick<UpdateOpportunityInput, 'id' | 'attachmentsUpdate'> }
  >(UPDATE_OPPORTUNITY_ATTACHMENTS_MUTATION, {})
}

const ADD_OPPORTUNITY_FAVORITE = gql`
  mutation addOfferFavorite($input: OfferFavoriteInput!) {
    addOfferFavorite(input: $input) {
      id
    }
  }
`

export function useAddOpportunityFavoriteMutation() {
  return useMutation<Pick<Mutation, 'addOfferFavorite'>, MutationAddOfferFavoriteArgs>(ADD_OPPORTUNITY_FAVORITE)
}

const REMOVE_OPPORTUNITY_FAVORITE = gql`
  mutation removeOfferFavorite($input: OfferFavoriteInput!) {
    removeOfferFavorite(input: $input)
  }
`

export function useRemoveOpportunityFavoriteMutation() {
  return useMutation<Pick<Mutation, 'removeOfferFavorite'>, MutationRemoveOfferFavoriteArgs>(
    REMOVE_OPPORTUNITY_FAVORITE,
  )
}

export const MESSAGE_TEMPLATES_QUERY = gql`
  query messageTemplates {
    messageTemplates {
      data {
        id
        name
        message
        subject
        isDefault
      }
    }
  }
`

export function useMessageTemplatesQuery(basicOptions: QueryHookOptions<Pick<Query, 'messageTemplates'>>) {
  return useQuery(MESSAGE_TEMPLATES_QUERY, basicOptions)
}

const ADD_MESSAGE_TEMPLATE = gql`
  mutation saveMessageTemplate($input: CreateMessageTemplateInput!) {
    saveMessageTemplate(input: $input) {
      id
      name
      message
      subject
    }
  }
`

export function useAddMessageTemplateMutation() {
  return useMutation<Pick<Mutation, 'saveMessageTemplate'>, MutationSaveMessageTemplateArgs>(ADD_MESSAGE_TEMPLATE)
}

const UPDATE_MESSAGE_TEMPLATE = gql`
  mutation updateMessageTemplate($input: UpdateMessageTemplateInput!) {
    updateMessageTemplate(input: $input) {
      id

      name
      message
      subject
    }
  }
`

export function useUpdateMessageTemplateMutation() {
  return useMutation<Pick<Mutation, 'updateMessageTemplate'>, MutationUpdateMessageTemplateArgs>(
    UPDATE_MESSAGE_TEMPLATE,
  )
}

const REMOVE_MESSAGE_TEMPLATE = gql`
  mutation removeMessageTemplate($input: IDArgs!) {
    removeMessageTemplate(input: $input)
  }
`

export function useRemoveMessageTemplateMutation() {
  return useMutation<Pick<Mutation, 'removeMessageTemplate'>, MutationRemoveMessageTemplateArgs>(
    REMOVE_MESSAGE_TEMPLATE,
  )
}
const PREVIEW_EMAIL_TEMPLATE = gql`
  query previewOpportunityEmailTemplate($input: OpportunityEmailTemplatePreviewInput!) {
    previewOpportunityEmailTemplate(input: $input)
  }
`

export function usePreviewOpportunityEmailTemplate(
  basicOptions: QueryHookOptions<
    Pick<Query, 'previewOpportunityEmailTemplate'>,
    QueryPreviewOpportunityEmailTemplateArgs
  >,
) {
  return useQuery(PREVIEW_EMAIL_TEMPLATE, basicOptions)
}

const OPPORTUNITY_CUSTOMIZATION = gql`
  query opportunityCustomization($input: OpportunityCustomizationInput!) {
    opportunityCustomization(input: $input) {
      taskId
    }
  }
`

export function useOpportunityCustomizationQuery(
  basicOptions: QueryHookOptions<Pick<Query, 'opportunityCustomization'>, QueryOpportunityCustomizationArgs>,
) {
  return useQuery(OPPORTUNITY_CUSTOMIZATION, basicOptions)
}

const SUBMIT_OPPORTUNITY = gql`
  mutation submitOpportunity($input: IDArgs!) {
    submitOpportunity(input: $input) {
      id
    }
  }
`

export function useSubmitOpportunity() {
  return useMutation<Pick<Mutation, 'submitOpportunity'>, MutationSubmitOpportunityArgs>(SUBMIT_OPPORTUNITY)
}

const DOWNLOAD_VENDOR_OFFER = gql`
  mutation downloadVendorOffer($input: DownloadVendorOfferInput!) {
    downloadVendorOffer(input: $input)
  }
`

export function useDownloadOffer() {
  return useMutation<Pick<Mutation, 'downloadVendorOffer'>, MutationDownloadVendorOfferArgs>(DOWNLOAD_VENDOR_OFFER)
}

const EXPORT_CONSOLIDATED_REPORT = gql`
  mutation exportConsolidatedReport($id: String!) {
    exportConsolidatedReport(id: $id)
  }
`

export function useExportConsolidatedReport() {
  return useMutation<Pick<Mutation, 'exportConsolidatedReport'>, MutationExportConsolidatedReportArgs>(
    EXPORT_CONSOLIDATED_REPORT,
  )
}

const REOPEN_SUBMITTED_OFFER = gql`
  mutation reopenSubmittedOffer($input: ReopenOfferInput!) {
    reopenSubmittedOffer(input: $input)
  }
`

export function useReopenSubmittedOffer() {
  return useMutation<Pick<Mutation, 'reopenSubmittedOffer'>, MutationReopenSubmittedOfferArgs>(REOPEN_SUBMITTED_OFFER)
}

const RESEND_INVITE = gql`
  mutation resendInvite($input: ResendInviteInput!) {
    resendInvite(input: $input)
  }
`

export function useResendInvite() {
  return useMutation<Pick<Mutation, 'resendInvite'>, MutationResendInviteArgs>(RESEND_INVITE)
}

const INVITE_MORE_VENDORS = gql`
  mutation inviteMoreVendors($input: InviteMoreVendorsInput!) {
    inviteMoreVendors(input: $input) {
      id
    }
  }
`

export function useInviteMoreVendors() {
  return useMutation<Pick<Mutation, 'inviteMoreVendors'>, MutationInviteMoreVendorsArgs>(INVITE_MORE_VENDORS)
}

const CHECK_EXIST_VENDOR = gql`
  mutation checkExistVendor($input: CheckExistVendorInput!) {
    checkExistVendor(input: $input)
  }
`

export function useCheckExistVendorMutation() {
  return useMutation<Pick<Mutation, 'checkExistVendor'>, MutationCheckExistVendorArgs>(CHECK_EXIST_VENDOR)
}

const AWARD_PRIORITIES = gql`
  query awardPriorities($input: AwardPrioritiesInput!) {
    awardPriorities(input: $input) {
      data {
        type
        isDisabled
        basketPriority {
          id
          name
        }
        vendorPriority {
          id
          name
        }
      }
    }
  }
`

export function useAwardPrioritiesQuery(
  basicOptions: QueryHookOptions<Pick<Query, 'awardPriorities'>, QueryAwardPrioritiesArgs>,
) {
  return useQuery(AWARD_PRIORITIES, basicOptions)
}

const OPPORTUNITY_WITH_CHILD_OFFER_DETAIL_QUERY = gql`
  query opportunityWithChildOfferDetailQuery($opportunityId: String!, $scenarioId: String!) {
    offerDetail(id: $opportunityId) {
      ...OfferFragment
      offerBasketInsights {
        offer {
          id
          name
          customer {
            id
            name
          }
          customerId
        }
        tiers {
          tier {
            id
            name
            booleanOperator
            rules {
              id
              valueFirst
              valueSecond
              operator
              periodType
              ruleType
              metricType
            }
          }
          baskets {
            basket {
              id
              name
              insights {
                numItems
                totalSpend
              }
            }
            coveredItems
            totalItems
            coveredPercentage
            incumbentTotalProjected
            offeredTotalProjected
            offeredPriceScore
            savingsTotal
            savingsPercentage
          }
        }
      }
    }
    offerScenario(id: $scenarioId) {
      ...ScenarioFragment
      tiers {
        id
        name
        customerId
      }
      offers {
        id
        name
        customerId
        tiers {
          id
          name
        }
        customer {
          id
          name
        }
      }
      isAwarded
      stats {
        totalSpendAfterRebate
        totalIncumbentSpend
        totalIncumbentUnits
        savings
        estimatedRebate
        totalItems
        coveredItems
        convertedItems
        excludedItems
        uncoveredItems
        tierStats {
          totalProjectedSpend
          coveredItems
          coveredUnits
          coveredIncumbentSpend
          tierId
        }
        basketTierStats {
          totalProjectedSpend
          coveredItems
          totalItems
          coveredUnits
          coveredIncumbentSpend
          pricingScore
          tierId
          basketId
        }
        validateTiers {
          tier {
            id
            name
            customer {
              name
            }
          }
          validation
          message
        }
      }
    }
  }
  ${OfferFragment}
  ${ScenarioFragment}
`

export function useOpportunityWithChildOffersDetailQuery(
  queryParams: QueryHookOptions<
    { offerDetail: Offer; offerScenario: OfferScenario },
    { opportunityId: string; scenarioId: string }
  >,
) {
  return useQuery(OPPORTUNITY_WITH_CHILD_OFFER_DETAIL_QUERY, queryParams)
}

const GENERATE_OPPORTUNITY_ITEMS_MUTATION = gql`
  mutation checkAndGenerateOfferItems($id: String!, $skipZeroQuantityItems: Boolean!) {
    checkAndGenerateOfferItems(id: $id, skipZeroQuantityItems: $skipZeroQuantityItems) {
      ...OfferFragment
    }
  }
  ${OfferFragment}
`

export function useCheckAndGenerateOpportunityItemsMutation() {
  return useMutation<Pick<Mutation, 'checkAndGenerateOfferItems'>, MutationCheckAndGenerateOfferItemsArgs>(
    GENERATE_OPPORTUNITY_ITEMS_MUTATION,
    {},
  )
}

const BULK_UPDATE_OPPORTUNITY_ITEMS_PRICING_TARGET_MUTATION = gql`
  mutation bulkUpdateOpportunityItemsPricingTarget($input: BulkUpdateOpportunityItemsPricingTargetInput!) {
    bulkUpdateOpportunityItemsPricingTarget(input: $input) {
      id
      pricingTarget
    }
  }
`

export function useBulkUpdateOpportunityItemsPricingTargetMutation() {
  return useMutation<
    Pick<Mutation, 'bulkUpdateOpportunityItemsPricingTarget'>,
    MutationBulkUpdateOpportunityItemsPricingTargetArgs
  >(BULK_UPDATE_OPPORTUNITY_ITEMS_PRICING_TARGET_MUTATION, {})
}

const ARCHIVE_OPPORTUNITY = gql`
  mutation archiveOpportunity($id: String!) {
    archiveOpportunity(id: $id) {
      id
    }
  }
`

export function useArchiveOpportunityMutation() {
  return useMutation<Pick<Mutation, 'archiveOpportunity'>, MutationArchiveOpportunityArgs>(ARCHIVE_OPPORTUNITY)
}

const UNARCHIVE_OPPORTUNITY = gql`
  mutation unarchiveOpportunity($id: String!) {
    unarchiveOpportunity(id: $id) {
      id
    }
  }
`

export function useUnarchiveOpportunityMutation() {
  return useMutation<Pick<Mutation, 'unarchiveOpportunity'>, MutationUnarchiveOpportunityArgs>(UNARCHIVE_OPPORTUNITY)
}

export const GET_ATTACHMENT_DOWNLOAD = gql`
  query offerWithAttachements($id: String!, $attachmentId: String!) {
    offer(id: $id) {
      attachment(id: $attachmentId) {
        ...OpportunityAttachmentFragment
        base64
      }
    }
  }
  ${OpportunityAttachmentFragment}
`

export function useGetAttachmentBase64Query(
  options: QueryHookOptions<Pick<Query, 'offer'>, { id: string; attachmentId: string }>,
) {
  return useQuery(GET_ATTACHMENT_DOWNLOAD, options)
}

const UPDATE_SINGLE_OPPORTUNITY_ATTACHMENT = gql`
  mutation updateOpportunityAttachment($input: SingleOpportunityAttachmentInput!) {
    updateOpportunityAttachment(input: $input) {
      ...OpportunityAttachmentFragment
    }
  }
  ${OpportunityAttachmentFragment}
`

export function useUpdateSingleOpportunityAttachment(
  options?: MutationHookOptions<Pick<Mutation, 'updateOpportunityAttachment'>, MutationUpdateOpportunityAttachmentArgs>,
) {
  return useMutation(UPDATE_SINGLE_OPPORTUNITY_ATTACHMENT, options)
}

const UPDATE_OPPORTUNITY_ITEM = gql`
  mutation updateOpportunityItem($input: UpdateOpportunityItemInput!) {
    updateOpportunityItem(input: $input) {
      id
      itemUoM
      itemConversion
      item {
        vendor
        vendorItem
        manufacturer
        normalizedManufacturer
        manufacturerItem
        description
        gicName
        typeOneName
        typeTwoName
        gmdn
      }
      submittedItem {
        vendor
        vendorItem
        manufacturer
        manufacturerItem
        description
      }
      facilityId
      facilityName
      unitprice
      quantity
      itemUoM
      itemConversion
      curvo25
      curvo50
      curvo75
      curvo90
      pricingTarget
    }
  }
`

export function useUpdateOpportunityItemMutation(
  options?: MutationHookOptions<Pick<Mutation, 'updateOpportunityItem'>, MutationUpdateOpportunityItemArgs>,
) {
  return useMutation(UPDATE_OPPORTUNITY_ITEM, options)
}

const REMOVE_OPPORTUNITY_ITEM = gql`
  mutation removeOpportunityItems($ids: [String!]!) {
    removeOpportunityItems(ids: $ids) {
      id
    }
  }
`

export function useRemoveOpportunityItemsMutation(
  options?: MutationHookOptions<Pick<Mutation, 'removeOpportunityItems'>, MutationRemoveOpportunityItemsArgs>,
) {
  return useMutation(REMOVE_OPPORTUNITY_ITEM, options)
}

const REFRESH_OPPORTUNITY_ENRICHMENT = gql`
  mutation refreshOpportunityEnrichment($id: String!) {
    refreshOpportunityEnrichment(id: $id) {
      id
    }
  }
`

export function useRefreshOpportunityEnrichmentMutation(
  options?: MutationHookOptions<
    Pick<Mutation, 'refreshOpportunityEnrichment'>,
    MutationRefreshOpportunityEnrichmentArgs
  >,
) {
  return useMutation(REFRESH_OPPORTUNITY_ENRICHMENT, options)
}
