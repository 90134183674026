import React, { useState, useEffect } from 'react'
import { Form, Modal, message } from 'antd'
import { ModalProps } from 'antd/lib/modal'
import { pick } from 'lodash'
import {
  CreateCustomerInput,
  UpdateCustomerInput,
  useUpdateCustomerMutation,
  Customer,
  useCreateCustomerMutation,
  QueryCustomersArgs,
} from '../../../api'
import { CreateEditClientForm } from '../forms/CreateEditCustomerForm'

type Props = ModalProps & {
  onSuccess?: (customer: Customer) => any
  onError?: (error: Error) => any
  isEditing?: boolean
  customer?: Customer
  queryArgs?: QueryCustomersArgs
}

export const CreateEditCustomerModal: React.FC<Props> = ({
  isEditing,
  customer,
  onSuccess,
  onError,
  queryArgs,
  ...props
}) => {
  const [newClientInput, setNewClientInput] = useState<Partial<CreateCustomerInput | UpdateCustomerInput>>({})
  const [form] = Form.useForm()
  const [createCustomer] = useCreateCustomerMutation(queryArgs)
  const [editCustomer] = useUpdateCustomerMutation()

  const handleOnOk = async () => {
    try {
      await form.validateFields()
    } catch (e) {
      return
    }

    const promise = isEditing
      ? editCustomer({ variables: { input: newClientInput as UpdateCustomerInput } }).then(result => {
          if (result.data) {
            if (onSuccess) {
              onSuccess(result.data.updateCustomer)
            }
            message.success('Updated customer')
          }
        })
      : createCustomer({ variables: { input: newClientInput as CreateCustomerInput } }).then(result => {
          if (result.data) {
            if (onSuccess) {
              onSuccess(result.data.createCustomer)
            }
            message.success('Created new customer')
          }
        })
    promise.catch(e => {
      message.error(e.message)
      if (onError) {
        onError(e)
      }
    })
  }

  useEffect(() => {
    if (customer) {
      setNewClientInput(old => ({
        ...old,
        ...pick<Customer>(customer, [
          'id',
          'name',
          'address',
          'medicareId',
          'maxUser',
          'address2',
          'city',
          'state',
          'zipCode',
          'website',
          'phoneNumber',
          'geoCode',
        ]),
      }))
      form.setFieldsValue(customer)
    }
  }, [customer, form])

  return (
    <Modal title={isEditing ? 'Update client' : 'Create New Client'} {...props} onOk={handleOnOk}>
      <CreateEditClientForm
        form={form}
        onChange={v => {
          setNewClientInput({
            ...newClientInput,
            ...v,
          })
        }}
      />
    </Modal>
  )
}
