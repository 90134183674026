import { ArrowLeftOutlined } from '@ant-design/icons/lib'
import { Button, Col, Layout, Popconfirm, Row, Skeleton, Space, Typography, message } from 'antd'
import { BreadcrumbProps } from 'antd/es/breadcrumb'
import moment from 'moment'
import React, { useCallback, useContext, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link, useParams } from 'react-router-dom'
import styled from 'styled-components'
import {
  Customer,
  MeContext,
  Organization,
  useActivateCustomerMutation,
  useCustomerQuery,
  useDeactivateCustomerMutation,
} from '../../../api'
import { useRebuildQuicksightPermissionsMutation } from '../../../api/hooks/dashboards'
import { useOrganizationTypesQuery } from '../../../api/hooks/organization-types'
import { breadcrumbItemRender } from '../../../utils'
import { Card } from '../../components/Card'
import { DraggableSidebar } from '../../components/DraggableSidebar'
import { FiltersBar, FiltersBarConfig } from '../../components/FiltersBar'
import { OrganizationList } from '../../components/OrganizationCommon'
import { OrganizationsTree } from '../../components/OrganizationsTree'
import { PermissionPlaceholder } from '../../components/PermissionPlaceholder'
import { UserWithRole } from '../../components/UserCommon'
import { UserManagementModal } from '../../components/UserManagementModal'
import { BaseManagePageLayout } from '../BaseManagePageLayout'
import { OrganizationUsersModal } from '../Organization/OrganizationUsersModal'
import { UpdateOrganizationModal } from '../Organization/UpdateOrganizationModal'
import { CreateEditCustomerModal } from './CreateEditCustomerModal'
import { AddOrganizationToCustomerModal } from './forms/AddOrganizationToCustomerModal'

export const ClientDetailPage: React.FC = () => {
  const { customerId } = useParams<{ customerId: string }>()
  const { data: meData } = useContext(MeContext)

  const [organizationUsersModal, setOrganizationUsersModal] = useState<{
    visible: boolean
    selectedOrg?: Organization
  }>({ visible: false, selectedOrg: undefined })
  const [showNewOrganizationForm, setShowNewOrganizationForm] = useState(false)
  const [showUpdateCustomerForm, setShowUpdateCustomerForm] = useState(false)
  const [showEditCustomerForm, setShowEditCustomerForm] = useState(false)
  const [filtersSelections, setFiltersSelections] = useState<any>({})
  const [userManagementModalVisible, setUserManagementModalVisible] = useState(false)
  const [showUpdateOrganizationModal, setShowUpdateOrganizationModal] = useState<{
    visible: boolean
    org?: Organization
  }>({
    visible: false,
    org: undefined,
  })

  const { data, loading } = useCustomerQuery({ variables: { input: { id: customerId! } } })
  const [deactivateCustomer] = useDeactivateCustomerMutation()
  const [activateCustomer] = useActivateCustomerMutation()
  const organizationTypesRequest = useOrganizationTypesQuery({ variables: { input: {} } })
  const [rebuildQuicksightPermissions, { loading: rebuilding }] = useRebuildQuicksightPermissionsMutation()

  const customer = data && data.customer
  const organizationTypes =
    organizationTypesRequest && organizationTypesRequest.data ? organizationTypesRequest.data.organizationTypes : []

  const organizations = (data && data.customer && data.customer.organizations) || []
  const topUsers = (data && data.customer && data.customer.topUsers) || []

  const handleRebuildQuicksightPermissions = useCallback(async () => {
    if (!customerId) {
      return
    }

    const resp = await rebuildQuicksightPermissions({
      variables: { customerId },
    })
    if (resp.data?.rebuildQuicksightPermissions) {
      message.success('Rebuilding user quicksight permissions has been queued.')
    } else {
      message.error('Failed to rebuild user quicksight permissions. Please try again.')
    }
  }, [rebuildQuicksightPermissions, customerId])

  const pageExtra = (
    <PermissionPlaceholder permissionTarget="write">
      <Space>
        <Popconfirm
          title={'Are you sure you want to rebuild QS Permissions?'}
          okText="Yes"
          cancelText="No"
          onConfirm={() => handleRebuildQuicksightPermissions()}>
          <Button key="rebuild-qs-permissions" loading={rebuilding}>
            Rebuild QS Permissions
          </Button>
        </Popconfirm>
        <Button
          style={{ color: (customer?.isActive && 'red') || '' }}
          onClick={() => {
            if (customer) {
              const params = { variables: { input: { id: customer.id } } }
              customer.isActive ? deactivateCustomer(params) : activateCustomer(params)
            }
          }}>
          {customer?.isActive ? 'Deactivate' : 'Activate'}
        </Button>
        <Button
          onClick={() => {
            setShowEditCustomerForm(true)
          }}>
          Edit Customer
        </Button>
        <Button type="primary" onClick={() => setShowNewOrganizationForm(true)}>
          New Organization
        </Button>
      </Space>
    </PermissionPlaceholder>
  )

  const breadcrumbOptions: BreadcrumbProps = {
    itemRender: breadcrumbItemRender,
    routes: customer
      ? [
          {
            path: meData?.me.isAdmin ? '/manage/customer' : '',
            breadcrumbName: 'All Customers',
          },
          {
            path: `${customer.id}`,
            breadcrumbName: customer.name,
          },
        ]
      : [],
  }

  const filters: FiltersBarConfig[] = [
    {
      name: 'organizationType',
      label: 'Organization Type',
      options: organizationTypes.map(orgType => ({ value: orgType.id, label: orgType.name })),
    },
  ]

  return (
    <>
      <Helmet>
        <title>{customer?.name ?? 'Customer'}</title>
      </Helmet>
      <BaseManagePageLayout
        title={(customer && customer.name) || 'Customer'}
        extra={pageExtra}
        breadcrumb={breadcrumbOptions}
        subTitle={`Customer ∙ Updated ${customer ? moment(customer.updatedAt).calendar() : ''}`}
        sidebar={
          <DraggableSidebar
            backLink={
              <PermissionPlaceholder permissionTarget="curvoAdmin">
                <BackLink to="/manage/customer">
                  <ArrowLeftOutlined /> All Clients
                </BackLink>
              </PermissionPlaceholder>
            }
            title={customer?.name || ''}>
            {customer ? (
              <>
                <OrganizationsTree customerId={customer.id} onNewOrgClick={() => setShowNewOrganizationForm(true)} />
              </>
            ) : (
              ''
            )}
          </DraggableSidebar>
        }>
        <Row gutter={[16, 16]}>
          <Col span={16}>
            {loading || !customer ? (
              <Skeleton active={true} />
            ) : (
              <CustomerInfoCard customer={customer} onUpdateButtonClick={() => setShowUpdateCustomerForm(true)} />
            )}
          </Col>
          <Col span={8}>
            {loading ? (
              <Skeleton active={true} />
            ) : (
              <Card title="Top Users" width="100%" height="100%">
                <Layout style={{ background: '#fff' }}>
                  <UserListSpace direction="vertical" size={24}>
                    {topUsers.map(user => (
                      <UserWithRole user={user} key={user.id} />
                    ))}
                  </UserListSpace>
                  <AllUsersLink to={`/manage/users?customerId=${customerId}`}>View all users</AllUsersLink>
                </Layout>
              </Card>
            )}
          </Col>
        </Row>
        <OrganizationsHeader>
          <Col span={12}>
            <Typography.Title level={4}>
              All Organizations ({customer && customer.organizations.length})
            </Typography.Title>
          </Col>
          <Col span={12}>
            <PermissionPlaceholder permissionTarget="write" customerId={customerId}>
              <Button
                style={{ float: 'right' }}
                key="add-organization"
                type="default"
                onClick={() => setShowNewOrganizationForm(true)}>
                Add Organization
              </Button>
            </PermissionPlaceholder>
          </Col>
        </OrganizationsHeader>
        <FiltersBar
          filters={filters}
          onChange={(selections: any) => setFiltersSelections(selections)}
          searchItem="organizations"
          searchDebounceTimeout={50}
        />
        {loading || !customer ? (
          <Skeleton active={true} />
        ) : (
          <OrganizationList
            customer={customer}
            organizations={organizations
              .filter(organization => {
                return organization.name
                  .toLowerCase()
                  .includes(filtersSelections.searchText ? filtersSelections.searchText.toLowerCase() : '')
              })
              .filter(organization => {
                return (
                  !filtersSelections.organizationType ||
                  filtersSelections.organizationType === organization.organizationTypeId
                )
              })}
            onAddNewOrganization={() => {
              setShowNewOrganizationForm(true)
            }}
            onEditOrganization={(org: Organization) => {
              setShowUpdateOrganizationModal({ visible: true, org })
            }}
            onAddUser={(org: Organization) => {
              setOrganizationUsersModal({ visible: true, selectedOrg: org })
            }}
          />
        )}
        {setShowUpdateCustomerForm && (
          <CreateEditCustomerModal
            customer={customer}
            visible={showUpdateCustomerForm}
            isEditing
            onCancel={() => setShowUpdateCustomerForm(false)}
            onSuccess={() => {
              setShowUpdateCustomerForm(false)
            }}
          />
        )}
        {customer && (
          <AddOrganizationToCustomerModal
            customer={customer}
            visible={showNewOrganizationForm}
            onCancel={() => setShowNewOrganizationForm(false)}
            onFinished={() => {
              setShowNewOrganizationForm(false)
            }}
          />
        )}
        <CreateEditCustomerModal
          isEditing={true}
          customer={customer}
          visible={showEditCustomerForm}
          onCancel={() => setShowEditCustomerForm(false)}
          onSuccess={() => {
            setShowEditCustomerForm(false)
          }}
        />
        <PermissionPlaceholder permissionTarget="curvoAdmin">
          <UserManagementModal
            visible={userManagementModalVisible}
            onCancel={() => setUserManagementModalVisible(false)}
            defaultCustomerId={customerId}
            onFinished={() => {
              setUserManagementModalVisible(false)
            }}
          />
        </PermissionPlaceholder>
        {customer && (
          <UpdateOrganizationModal
            customer={customer}
            visible={showUpdateOrganizationModal.visible}
            onCancel={() => setShowUpdateOrganizationModal({ visible: false, org: undefined })}
            organization={showUpdateOrganizationModal.org}
            onSuccess={() => {
              setShowUpdateOrganizationModal({ visible: false, org: undefined })
            }}
          />
        )}
        {organizationUsersModal.selectedOrg && (
          <OrganizationUsersModal
            customerId={customerId!}
            organization={organizationUsersModal.selectedOrg}
            visible={organizationUsersModal.visible}
            onCancel={() => setOrganizationUsersModal({ visible: false, selectedOrg: undefined })}
            onSuccess={() => setOrganizationUsersModal({ visible: false, selectedOrg: undefined })}
          />
        )}
      </BaseManagePageLayout>
    </>
  )
}

const CustomerInfoCard: React.FC<{ customer?: Customer; onUpdateButtonClick: () => any }> = ({
  customer,
  onUpdateButtonClick,
}) => {
  const clientInfoExtra = [
    <PermissionPlaceholder permissionTarget="write" customerId={customer?.id}>
      <Button onClick={onUpdateButtonClick}>Update</Button>
    </PermissionPlaceholder>,
  ]

  return (
    <Card title="Client Info" width="100%" extra={clientInfoExtra} height="100%">
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <InformationLabel>Address</InformationLabel>
          <InformationData>{customer && customer.address}</InformationData>
        </Col>
        <Col span={12}>
          <InformationLabel>Total Users</InformationLabel>
          <InformationData>{(customer && customer.totalUsers) || '0'}</InformationData>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <InformationLabel>Phone Number</InformationLabel>
          <InformationData>{(customer && customer.phoneNumber) || 'N/A'}</InformationData>
        </Col>
      </Row>
      {customer && customer.website && (
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <InformationLabel>Website</InformationLabel>
            <InformationData>
              <a href={customer.website} target="_blank">
                {customer.website}
              </a>
            </InformationData>
          </Col>
        </Row>
      )}
    </Card>
  )
}

const InformationLabel = styled.div`
  text-transform: uppercase;
  font-size: 10px;
`

const InformationData = styled.div`
  font-size: 14px;
  font-weight: 600;
`

const UserListSpace = styled(Space)`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
`

const BackLink = styled(Link)`
  font-weight: bold;
`

const AllUsersLink = styled(Link)`
  margin-top: 30px;
`

const OrganizationsHeader = styled(Row)`
  margin-bottom: 10px;
`
