import { gql, QueryHookOptions, useQuery } from '@apollo/client'
import { Query } from '../gen/models'

const QUICKSIGHT_GET_GROUPS = gql`
  query quickSightGetGroups {
    quickSightGetGroups
  }
`
export function useQuickSightGetGroups(baseOptions?: QueryHookOptions<Pick<Query, 'quickSightGetGroups'>>) {
  return useQuery(QUICKSIGHT_GET_GROUPS, baseOptions)
}
