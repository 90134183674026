import { UploadOutlined } from '@ant-design/icons'
import { Button, Col, message, Row } from 'antd'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'
import { useQueryUser, useResetPassword, useUpdateMeMutation } from '../../api/hooks/users'
import { S3UploadButton } from '../../common/components/UploadArea'
import { UserAvatar } from '../components/UserAvatar'
import { UserEmailModal } from '../components/UserEmailModal'
import { BaseManagePageLayout } from '../Manage/BaseManagePageLayout'
import { ProfileInformation } from './ProfileInformation'

const UserBlock = styled.div`
  background-color: white;
  padding: 33px 25px;
`

const SettingsAction = styled(Button)`
  display: block;
  width: 100%;
  margin-bottom: 20px;
`

const UserData = styled.section`
  padding: 1em;
  text-align: center;
`

export const Profile: React.FC = () => {
  const [userEmailModalVisible, setUserEmailModalVisible] = useState(false)
  const [resetPasswordMutation] = useResetPassword()

  const [updateMe] = useUpdateMeMutation()
  const [uploading, setUploading] = useState(false)
  const [updatingAvatar, setUpdatingAvatar] = useState(false)

  const { id } = useParams<{ id: string }>()
  const { data } = useQueryUser({ variables: { id: id! } })
  const user = data && data.user

  const onChangePasswordClick = () => {
    resetPasswordMutation()
      .then(() => {
        message.success('We sent you an e-mail with a secure link to set your new password')
      })
      .catch(e => {
        message.error(e.message)
      })
  }

  return user ? (
    <>
      <Helmet>
        <title>My Profile</title>
      </Helmet>
      <BaseManagePageLayout title="My Profile">
        <Row gutter={26}>
          <Col span={5}>
            <UserBlock>
              <UserData>
                <UserAvatar />
                <h2>{user.firstName + ' ' + user.lastName}</h2>
                <h4>{user.email}</h4>
                <S3UploadButton
                  fileType="image"
                  showUploadList={false}
                  disabled={uploading || updatingAvatar}
                  onChange={info => setUploading(info.file.status === 'uploading')}
                  onUploadSuccess={async (file: any) => {
                    setUpdatingAvatar(true)
                    await updateMe({
                      variables: {
                        input: {
                          avatarUrl: file.s3Key,
                        },
                      },
                    }).finally(() => setUpdatingAvatar(false))
                  }}>
                  <Button loading={uploading || updatingAvatar}>
                    {uploading || updatingAvatar ? <></> : <UploadOutlined />} Update Picture
                  </Button>
                </S3UploadButton>
              </UserData>
            </UserBlock>
          </Col>
          <Col span={12}>
            <UserBlock>
              <h2>Profile Information</h2>
              <br />
              <ProfileInformation user={user} />
            </UserBlock>
          </Col>
          <Col span={6}>
            <UserBlock>
              <h2>Settings</h2>
              <SettingsAction onClick={() => setUserEmailModalVisible(true)}>Change Email</SettingsAction>
              <SettingsAction onClick={onChangePasswordClick}>Change Password</SettingsAction>
            </UserBlock>
          </Col>
        </Row>
        <UserEmailModal
          user={user}
          visible={userEmailModalVisible}
          onCancel={() => setUserEmailModalVisible(false)}
          onSuccess={() => setUserEmailModalVisible(false)}
        />
      </BaseManagePageLayout>
    </>
  ) : (
    <></>
  )
}
