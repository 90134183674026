import { gql, MutationHookOptions, QueryHookOptions, useMutation, useQuery } from '@apollo/client'
import {
  Mutation,
  MutationAwardScenarioArgs,
  MutationBulkUpdateScenarioItemsArgs,
  MutationCreateOfferScenarioArgs,
  MutationResetOfferScenarioArgs,
  Query,
  QueryOfferScenarioArgs,
  MutationDeleteScenarioArgs,
  MutationUpdateScenarioItemByOfferArgs,
  QueryScenarioTierConversionBreakdownArgs,
  MutationUpdateCommitmentArgs,
} from '../gen/models'

export const ScenarioFragment = gql`
  fragment ScenarioFragment on OfferScenario {
    id
    name
    isAwarded
    awardedAt
    isInvalid
  }
`

const BULK_UPDATE_SCENARIO_ITEMS = gql`
  mutation bulkUpdateScenarioItems($input: BulkUpdateOfferScenarioItemsInput!) {
    bulkUpdateScenarioItems(input: $input)
  }
`

export function useBulkUpdateScenarioItemsMutation() {
  return useMutation<Pick<Mutation, 'bulkUpdateScenarioItems'>, MutationBulkUpdateScenarioItemsArgs>(
    BULK_UPDATE_SCENARIO_ITEMS,
  )
}

const CREATE_SCENARIO = gql`
  mutation createOfferScenario($input: CreateOfferScenarioInput!) {
    createOfferScenario(input: $input) {
      id
    }
  }
`

export function useCreateScenarioMutation() {
  return useMutation<Pick<Mutation, 'createOfferScenario'>, MutationCreateOfferScenarioArgs>(CREATE_SCENARIO)
}

const SCENARIO_DETAIL = gql`
  query offerScenario($id: String!) {
    offerScenario(id: $id) {
      id
      name
      enableCommitment
      tiers {
        id
        name
        offerId
        readableRules(skipParent: true)
      }
      offers {
        id
        name
        tiers {
          id
          name
        }
        customer {
          id
          name
        }
      }
      isAwarded
      awardedAt
      stats {
        totalProjectedSpend
        totalSpendAfterRebate
        totalIncumbentSpend
        totalIncumbentUnits
        savings
        estimatedRebate
        totalItems
        coveredItems
        convertedItems
        excludedItems
        excludedSpend
        uncoveredItems
        pricingTarget
        tierStats {
          totalProjectedSpend
          coveredItems
          coveredUnits
          coveredIncumbentSpend
          remainingTotalProjectedSpend
          remainingTotalProjectedSavings
          remainingCoveredUnits
          remainingIncumbentUnits
          remainingCurrentSpend
          remainingIncumbentSavings
          excludedItems
          tierId
        }
        basketTierStats {
          totalProjectedSpend
          coveredItems
          totalItems
          coveredUnits
          coveredIncumbentSpend
          pricingScore
          tierId
          basketId
        }
        validateTiers {
          tier {
            id
            name
            customer {
              name
            }
          }
          validation
          message
        }
      }
    }
  }
`

export function useScenarioDetailQuery(
  options: QueryHookOptions<Pick<Query, 'offerScenario'>, QueryOfferScenarioArgs>,
) {
  return useQuery(SCENARIO_DETAIL, options)
}

const RESET_SCENARIO = gql`
  mutation resetOfferScenario($id: String!) {
    resetOfferScenario(id: $id) {
      ...ScenarioFragment
    }
  }
  ${ScenarioFragment}
`

export function useResetScenarioMutation() {
  return useMutation<Pick<Mutation, 'resetOfferScenario'>, MutationResetOfferScenarioArgs>(RESET_SCENARIO)
}

const AWARD_SCENARIO = gql`
  mutation awardScenario($input: AwardScenarioInput!) {
    awardScenario(input: $input) {
      id
    }
  }
`

export function useAwardScenarioMutation() {
  return useMutation<Pick<Mutation, 'awardScenario'>, MutationAwardScenarioArgs>(AWARD_SCENARIO)
}

export const DELETE_SCENARIO = gql`
  mutation deleteScenario($id: String!) {
    deleteScenario(id: $id) {
      ...ScenarioFragment
    }
  }
  ${ScenarioFragment}
`

export function useDeleteOfferScenarioMutation() {
  return useMutation<Pick<Mutation, 'deleteScenario'>, MutationDeleteScenarioArgs>(DELETE_SCENARIO)
}

export const UPDATE_SCENARIO_COMMITMENT = gql`
  mutation updateCommitment($input: UpdateScenarioCommitmentInput!) {
    updateCommitment(input: $input) {
      ...ScenarioFragment
    }
  }
  ${ScenarioFragment}
`

export function useUpdateCommitmentMutation(
  options?: MutationHookOptions<Pick<Mutation, 'updateCommitment'>, MutationUpdateCommitmentArgs>,
) {
  return useMutation<Pick<Mutation, 'updateCommitment'>, MutationUpdateCommitmentArgs>(
    UPDATE_SCENARIO_COMMITMENT,
    options,
  )
}

const UPDATE_SCENARIO_ITEM_BY_OFFER = gql`
  mutation updateScenarioItemByOffer($input: UpdateScenarioByOfferInput!) {
    updateScenarioItemByOffer(input: $input)
  }
`

export function useUpdateScenarioItemByOfferMutation() {
  return useMutation<Pick<Mutation, 'updateScenarioItemByOffer'>, MutationUpdateScenarioItemByOfferArgs>(
    UPDATE_SCENARIO_ITEM_BY_OFFER,
    { refetchQueries: ['offerScenario'] },
  )
}

const SCENARIO_TIER_CONVERSION_BREAKDOWN = gql`
  query scenarioTierConversionBreakdown($scenarioId: String!, $tierId: String!) {
    scenarioTierConversionBreakdown(scenarioId: $scenarioId, tierId: $tierId) {
      tierId
      scenarioId
      vendor
      currentIncumbentSpend
      projectedSavings
    }
  }
`

export function useScenarioTierConversionBreakdown(
  options: QueryHookOptions<Pick<Query, 'scenarioTierConversionBreakdown'>, QueryScenarioTierConversionBreakdownArgs>,
) {
  return useQuery(SCENARIO_TIER_CONVERSION_BREAKDOWN, options)
}
