import React, { useContext } from 'react'
import { MeContext, UserContext } from '../../api'

type PermissionType = 'read' | 'write' | 'curvoAdmin'

type Props = {
  customerId?: string
  organizationId?: string
  permissionTarget: PermissionType
  children?: React.ReactNode
}

const PERMISSION_VALUE = {
  read: 1,
  write: 2,
  curvoAdmin: 3,
}

const checkPermission = (target: PermissionType, permission: string) => {
  return PERMISSION_VALUE[target] <= PERMISSION_VALUE[permission as PermissionType]
}

const getPermissionForOrganization = (meData?: UserContext, organizationId?: string, customerId?: string) => {
  let permission: PermissionType | 'no' = 'no'
  const isAdmin = meData?.isAdmin
  if (isAdmin) {
    permission = 'curvoAdmin'
  }
  if (customerId !== undefined) {
    if (meData?.user.customerId === customerId) {
      permission = 'read'
    }
  }
  if (organizationId !== undefined) {
    const accessibleOrg = (meData?.powerUserList || []).find(pwOrg => pwOrg.organizationId === organizationId)
    if (accessibleOrg) {
      permission = accessibleOrg.isPowerUser ? 'write' : 'read'
    }
  }
  return permission
}

export const usePermissionCheck = ({ customerId, organizationId }: Omit<Props, 'permissionTarget'>) => {
  const { data: meData } = useContext(MeContext)

  return getPermissionForOrganization(meData?.me, organizationId, customerId)
}

export const PermissionPlaceholder: React.FC<Props> = ({ customerId, organizationId, permissionTarget, children }) => {
  const { data: meData } = useContext(MeContext)

  const permission = getPermissionForOrganization(meData?.me, organizationId, customerId)

  if (permission === 'curvoAdmin') {
    return <>{children}</>
  }
  if (permissionTarget === 'curvoAdmin') {
    return <></>
  }

  if (checkPermission(permissionTarget, permission)) {
    return <>{children}</>
  }

  return <></>
}
