import React from 'react'
import styled, { css } from 'styled-components'

export type CardProps = {
  extra?: React.ReactNode[]
  children?: React.ReactNode
  width?: number | string
  height?: number | string
  title?: string
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => any
}

export const Card: React.FC<CardProps> = ({ title, height, extra, children, width, onClick, ...props }) => {
  let cardWidth = ''
  if (width) {
    cardWidth = typeof width === 'number' ? `${width}px` : `${width}`
  }
  let cardHeight = ''
  if (height) {
    cardHeight = typeof height === 'number' ? `${height}px` : `${height}`
  }
  return (
    <CardWrapper width={cardWidth} height={cardHeight} {...props} onClick={onClick}>
      {title && (
        <CardHeaderWrapper>
          <CardTitle>{title}</CardTitle>
          {extra || ''}
        </CardHeaderWrapper>
      )}
      {children}
    </CardWrapper>
  )
}

const CardWrapper = styled.div<{
  width?: string
  height?: string
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => any
}>`
  padding: 16px;
  display: flex;
  flex-direction: column;
  background: #fff;
  ${props =>
    props.width &&
    css`
      width: ${props.width};
    `}
  ${props =>
    props.height &&
    css`
      height: ${props.height};
    `}
  ${props =>
    props.onClick &&
    css`
      cursor: pointer;

      :hover {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
        transition: box-shadow 300ms cubic-bezier(0.2, 0, 0, 1) 0s;
      }
    `}
`

const CardHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const CardTitle = styled.div`
  font-size: 20px;
  line-height: 28px;
  height: 56px;
`
