import { gql, QueryHookOptions, useMutation, useQuery } from '@apollo/client'
import {
  Mutation,
  MutationCloakingPermissionsOfUserArgs,
  MutationRebuildQuicksightPermissionsArgs,
  MutationUpdateDashboardArgs,
  Query,
  QueryGetDashboardByIdArgs,
} from '../gen/models'

const CREATE_DASHBOARDS_MUTATION = gql`
  mutation createDashboards {
    createDashboards
  }
`
export function useCreateDashboardsMutation() {
  return useMutation<Pick<Mutation, 'createDashboards'>>(CREATE_DASHBOARDS_MUTATION)
}

const UPDATE_DASHBOARD = gql`
  mutation updateDashboard($input: UpdateDashboardInput!) {
    updateDashboard(input: $input)
  }
`

export function useUpdateDashbboardMutation() {
  return useMutation<Pick<Mutation, 'updateDashboard'>, MutationUpdateDashboardArgs>(UPDATE_DASHBOARD)
}

const GET_DASHBOARD = gql`
  query getDashboardById($input: GetDashboardInput!) {
    getDashboardById(input: $input) {
      id
      name
      angleTypes
      basketDashboards {
        basketId
      }
    }
  }
`

export function useGetDashboardByIdQuery(
  baseOptions?: QueryHookOptions<Pick<Query, 'getDashboardById'>, QueryGetDashboardByIdArgs>,
) {
  return useQuery(GET_DASHBOARD, baseOptions)
}

const REBUILD_QUICKSIGHT_PERMISSIONS = gql`
  mutation rebuildQuicksightPermissions($userId: String, $customerId: String) {
    rebuildQuicksightPermissions(userId: $userId, customerId: $customerId)
  }
`

export function useRebuildQuicksightPermissionsMutation() {
  return useMutation<Pick<Mutation, 'rebuildQuicksightPermissions'>, MutationRebuildQuicksightPermissionsArgs>(
    REBUILD_QUICKSIGHT_PERMISSIONS,
  )
}

const CLOAKING_USER_QUICKSIGHT_PERMISSIONS = gql`
  mutation cloakingPermissionsOfUser($userId: String!) {
    cloakingPermissionsOfUser(userId: $userId)
  }
`

export function useCloakingPermissionsOfUserMutation() {
  return useMutation<Pick<Mutation, 'cloakingPermissionsOfUser'>, MutationCloakingPermissionsOfUserArgs>(
    CLOAKING_USER_QUICKSIGHT_PERMISSIONS,
  )
}
