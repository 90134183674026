import { ErrorResponse } from '@apollo/client/link/error'
import { GraphQLError } from 'graphql'
import { message } from 'antd'
import { ERROR_MESSAGE_MAP } from './errorMessages'

function getGqlErrors(error: ErrorResponse): GraphQLError[] {
  const result: GraphQLError[] = []

  if (error.graphQLErrors?.length) {
    result.push(...error.graphQLErrors)
  }

  return result
}

function isNetworkError(error: ErrorResponse) {
  return !!error.networkError && !error.graphQLErrors?.length
}

function extractErrorMessages(error: ErrorResponse): string[] {
  if (isNetworkError(error)) {
    return ['Network error. Please check your network or contact our support to help you fix it.']
  }

  return getGqlErrors(error)
    .map(({ extensions }) => ERROR_MESSAGE_MAP[extensions?.code as string])
    .filter(msg => !!msg)
}

export function hasUnauthenticatedError(error: ErrorResponse) {
  const gqlErrors = getGqlErrors(error)
  const unauthenticatedError = gqlErrors.find(err => err.extensions?.code === 'UNAUTHENTICATED')
  return !!unauthenticatedError
}

export function handleCurvoError(error: ErrorResponse) {
  const errMessages = extractErrorMessages(error)
  if (errMessages?.length > 0) {
    message.error(errMessages.join('\n'))
  }
}
