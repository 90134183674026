import axios from 'axios'
import { Spin, Button, Result } from 'antd'
import React, { useEffect, useState, useMemo, useCallback } from 'react'
import styled from 'styled-components'
import { useLocation } from 'react-router'
import { GreenCircleThumbsUpSVG } from '../../ui/svgs'
// @ts-ignore
import Env from '../../api/gen/Env'

export const UserUpdateNewEmailConfirmation = () => {
  const [loading, setIsLoading] = useState<boolean>()
  const [error, setError] = useState<Error>()
  const location = useLocation()

  const { token } = useMemo(() => {
    const params = new URLSearchParams(location.search)
    return {
      token: params.get('token'),
    }
  }, [location])

  const handleSubmitResult = useCallback(() => {
    setIsLoading(true)
    axios
      .post(`${Env.api.replace('/graphql', '')}/non-login/user/update-email/update?token=${token}`)
      .catch(e => setError(e))
      .finally(() => {
        setIsLoading(false)
      })
  }, [token])

  useEffect(() => {
    handleSubmitResult()
  }, [handleSubmitResult])

  return (
    <MainPageLayout>
      {loading ? (
        <SpinWrapper>
          <Spin size="large" />
        </SpinWrapper>
      ) : error ? (
        <Result
          status="error"
          title="Submission Failed"
          subTitle="Please check or report to Curvo Support team support@curvolabs.com."
        />
      ) : (
        <ResponseCard />
      )}
    </MainPageLayout>
  )
}

const ResponseCard: React.FC = () => {
  return (
    <ResponseCardWrapper>
      <span className="title">Confirmed</span>
      <div className="thumb">
        <GreenCircleThumbsUpSVG />
      </div>
      <span className="message">You have confirmed your new email update.</span>
      <div className="change_response">
        <Button type="link" href="/">
          Return to Home
        </Button>
      </div>
    </ResponseCardWrapper>
  )
}

const MainPageLayout = styled.div`
  height: 100vh;
  width: 100%;
  background: #f3f5f8;
`

const SpinWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const ResponseCardWrapper = styled.div`
  position: absolute;
  top: calc(50% - 152px);
  left: calc(50% - 300px);
  width: 600px;
  height: 304px;
  display: flex;
  flex-direction: column;
  background: white;
  padding: 40px;

  .title {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
  }

  .message {
    width: 382px;
    margin: auto;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
  }

  .thumb {
    margin: auto;
  }

  .change_response {
    margin: auto;
  }
`
