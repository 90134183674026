import React, { useContext } from 'react'
import styled from 'styled-components'
import { Row, Col, Button, Popconfirm } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import { MeContext, User, useUpdateUserMutation } from '../../api'
import { Card } from '../components/Card'
import { UserAvatar } from '../components/UserAvatar'

type Props = {
  user: User
}

const UserBlock = styled.div`
  background-color: white;
  padding: 33px 25px;
  text-align: center;
`

const UserData = styled.section`
  padding: 1em;
  text-align: center;
`

const InformationLabel = styled.div`
  text-transform: uppercase;
  font-size: 10px;
`

const InformationData = styled.div`
  font-size: 14px;
  font-weight: 600;
`

export const UserInfoCard: React.FC<Props> = ({ user }) => {
  const { data: meData } = useContext(MeContext)
  const isAdmin = meData?.me.isAdmin || false
  const [updateUser] = useUpdateUserMutation()

  return (
    <Card title="User Info" width="100%" height="100%">
      <Row gutter={[36, 16]}>
        <Col span={8}>
          <UserBlock>
            <UserAvatar user={user} />
            <UserData>
              <h2>{`${user.firstName} ${user.lastName}`}</h2>
              <h4>{user.email}</h4>
            </UserData>
          </UserBlock>
        </Col>
        <Col span={8}>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <InformationLabel>Title</InformationLabel>
              <InformationData>{user.title}</InformationData>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <InformationLabel>Phone Number</InformationLabel>
              <InformationData>{user.phoneNumber || '-'}</InformationData>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={12}>
              <InformationLabel>Contact Info</InformationLabel>
              <InformationData>
                <a href={`https://www.linkedin.com/in/${user.linkedIn}/`}>LinkedIn</a>
              </InformationData>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <InformationLabel>Bio</InformationLabel>
              <InformationData>{user.bio}</InformationData>
            </Col>
          </Row>
        </Col>
        {isAdmin && user.qsGroups?.length ? (
          <Col span={8}>
            <InformationLabel>QuickSight Groups</InformationLabel>
            {user.qsGroups.map(group => (
              <InformationData>
                <Popconfirm
                  title={
                    <>
                      <strong>{group}</strong> QuickSight group will be detached from user
                    </>
                  }
                  onConfirm={() =>
                    updateUser({
                      variables: { input: { id: user.id, qsGroups: user.qsGroups!.filter(g => g !== group) } },
                    })
                  }>
                  {group}
                  <Button type="text" shape="circle" size="small" danger={true} icon={<CloseOutlined />} />
                </Popconfirm>
              </InformationData>
            ))}
          </Col>
        ) : (
          ''
        )}
      </Row>
    </Card>
  )
}
