import { MutationHookOptions, gql, useMutation, QueryHookOptions, useQuery } from '@apollo/client'
import {
  Mutation,
  MutationBulkUpdateHomepageFeaturedContentsArgs,
  Query,
  QueryHomepageFeaturedContentsArgs,
} from '../gen/models'

export const FeaturedContentFragment = gql`
  fragment HomepageFeaturedContentFragement on HomepageFeaturedContent {
    id
    title
    description
    priority
    customerId
    url
    imageUrl
    customer {
      id
      name
    }
  }
`

const QUERY_FEATURED_CONTENTS = gql`
  query homepageFeaturedContents($input: HomepageFeaturedContentQueryInput!) {
    homepageFeaturedContents(input: $input) {
      ...HomepageFeaturedContentFragement
    }
  }
  ${FeaturedContentFragment}
`

export const useQueryFeaturedContents = (
  queryHookOptions: QueryHookOptions<Pick<Query, 'homepageFeaturedContents'>, QueryHomepageFeaturedContentsArgs>,
) => {
  return useQuery(QUERY_FEATURED_CONTENTS, queryHookOptions)
}

const USER_QUERY_FEATURED_CONTENTS = gql`
  query userHomepageFeaturedContents {
    userHomepageFeaturedContents {
      ...HomepageFeaturedContentFragement
    }
  }
  ${FeaturedContentFragment}
`

export const useUserQueryFeaturedContents = (
  queryHookOptions: QueryHookOptions<Pick<Query, 'userHomepageFeaturedContents'>>,
) => {
  return useQuery(USER_QUERY_FEATURED_CONTENTS, queryHookOptions)
}

const BULK_UPDATE_FEATURED_CONTENTS_MUTATION = gql`
  mutation bulkUpdateHomepageFeaturedContents($input: BulkUpdateHomepageFeaturedContentInput!) {
    bulkUpdateHomepageFeaturedContents(input: $input) {
      ...HomepageFeaturedContentFragement
    }
  }
  ${FeaturedContentFragment}
`

export const useBulkUpdateFeaturedContentsMutation = (
  mutationHookOptions?: MutationHookOptions<
    Pick<Mutation, 'bulkUpdateHomepageFeaturedContents'>,
    MutationBulkUpdateHomepageFeaturedContentsArgs
  >,
) => {
  return useMutation(BULK_UPDATE_FEATURED_CONTENTS_MUTATION, mutationHookOptions)
}
