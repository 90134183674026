import React from 'react'
import styled from 'styled-components'
import { Space, Tag } from 'antd'
import * as dt from '../../ui/design-tokens/tokens'
import { User } from '../../api'
import { UserAvatar } from './UserAvatar'

type UserHeaderSectionProps = {
  user: User
  hideAvatar?: boolean
}

export const UserHeaderSection: React.FC<React.AnchorHTMLAttributes<{}> & UserHeaderSectionProps> = ({
  user,
  hideAvatar,
  ...props
}) => (
  <UserHeaderSectionWrapper {...props}>
    {!hideAvatar && <UserAvatar user={user} size={32} />}
    <TextGroup>
      <Space wrap size={10} className="username">
        {(user && `${user.firstName} ${user.lastName}`) || 'name'}
        {user.cloakUserId && <Tag color={dt.ColorAccentDaybreakBlue70}>Cloaked</Tag>}
      </Space>
      <span className="email">{user.email}</span>
    </TextGroup>
  </UserHeaderSectionWrapper>
)

const transitionTime: number = 0.2

const UserHeaderSectionWrapper = styled.a`
  display: flex;
  align-items: center;
  height: 100%;
  gap: 0.5em;

  .avatar {
    background-color: ${props => props.theme.ColorNeutral70};
    margin-right: ${props => props.theme.SpaceXs};
    color: #fff;
    transition: ${transitionTime}s;
  }

  .username {
    color: ${props => props.theme.ColorNeutral50};
    font-weight: 500;
    font-size: 0.8125rem;
    line-height: 1rem;
    transition: ${transitionTime}s;
  }

  .email {
    color: ${props => props.theme.ColorNeutral60};
    font-size: 0.75rem;
    line-height: 1rem;
    transition: ${transitionTime}s;
  }

  :hover {
    text-decoration: none;
    .avatar {
      background-color: ${props => props.theme.ColorNeutral60};
    }
    .username {
      color: ${props => props.theme.ColorNeutral0};
    }
    .email {
      color: ${props => props.theme.ColorNeutral40};
    }
  }
`

const TextGroup = styled.div`
  display: flex;
  flex-direction: column;
  overflow-wrap: anywhere;
`
