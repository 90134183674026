import { gql, QueryHookOptions, useQuery } from '@apollo/client'
import { Query } from '../../api'

export const ExternalAuthConnectionFragment = gql`
  fragment ExternalAuthConnectionFragment on ExternalAuthConnection {
    id
    name
  }
`

const EXTERNAL_AUTH_CONNECTIONS_QUERY = gql`
  query externalAuthConnections {
    externalAuthConnections {
      ...ExternalAuthConnectionFragment
    }
  }
  ${ExternalAuthConnectionFragment}
`

export function useFetchExternalAuthConnections(options?: QueryHookOptions<Pick<Query, 'externalAuthConnections'>>) {
  return useQuery(EXTERNAL_AUTH_CONNECTIONS_QUERY, options)
}
