import React, { useState } from 'react'
import Modal, { ModalProps } from 'antd/lib/modal'
import styled from 'styled-components'
import { message } from 'antd'
import {
  useOrganizationUsersQuery,
  Organization,
  useAddUserToOrganizationMutation,
  useRemoveUserFromOrganizationMutation,
  useBulkOrganizationUserRoleUpdateMutation,
} from '../../../api'
import { UserSearchInput, OrganizationUserItemList } from '../../components/UserCommon'

type OrganizationUsersModalProps = ModalProps & {
  onSuccess: () => any
  organization: Organization
  customerId: string
}

export const OrganizationUsersModal: React.FC<OrganizationUsersModalProps> = ({
  onSuccess,
  organization,
  customerId,
  ...modalProps
}) => {
  const queryArgs = { input: { id: organization.id } }
  const { data } = useOrganizationUsersQuery({ variables: queryArgs })
  const usersWithRole = (data && data.organizationUsers) || []
  const [bulkOrganizationUserRoleUpdate] = useBulkOrganizationUserRoleUpdateMutation(queryArgs)
  const [addUserToOrganization] = useAddUserToOrganizationMutation(queryArgs)
  const [removeUserFromOrganization] = useRemoveUserFromOrganizationMutation(queryArgs)
  const [updatingUsers, setUpdatingUsers] = useState<{ [key: string]: boolean }>({})
  const { onCancel } = modalProps

  return (
    <Modal
      {...modalProps}
      title={`Assign New User to ${organization.name}`}
      width={600}
      onOk={e => {
        bulkOrganizationUserRoleUpdate({
          variables: {
            input: {
              organizationsId: organization.id,
              updates: Object.keys(updatingUsers).map(key => ({
                usersId: key,
                isPowerUser: updatingUsers[key],
              })),
            },
          },
        }).then(() => onCancel && onCancel(e))
        onSuccess()
      }}>
      <ModalWrapper>
        <UserSearchInput
          assignedUsers={data?.organizationUsers}
          customerId={customerId}
          onSelect={user => {
            addUserToOrganization({ variables: { input: { usersId: user.id, organizationsId: organization.id } } })
              .then(() => message.success('Added new user'))
              .catch(e => message.error(e.message))
          }}
        />
        <UserListWrapper>
          <span>{organization.totalUsers} have access to this organization.</span>
          <UserList>
            {usersWithRole.map(userWithRole => (
              <OrganizationUserItemList
                key={userWithRole.user.id}
                user={userWithRole.user}
                disabled={userWithRole.user.isTopUser}
                isAdmin={
                  updatingUsers[userWithRole.user.id] !== undefined
                    ? updatingUsers[userWithRole.user.id]
                    : userWithRole.isPowerUser
                }
                onDeleteButtonClicked={() => {
                  removeUserFromOrganization({
                    variables: {
                      input: {
                        usersId: userWithRole.usersId,
                        organizationsId: userWithRole.organizationsId,
                      },
                    },
                  })
                    .then(() => message.success(`Removed user ${userWithRole.user.username}`))
                    .catch(e => {
                      message.error(e.message)
                    })
                }}
                onRoleChanged={isPowerUser => {
                  setUpdatingUsers({
                    ...updatingUsers,
                    [userWithRole.user.id]: isPowerUser,
                  })
                }}
              />
            ))}
          </UserList>
        </UserListWrapper>
      </ModalWrapper>
    </Modal>
  )
}

const UserList = styled.div`
  max-height: 400px;
  overflow: auto;
`

const UserListWrapper = styled.div`
  margin-top: 15px;
`

const ModalWrapper = styled.div`
  margin-left: 32px;
  margin-right: 32px;
  height: 500px;
`
