import { LicenseManager } from '@ag-grid-enterprise/core'
import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import * as serviceWorker from './serviceWorker'
// @ts-ignore
import Env from './api/gen/Env'

if (Env.agGridLicense) {
  LicenseManager.setLicenseKey(Env.agGridLicense)
}

const container = document.getElementById('root')
const root = createRoot(container as any) // createRoot(container!) if you use TypeScript

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
