import React from 'react'
import styled from 'styled-components'
import { Layout, PageHeader } from 'antd'
import { PageHeaderProps } from 'antd/es/page-header'

const { Content } = Layout

type Props = PageHeaderProps & {
  sidebar?: React.ReactNode
  extraContent?: React.ReactNode
}

export const BaseManagePageLayout: React.FC<Props> = ({ sidebar, children, ...headerProps }) => {
  return (
    <BaseManagePageContainer>
      {sidebar}
      <Layout>
        <BasePageHeader {...headerProps} />
        <ManagePageContentContainer>{children}</ManagePageContentContainer>
      </Layout>
    </BaseManagePageContainer>
  )
}

const BaseManagePageContainer = styled(Layout)`
  display: flex;
  flex-direction: row;
  height: 100%;
`

const ManagePageContentContainer = styled(Content)`
  margin: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const BasePageHeader = styled(PageHeader)`
  background: #fff;
`
