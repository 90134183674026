import { Rate, Statistic } from 'antd'
import { sumBy } from 'lodash'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import {
  Offer,
  OppotunityStatusEnum,
  useAddOpportunityFavoriteMutation,
  useRemoveOpportunityFavoriteMutation,
} from '../../../api'
import { formatCurrency } from '../../../utils'

export const OpportunityCard: React.FC<{ opportunity: Offer }> = ({ opportunity }) => {
  const [isFavorite, setIsFavorite] = useState<number>(opportunity.isFavorite ? 1 : 0)
  const [addOpportunityFavorite] = useAddOpportunityFavoriteMutation()

  const [removeOpportunityFavorite] = useRemoveOpportunityFavoriteMutation()

  const handleFavorite = (value: number) => {
    setIsFavorite(value)
    value
      ? addOpportunityFavorite({ variables: { input: { offerId: opportunity.id } } })
      : removeOpportunityFavorite({ variables: { input: { offerId: opportunity.id } } })
  }

  const offersMetGoalCount = opportunity.goal
    ? opportunity.offerBasketInsights?.filter(obi =>
        obi.tiers.some(t => sumBy(t.baskets, b => b.offeredTotalProjected || 0) <= opportunity.goal!),
      ).length || 0
    : 0

  return (
    <OpportunityListItemCard>
      <OpportunityTitleWrapper>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <PinnedStarIcon count={1} onChange={handleFavorite} value={isFavorite} />
          <ContractTitle
            to={
              opportunity.parentStatus === OppotunityStatusEnum.Draft
                ? `/strategic-sourcing/opportunities/create/${opportunity.id}`
                : `/strategic-sourcing/opportunities/${opportunity.id}`
            }>
            {opportunity.name}
          </ContractTitle>
        </div>
      </OpportunityTitleWrapper>
      <Link
        to={
          opportunity.parentStatus === OppotunityStatusEnum.Draft
            ? `/strategic-sourcing/opportunities/create/${opportunity.id}`
            : `/strategic-sourcing/opportunities/${opportunity.id}`
        }>
        <OpportunityInfoWrapper>
          <Statistic
            title="CURRENT SPENDING"
            value={opportunity.totalSpend ? formatCurrency(opportunity.totalSpend) : '-'}
            style={{ textAlign: 'right' }}
          />
          <Statistic
            title="SPENDING GOAL"
            value={opportunity.goal ? formatCurrency(opportunity.goal) : '-'}
            style={{ textAlign: 'right' }}
          />
          <Statistic title="VENDORS CONTACTED" value={opportunity.totalVendor || '-'} style={{ textAlign: 'right' }} />
          <Statistic
            title="OFFERS RECEIVED"
            value={
              opportunity.totalOffer
                ? `${opportunity.totalOffer} ${offersMetGoalCount ? `(${offersMetGoalCount} meet your goal)` : ''}`
                : '-'
            }
            style={{ textAlign: 'right' }}
          />
          <Statistic title="DUE DATE" value={opportunity.dueDate || '-'} style={{ textAlign: 'right' }} />
        </OpportunityInfoWrapper>
      </Link>
    </OpportunityListItemCard>
  )
}

const OpportunityInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 48px;

  .ant-statistic {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-right: 0;

    > div {
      z-index: 1;
      background-color: white;
    }

    .ant-statistic-title {
      padding-right: 8px;
    }

    .ant-statistic-content {
      padding-left: 8px;
    }
  }

  .ant-statistic::before {
    position: absolute;
    width: 100%;
    content: ' ';
    height: 1px;
    border-top: dashed 1px var(--color-neutral-50);
    top: 50%;
  }
`

const OpportunityListItemCard = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 16px;
  padding-right: 32px;
  padding-bottom: 20px;
  background: #fff;
  margin-bottom: 8px;
  border-radius: 4px;
`

const OpportunityTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 16px;
  margin-bottom: 8px;
`

const PinnedStarIcon = styled(Rate)`
  margin-right: 8px;
  font-size: 18px;
  line-height: 18px;
  color: ${props => props.theme.ColorOrange50};
  width: 24px;
`

const ContractTitle = styled(Link)`
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 28px;

  color: #3b7cff;
`
