import { useAbility } from '@casl/react'
import { message } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { MeContext, OpportunityOwnerEnum, OrderDirection, UserPermissionFeature, useOffersQuery } from '../../../api'
import { AbilityContext } from '../../../api/components/Can'
import { useCreateSupportTicketMutation } from '../../../api/hooks/support-ticket'
import { CreateItemCard } from '../components/CreateItemCard'
import { HomeCarousel } from '../components/HomeCarousel'
import { HomeSection } from '../components/HomeSection'
import { OpportunityCard } from './OpportunityCard'

const SUPPORT_TICKET_KEY = 'home_rfx_st_requested'

export const OpportunitiesSection: React.FC = () => {
  const navigate = useNavigate()
  const { data: meData } = useContext(MeContext)
  const ability = useAbility(AbilityContext)
  const hasPermission = ability.can('access', UserPermissionFeature.Opportunities)
  const userSupportTicketKey = `${SUPPORT_TICKET_KEY}_${meData?.me.user.id}`
  const [requested, setRequested] = useState<boolean | null>(null)

  const { data, loading } = useOffersQuery({
    variables: {
      input: { opportunityOnly: true, owner: OpportunityOwnerEnum.Mine },
      sort: { field: 'updatedAt', direction: OrderDirection.Desc },
      pagination: { limit: 6, offset: 0 },
    },
    skip: !hasPermission,
    fetchPolicy: 'network-only',
  })
  const [createSupportTicket, { loading: ticketLoading }] = useCreateSupportTicketMutation({
    onCompleted: () => {
      window.localStorage.setItem(userSupportTicketKey, Date.now().toString())
      setRequested(true)
      message.success(
        'We acknowledged your request. Customer Success team will contact you to provide more information.',
      )
    },
    onError: () => {
      message.error('Your request was failed. Please check your network or contact our support to help you fix it.')
    },
  })

  useEffect(() => {
    if (!hasPermission && requested === null) {
      setRequested(!!window.localStorage.getItem(userSupportTicketKey))
    }
  }, [hasPermission, requested, userSupportTicketKey])

  return (
    <HomeSection
      title="RFx"
      showAllLink={hasPermission && data?.offers.hits.length ? '/strategic-sourcing/opportunities' : undefined}>
      <HomeCarousel
        loading={loading}
        items={hasPermission ? data?.offers.hits || [] : []}
        getRowKey={item => item.id}
        renderItem={opp => <OpportunityCard opportunity={opp} />}
        slidesToShow={2}
        extraItem={
          <CreateItemCard
            title="Start an RFx"
            description={
              requested
                ? 'We acknowledged your request. Customer Success team will contact you to provide more information.'
                : 'Invite suppliers to bid for business and run what-if scenarios based on commitments and rebates.'
            }
            loading={ticketLoading}
            requested={!!requested}
            onClick={() => {
              if (hasPermission) {
                navigate('/strategic-sourcing/opportunities/create')
              } else {
                createSupportTicket({ variables: { input: { subject: 'Request for access to RFx on CX' } } })
              }
            }}
          />
        }
      />
    </HomeSection>
  )
}
