import { gql, QueryHookOptions, useMutation, useQuery } from '@apollo/client'
import {
  Mutation,
  MutationAdminResetPasswordArgs,
  MutationInviteUserArgs,
  MutationResendUserInvitationArgs,
  MutationToggleUserActivationArgs,
  MutationUpdateMeArgs,
  MutationUpdateUserArgs,
  Query,
  QueryCreateQuicksightGroupArgs,
  QueryGetDashboardEmbedUrlArgs,
  QueryListDashboardsForUserArgs,
  QueryOrganizationUsersArgs,
  QueryPermissionToArgs,
  QueryUserArgs,
  QueryUsersArgs,
} from '..'

export const SimplifiedUserFragment = gql`
  fragment SimplifiedUserFragment on User {
    id
    username
    email
    firstName
    lastName
    lastActionAt
    isCurvoAdmin
    status
    avatarUrl
    createdAt
    phoneNumber
    isTopUser
    isTopPowerUser
  }
`

export const UserFragment = gql`
  fragment UserFragment on User {
    ...SimplifiedUserFragment
    title
    bio
    avatarUrl
    facebook
    linkedIn
    twitter
    phoneNumber
    qsGroups
    permissions {
      can {
        permissionType
        value
      }
      cannot {
        permissionType
        value
      }
    }
  }
  ${SimplifiedUserFragment}
`

export const PowerUserListFragment = gql`
  fragment PowerUserListFragment on PowerUserListItem {
    organizationId
    organizationName
    isPowerUser
    rank
    userRank
    facilityId
  }
`

const USERS_QUERY = gql`
  query users($input: GetUsersByCriteriaInput!, $pagination: Pagination) {
    users(input: $input, pagination: $pagination) {
      hits {
        ...UserFragment
        isManageable
        isSupplierUser
        customerId
        userOrganizations {
          organizationsId
        }
      }
      totalHits
    }
  }
  ${UserFragment}
`

export const useQueryUsers = (baseOptions?: QueryHookOptions<Pick<Query, 'users'>, QueryUsersArgs>) => {
  return useQuery(USERS_QUERY, baseOptions)
}

const USERS_SUGGESTION_QUERY = gql`
  query usersSuggestions($input: GetUsersByCriteriaInput!, $pagination: Pagination) {
    users(input: $input, pagination: $pagination) {
      hits {
        ...SimplifiedUserFragment
        powerUserList {
          ...PowerUserListFragment
        }
      }
      totalHits
    }
  }
  ${SimplifiedUserFragment}
  ${PowerUserListFragment}
`

export const useQueryUsersSuggestion = (baseOptions?: QueryHookOptions<Pick<Query, 'users'>, QueryUsersArgs>) => {
  return useQuery(USERS_SUGGESTION_QUERY, baseOptions)
}

const INVITE_USER = gql`
  mutation inviteUser($input: InviteUserInput!) {
    inviteUser(input: $input) {
      ...UserFragment
      customer {
        id
      }
      organizations {
        id
      }
    }
  }
  ${UserFragment}
`

export const UserOrganizationFragment = gql`
  fragment UserOrganizationFragment on UserOrganization {
    user {
      ...UserFragment
    }
    usersId
    isPowerUser
    organizationsId
  }
  ${UserFragment}
`

export function useInviteUserMutation() {
  return useMutation<Pick<Mutation, 'inviteUser'>, MutationInviteUserArgs>(INVITE_USER, {
    refetchQueries: ['users'],
  })
}

export const ME_QUERY = gql`
  query me {
    me {
      user {
        ...SimplifiedUserFragment
        permissions {
          can {
            permissionType
            value
          }
          cannot {
            permissionType
            value
          }
        }
        customer {
          id
          name
          type
        }
        cloakUserId
      }
      isAdmin
      powerUserList {
        ...PowerUserListFragment
      }
    }
  }
  ${SimplifiedUserFragment}
  ${PowerUserListFragment}
`

export function useMeQuery(baseOptions?: QueryHookOptions<Pick<Query, 'me'>>) {
  return useQuery(ME_QUERY, baseOptions)
}

const ON_LOGIN = gql`
  mutation onLogin {
    onLogin
  }
`

export function useOnLoginMutation() {
  return useMutation<Pick<Mutation, 'onLogin'>>(ON_LOGIN)
}

const UPDATE_USER = gql`
  mutation updateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      ...UserFragment
      organizations {
        id
        name
        facilityId
      }
      userOrganizations {
        usersId
        organizationsId
        isPowerUser
      }
    }
  }
  ${UserFragment}
`

const UPDATE_ME = gql`
  mutation updateMe($input: UpdateMeInput!) {
    updateMe(input: $input) {
      ...UserFragment
    }
  }
  ${UserFragment}
`

const GET_USER = gql`
  query user($id: String!) {
    user(id: $id) {
      ...SimplifiedUserFragment
      bio
      title
      facebook
      linkedIn
      twitter
      qsGroups

      permissions {
        can {
          permissionType
          value
        }
        cannot {
          permissionType
          value
        }
      }

      powerUserList {
        organizationId
        organizationName
        facilityId
      }

      organizations {
        id
        name
        facilityId
      }
      userOrganizations {
        usersId
        organizationsId
        isPowerUser
      }
      customer {
        id
        name
      }
    }
  }
  ${SimplifiedUserFragment}
`

export function useUpdateUserMutation(_variables?: QueryUsersArgs) {
  return useMutation<Pick<Mutation, 'updateUser'>, MutationUpdateUserArgs>(UPDATE_USER)
}

export function useUpdateMeMutation() {
  return useMutation<Pick<Mutation, 'updateMe'>, MutationUpdateMeArgs>(UPDATE_ME)
}

export const useQueryUser = (baseOptions?: QueryHookOptions<Pick<Query, 'user'>, QueryUserArgs>) => {
  return useQuery(GET_USER, baseOptions)
}

const RESEND_USER_INVITE = gql`
  mutation resendUserInvite($email: String!) {
    resendUserInvitation(email: $email)
  }
`

export function useResendUserInviteMutation() {
  return useMutation<Pick<Mutation, 'resendUserInvitation'>, MutationResendUserInvitationArgs>(RESEND_USER_INVITE)
}

const RESET_USER_PASSWORD = gql`
  mutation resetPassword {
    resetPassword
  }
`

export function useResetPassword() {
  return useMutation<Pick<Mutation, 'resetPassword'>>(RESET_USER_PASSWORD)
}

const ADMIN_RESET_USER_PASSWORD = gql`
  mutation adminResetPassword($email: String!) {
    adminResetPassword(email: $email)
  }
`

export function useAdminResetUserPasswordMutation() {
  return useMutation<Pick<Mutation, 'adminResetPassword'>, MutationAdminResetPasswordArgs>(ADMIN_RESET_USER_PASSWORD)
}

const TOGGLE_USER_ACTIVATION = gql`
  mutation toggleUser($input: ToggleUserActivationInput!) {
    toggleUserActivation(input: $input) {
      ...UserFragment
    }
  }
  ${UserFragment}
`

export function useToggleUserActivationMutation(variables?: QueryUsersArgs) {
  return useMutation<Pick<Mutation, 'toggleUserActivation'>, MutationToggleUserActivationArgs>(TOGGLE_USER_ACTIVATION, {
    update: (cache, { data }) => {
      const cachedData = cache.readQuery<Pick<Query, 'users'>>({ query: USERS_QUERY, variables })
      const cachedUsers = cachedData?.users?.hits || []
      if (data && data.toggleUserActivation) {
        cache.writeQuery({
          query: USERS_QUERY,
          variables,
          data: {
            users: {
              ...cachedData?.users,
              hits: cachedUsers.map(user =>
                user.id === data.toggleUserActivation.id ? data.toggleUserActivation : user,
              ),
            },
          },
        })
      }
    },
  })
}

export const ORGANIZATION_USERS_QUERY = gql`
  query organizationUsers($input: IDArgs!) {
    organizationUsers(input: $input) {
      ...UserOrganizationFragment
    }
  }
  ${UserOrganizationFragment}
`

export const useOrganizationUsersQuery = (
  baseOptions?: QueryHookOptions<Pick<Query, 'organizationUsers'>, QueryOrganizationUsersArgs>,
) => {
  return useQuery(ORGANIZATION_USERS_QUERY, baseOptions)
}

const QuicksightDashboardItemFragment = gql`
  fragment QuicksightDashboardItemFragment on QuicksightDashboardItemType {
    dashboardId
    name
  }
`

const LIST_DASHBOARDS_FOR_USER = gql`
  query listDashboardsForUser($input: ListDashboardsForUserInput!) {
    listDashboardsForUser(input: $input) {
      ...QuicksightDashboardItemFragment
    }
  }
  ${QuicksightDashboardItemFragment}
`

export const useListDashboardsForUserQuery = (
  baseOptions?: QueryHookOptions<Pick<Query, 'listDashboardsForUser'>, QueryListDashboardsForUserArgs>,
) => {
  return useQuery(LIST_DASHBOARDS_FOR_USER, baseOptions)
}

const LIST_DASHBOARDS = gql`
  query getListOfDashboards {
    getListOfDashboards {
      ...QuicksightDashboardItemFragment
    }
  }
  ${QuicksightDashboardItemFragment}
`

export const useListDashboardsQuery = (baseOptions?: QueryHookOptions<Pick<Query, 'getListOfDashboards'>>) => {
  return useQuery(LIST_DASHBOARDS, baseOptions)
}

const QuicksightDashboardEmbedUrlFragment = gql`
  fragment QuicksightDashboardEmbedUrlFragment on QuicksightDashboardEmbedUrlType {
    EmbedUrl
    cloakingUser {
      id
      email
      firstName
      lastName
    }
  }
`

const GET_DASHBOARD_EMBED_URL = gql`
  query getDashboardEmbedUrl($input: GetDashboardEmbedUrlInput!) {
    getDashboardEmbedUrl(input: $input) {
      ...QuicksightDashboardEmbedUrlFragment
    }
  }
  ${QuicksightDashboardEmbedUrlFragment}
`

export const useGetDashboardEmbedUrlQuery = (
  baseOptions?: QueryHookOptions<Pick<Query, 'getDashboardEmbedUrl'>, QueryGetDashboardEmbedUrlArgs>,
) => {
  return useQuery(GET_DASHBOARD_EMBED_URL, baseOptions)
}

const GET_CONSOLE_EMBED_URL = gql`
  query getConsoleEmbedUrl {
    getConsoleEmbedUrl {
      ...QuicksightDashboardEmbedUrlFragment
    }
  }
  ${QuicksightDashboardEmbedUrlFragment}
`

export const useGetConsoleEmbedUrlQuery = (baseOptions?: QueryHookOptions<Pick<Query, 'getConsoleEmbedUrl'>>) => {
  return useQuery(GET_CONSOLE_EMBED_URL, baseOptions)
}

const CHECK_PERMISSION_TO = gql`
  query permissionTo($input: CheckPermissionInput!) {
    permissionTo(input: $input)
  }
`

export const useCheckPermissionToQuery = (
  baseOptions?: QueryHookOptions<Pick<Query, 'permissionTo'>, QueryPermissionToArgs>,
) => useQuery(CHECK_PERMISSION_TO, baseOptions)

const CREATE_QUICKSIGHT_GROUP = gql`
  query createQuicksightGroup($input: CreateQuicksightGroupInput!) {
    createQuicksightGroup(input: $input) {
      Group {
        GroupName
      }
    }
  }
`

export const useCreateQuicksightGroupQuery = (
  baseOptions?: QueryHookOptions<Pick<Query, 'createQuicksightGroup'>, QueryCreateQuicksightGroupArgs>,
) => useQuery(CREATE_QUICKSIGHT_GROUP, baseOptions)

const GET_DEFAULT_PERMISSIONS = gql`
  query defaultPermissions {
    defaultPermissions {
      can {
        permissionType
        value
      }
      cannot {
        permissionType
        value
      }
    }
  }
`

export const useDefaultUserPermissions = (baseOptions?: QueryHookOptions<Pick<Query, 'defaultPermissions'>>) =>
  useQuery(GET_DEFAULT_PERMISSIONS, baseOptions)

const SET_USER_CLOAKING = gql`
  mutation updateCloak($input: UpdateCloakInput!) {
    updateCloak(input: $input)
  }
`

export function useSetUserCloak() {
  return useMutation<Pick<Mutation, 'updateCloak'>>(SET_USER_CLOAKING)
}

const REMOVE_USER_CLOKING = gql`
  mutation removeCloak {
    removeCloak
  }
`

export function useRemoveUserCloak() {
  return useMutation<Pick<Mutation, 'removeCloak'>>(REMOVE_USER_CLOKING)
}

const USERS_FOR_SELECTOR_QUERY = gql`
  query usersForSelector($input: GetUsersByCriteriaInput!, $pagination: Pagination) {
    users(input: $input, pagination: $pagination) {
      hits {
        id
        username
        email
        firstName
        lastName
        customerId
        customer {
          id
          name
        }
      }
      totalHits
    }
  }
`

export const useQueryUsersForSelector = (baseOptions?: QueryHookOptions<Pick<Query, 'users'>, QueryUsersArgs>) => {
  return useQuery(USERS_FOR_SELECTOR_QUERY, baseOptions)
}
