import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Button, Input, Tree } from 'antd'
import { CaretDownFilled, FolderFilled, HomeFilled, PlusCircleFilled, SearchOutlined } from '@ant-design/icons/lib'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { useOrganizationTreeQuery } from '../../api/hooks'
import { Organization } from '../../api'
import * as dt from '../../ui/design-tokens/tokens'
import { PermissionPlaceholder } from './PermissionPlaceholder'

type Props = {
  customerId: string
  organizationId?: string
  onNewOrgClick?: () => void
}

const getDefaultExpandedKeys = (orgs: Organization[], organizationId: string): string[] => {
  return orgs.reduce<string[]>((prev, org) => {
    if (org.id === organizationId) {
      return [org.id]
    }

    if (!org.subOrganizations) {
      return prev
    }

    const selectedIds = getDefaultExpandedKeys(org.subOrganizations, organizationId)
    return selectedIds.length ? [...selectedIds, org.id] : prev
  }, [])
}

export const OrganizationsTree: React.FC<Props> = ({ customerId, organizationId, onNewOrgClick }) => {
  const [searchTerm, setSearchTerm] = useState('')
  const [expandedKeys, setExpandedKeys] = useState<any[]>([])
  const navigate = useNavigate()

  const onOrganizationSelect = (selectedKeys: any) => {
    navigate(`/manage/customer/${customerId}/organization/${selectedKeys[0]}`)
  }

  const organizationsToTree = (organizations: Organization[]): any => {
    return organizations.map(organization => ({
      title: organization.name,
      key: organization.id,
      icon:
        organization.subOrganizations && organization.subOrganizations.length ? (
          <FolderFilled style={{ fontSize: '16px' }} />
        ) : (
          <HomeFilled style={{ fontSize: '16px' }} />
        ),
      children: organization.subOrganizations ? organizationsToTree(organization.subOrganizations) : [],
    }))
  }

  const organizationSearch = useCallback((organizations: Organization[], search: string) => {
    const filteredChildren: Organization[] = organizations.map(organization => {
      return organization.name.toLocaleLowerCase().indexOf(search.toLowerCase()) >= 0
        ? organization
        : {
            ...organization,
            subOrganizations: organization.subOrganizations
              ? organizationSearch(organization.subOrganizations, search)
              : [],
          }
    })

    return filteredChildren.filter(
      organization =>
        (organization.subOrganizations && organization.subOrganizations.length > 0) ||
        organization.name.toLocaleLowerCase().indexOf(search.toLowerCase()) >= 0,
    )
  }, [])

  const getAllIds = (organizations: Organization[]): string[] => {
    return organizations
      .map(organization => {
        return organization.subOrganizations && organization.subOrganizations.length > 0
          ? [organization.id, ...getAllIds(organization.subOrganizations)]
          : []
      })
      .flat()
  }

  const organizationTreeRequest = useOrganizationTreeQuery({
    variables: { input: { id: customerId } },
  })

  const organizations = useMemo(
    () =>
      organizationTreeRequest.data
        ? organizationSearch(organizationTreeRequest.data.organizationTreeByCustomer, searchTerm)
        : [],
    [organizationTreeRequest, organizationSearch, searchTerm],
  )

  useEffect(() => {
    if (searchTerm || !organizationId || expandedKeys.length > 0) {
      return
    }

    setExpandedKeys(getDefaultExpandedKeys(organizations, organizationId))
  }, [customerId, expandedKeys.length, organizationId, organizations, searchTerm])

  return (
    <>
      <Input
        prefix={<SearchOutlined style={{ color: dt.ColorNeutral50 }} />}
        placeholder={'Search organizations...'}
        onChange={e => {
          setSearchTerm(e.target.value)
        }}
      />
      <Tree
        showIcon
        selectedKeys={organizationId ? [organizationId] : []}
        expandedKeys={searchTerm ? getAllIds(organizations) : expandedKeys}
        switcherIcon={<CaretDownFilled style={{ fontSize: '12px' }} />}
        treeData={organizationsToTree(organizations)}
        className="organizations-tree"
        onSelect={onOrganizationSelect}
        onExpand={keys => setExpandedKeys(keys)}
      />
      <PermissionPlaceholder permissionTarget="write" customerId={customerId} organizationId={organizationId}>
        <NewOrgButton type="link" onClick={onNewOrgClick}>
          <PlusCircleFilled /> New Organization...
        </NewOrgButton>
      </PermissionPlaceholder>
    </>
  )
}

const NewOrgButton = styled(Button)`
  font-weight: bold;
  font-size: 12px;
  padding: 0;
`
