import {
  CommentOutlined,
  DeploymentUnitOutlined,
  DollarCircleOutlined,
  FieldTimeOutlined,
  SafetyCertificateOutlined,
  UnlockOutlined,
} from '@ant-design/icons'
import { Space, Typography } from 'antd'
import React from 'react'
import { EnloeLogoSVG, VHPCLogoSVG } from './assets'

export const ProjectInformation: React.FC = () => {
  return (
    <Space size={32} direction="vertical">
      <div>
        <Typography.Text style={{ fontSize: '24px' }}>
          Unleash the <strong>power</strong> of your peers
        </Typography.Text>
        <Typography.Paragraph style={{ fontSize: '16px', margin: 0 }}>
          We&apos;re on a mission to <strong>rebuild trust</strong> in healthcare by creating the industry&apos;s first
          peer-to-peer network.
        </Typography.Paragraph>
      </div>
      <Space size={16} direction="vertical">
        <Space size={16}>
          <UnlockOutlined style={{ fontSize: '24px' }} />
          <Typography.Text style={{ fontSize: '14px' }}>
            Get access to the highest impact initiatives recommended by your peers. Learn about the impacts beyond
            price:
          </Typography.Text>
        </Space>
        <Space size={16} direction="vertical" style={{ paddingLeft: '40px' }}>
          <ValueElementItem
            icon={<FieldTimeOutlined style={{ fontSize: '24px' }} />}
            content="Productivity Impact"
            description="30 minutes saved pre-op"
          />
          <ValueElementItem
            icon={<SafetyCertificateOutlined style={{ fontSize: '24px' }} />}
            content="Clinical Impact"
            description="22% reduction in recovery time"
          />
          <ValueElementItem
            icon={<CommentOutlined style={{ fontSize: '24px' }} />}
            content="Coordination Efficiencies"
            description="10% reduction in SKU count"
          />
          <ValueElementItem
            icon={<DollarCircleOutlined style={{ fontSize: '24px' }} />}
            content="Cost Savings"
            description="18% reduction"
          />
        </Space>
        <Space size={16}>
          <DeploymentUnitOutlined style={{ fontSize: '24px' }} />
          <Typography.Text style={{ fontSize: '14px' }}>
            Join an existing network, or create your own and invite others.
          </Typography.Text>
        </Space>
      </Space>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'end' }}>
        <VHPCLogoSVG height="50px" />
        <EnloeLogoSVG height="50px" />
      </div>
    </Space>
  )
}

const ValueElementItem: React.FC<{ icon: React.ReactElement; content: string; description: string }> = ({
  icon,
  content,
  description,
}) => {
  return (
    <Space size={16} direction="horizontal">
      {icon}
      <Space.Compact direction="vertical">
        <Typography.Text style={{ fontWeight: 700, fontSize: '14px' }}>{content}</Typography.Text>
        <Typography.Text style={{ fontSize: '14px' }}>{`"${description}"`}</Typography.Text>
      </Space.Compact>
    </Space>
  )
}
