import {
  ApartmentOutlined,
  DeleteFilled,
  EditOutlined,
  MoreOutlined,
  PlusOutlined,
  ReloadOutlined,
  SearchOutlined,
} from '@ant-design/icons'
import { CheckCircleOutlined } from '@ant-design/icons/lib'
import { Button, Col, Dropdown, Input, Menu, Row, Skeleton, Tag, message } from 'antd'
import React, { ReactNode, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useNavigate } from 'react-router'
import styled from 'styled-components'
import { useActivateCustomerMutation, useDeactivateCustomerMutation, useQueryCustomersWithStats } from '../../../api'
import { Customer, GetCustomersByCriteriaInput } from '../../../api/gen/models'
import { useRebuildQuicksightPermissionsMutation } from '../../../api/hooks/dashboards'
import * as dt from '../../../ui/design-tokens/tokens'
import { ContextMenuButton } from '../../components/Buttons'
import { Card } from '../../components/Card'
import { UserAvatar } from '../../components/UserAvatar'
import { UserManagementModal } from '../../components/UserManagementModal'
import { useDebounce } from '../../components/useDebounce'
import { BaseManagePageLayout } from '../BaseManagePageLayout'
import { CreateEditCustomerModal } from './CreateEditCustomerModal'
import { AddOrganizationToCustomerModal } from './forms/AddOrganizationToCustomerModal'

export const ClientManagementPage: React.FC = () => {
  const [showNewClientForm, setShowNewClientForm] = useState(false)
  const [userManagementModalVisible, setUserManagementModalVisible] = useState(false)
  const [userManagementDefaultCustomerId, setUserManagementDefaultCustomerId] = useState<string>()

  const [customersQueryInput, setCustomersQueryInput] = useState<GetCustomersByCriteriaInput>({})
  const debouncedQueryInput = useDebounce(customersQueryInput, 800)

  const queryArgs = { input: debouncedQueryInput }

  const { data, loading, error, refetch } = useQueryCustomersWithStats({ variables: queryArgs })
  useEffect(() => {
    if (error) {
      message.error(error.message)
    }
  }, [error])

  const customers = (data && data.customersWithStats) || []

  const [deactivateCustomer] = useDeactivateCustomerMutation()
  const [activateCustomer] = useActivateCustomerMutation()
  const [rebuildQuicksightPermissions] = useRebuildQuicksightPermissionsMutation()

  const [showNewOrganizationForm, setShowNewOrganizationForm] = useState(false)
  const [showEditCustomerForm, setShowEditCustomerForm] = useState(false)

  const [selectedCustomer, setSelectedCustomer] = useState<Customer>()

  return (
    <>
      <Helmet>
        <title>Customer Management</title>
      </Helmet>
      <BaseManagePageLayout
        title="Customers"
        extra={[
          <Button key="new-customer" type="primary" onClick={() => setShowNewClientForm(true)}>
            New Customer
          </Button>,
        ]}>
        <ClientSearchInput
          placeholder="Search for a client"
          onChange={e => {
            setCustomersQueryInput({ searchText: e.target.value })
          }}
          prefix={<SearchOutlined style={{ color: dt.ColorNeutral50 }} />}
          style={{ width: '328px' }}
        />
        {loading ? (
          <Skeleton active={true} />
        ) : (
          <FlexClientList>
            {customers.map(customer => (
              <ClientCard
                customer={customer}
                key={customer.id}
                onEditCustomer={() => {
                  setShowEditCustomerForm(true)
                  setSelectedCustomer(customer)
                }}
                onAddUser={() => {
                  setUserManagementDefaultCustomerId(customer.id)
                  setUserManagementModalVisible(true)
                }}
                onActiveClick={() => {
                  if (customer.isActive) {
                    deactivateCustomer({ variables: { input: { id: customer.id } } }).then(
                      ({ data: deactivateCustomerResult, errors }) => {
                        if (deactivateCustomerResult) {
                          message.success('Deactivated')
                        } else if (errors) {
                          message.error(errors[0].message)
                        }
                      },
                    )
                    return
                  }
                  activateCustomer({ variables: { input: { id: customer.id } } }).then(
                    ({ data: activateCustomerResult, errors }) => {
                      if (activateCustomerResult) {
                        message.success('Activated')
                      } else if (errors) {
                        message.error(errors[0].message)
                      }
                    },
                  )
                }}
                onAddNewOrganization={() => {
                  setSelectedCustomer(customer)
                  setShowNewOrganizationForm(true)
                }}
                onRebuildQSPermission={() => {
                  rebuildQuicksightPermissions({ variables: { customerId: customer.id } }).then(resp => {
                    if (resp.data?.rebuildQuicksightPermissions) {
                      message.success('Rebuilding user quicksight permissions has been queued..')
                    } else {
                      message.error('Failed to rebuild user quicksight permissions. Please try again.')
                    }
                  })
                }}
              />
            ))}
          </FlexClientList>
        )}
        {selectedCustomer && (
          <AddOrganizationToCustomerModal
            customer={selectedCustomer}
            visible={showNewOrganizationForm}
            onCancel={() => setShowNewOrganizationForm(false)}
            onFinished={() => {
              refetch().then()
              setShowNewOrganizationForm(false)
            }}
          />
        )}
        <CreateEditCustomerModal
          visible={showNewClientForm}
          onCancel={() => setShowNewClientForm(false)}
          onSuccess={() => {
            setShowNewClientForm(false)
          }}
          queryArgs={queryArgs}
        />
        <CreateEditCustomerModal
          isEditing={true}
          customer={selectedCustomer}
          visible={showEditCustomerForm}
          onCancel={() => setShowEditCustomerForm(false)}
          onSuccess={() => {
            setShowEditCustomerForm(false)
          }}
        />
        <UserManagementModal
          visible={userManagementModalVisible}
          onCancel={() => setUserManagementModalVisible(false)}
          defaultCustomerId={userManagementDefaultCustomerId}
          onFinished={() => {
            refetch().then()
            setUserManagementModalVisible(false)
          }}
        />
      </BaseManagePageLayout>
    </>
  )
}

type ClientCardProps = {
  customer: Customer
  onAddNewOrganization: () => any
  onActiveClick: () => any
  onEditCustomer: () => any
  onAddUser: () => any
  onRebuildQSPermission: () => any
}

const ClientCard: React.FC<ClientCardProps> = ({
  customer,
  onActiveClick,
  onAddNewOrganization,
  onEditCustomer,
  onAddUser,
  onRebuildQSPermission,
}) => {
  const navigate = useNavigate()

  const menu = (
    <Menu theme="dark">
      <Menu.Item
        key="rebuildQS"
        icon={<ReloadOutlined />}
        onClick={e => {
          e.domEvent.stopPropagation()
          onRebuildQSPermission()
        }}>
        Rebuild QS Permission
      </Menu.Item>
      <Menu.Item
        key="addOrganization"
        icon={<ApartmentOutlined />}
        onClick={e => {
          e.domEvent.stopPropagation()
          onAddNewOrganization()
        }}>
        Add Organization
      </Menu.Item>
      <Menu.Item
        key="addUser"
        icon={<PlusOutlined />}
        onClick={e => {
          onAddUser()
          e.domEvent.stopPropagation()
        }}>
        Add User
      </Menu.Item>
      <Menu.Item
        key="editCustomer"
        icon={<EditOutlined />}
        onClick={e => {
          e.domEvent.stopPropagation()
          onEditCustomer()
        }}>
        Edit Customer
      </Menu.Item>
      <Menu.Item
        key="toggleCustomer"
        icon={customer.isActive ? <DeleteFilled style={{ color: 'red' }} /> : <CheckCircleOutlined />}
        onClick={e => {
          onActiveClick()
          e.domEvent.stopPropagation()
        }}>
        {customer.isActive ? <span style={{ color: 'red' }}>Deactivate Customer</span> : <span>Activate Customer</span>}
      </Menu.Item>
    </Menu>
  )

  const extra = [
    <Dropdown key={1} overlay={menu} trigger={['click']}>
      <ContextMenuButton
        shape="circle"
        onClick={e => {
          e.stopPropagation()
        }}>
        <MoreOutlined />
      </ContextMenuButton>
    </Dropdown>,
  ]

  return (
    <ClientCardWrapper
      title={customer.name}
      extra={extra}
      width={288}
      onClick={() => navigate(`/manage/customer/${customer.id}`)}>
      <ClientCardBody>
        <ActiveOrgCountRow>
          {!customer.isActive && (
            <Col>
              <Tag color="#edeeef" style={{ color: '#172237' }}>
                · Inactive
              </Tag>
            </Col>
          )}
          <Col>
            <OrgCount>{`${customer.totalOrganizations} Organizations`}</OrgCount>
          </Col>
        </ActiveOrgCountRow>
        {customer.isActive ? (
          <>
            <CardMetrics>
              <CardMetric title="total spend">{`${0}`}</CardMetric>
              <CardMetric title="line count">{`${0}`}</CardMetric>
            </CardMetrics>
            <CardMetrics>
              <CardMetric title="admin">
                {customer.topUsers && customer.topUsers.length ? (
                  <UserAvatar user={customer.topUsers[0]} size={32} />
                ) : (
                  'No admins'
                )}
              </CardMetric>
              <CardMetric title="total user">{`${customer.totalUsers}/${customer.maxUser}`}</CardMetric>
            </CardMetrics>
          </>
        ) : (
          ''
        )}
      </ClientCardBody>
    </ClientCardWrapper>
  )
}

const CardMetric: React.FC<{ title: string; children?: ReactNode }> = ({ title, children }) => (
  <MetricWrapper>
    <MetricTitle>{title}</MetricTitle>
    <MetricContent>{children}</MetricContent>
  </MetricWrapper>
)

const OrgCount = styled.div`
  width: 100%;
  height: 16px;
  font-size: 12px;
`

const CardMetrics = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
  flex-wrap: wrap;
  justify-content: space-between;
`

const ClientSearchInput = styled(Input)`
  width: 328px;
`

const MetricWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 108px;
`

const MetricTitle = styled.div`
  text-transform: uppercase;
  font-size: 10px;
  width: 100%;
`

const MetricContent = styled.div`
  margin-top: 4px;
  width: 100%;
  font-size: 16px;
  height: 24px;
`

const FlexClientList = styled.div`
  display: flex;
  margin-top: 24px;
  flex-wrap: wrap;
`

const ClientCardWrapper = styled(Card)`
  margin-right: 24px;
  margin-bottom: 24px;
  cursor: pointer;
`

const ClientCardBody = styled.div`
  margin-top: 8px;
`

const ActiveOrgCountRow = styled(Row)`
  margin-bottom: 16px;
  align-items: center;
`
