import { Divider, Space, Typography } from 'antd'
import React from 'react'
import { Helmet } from 'react-helmet'
import styled from 'styled-components'
import { OfficialCurvoLogoSVG } from '../../../ui/svgs'
import { OnboardingForm } from './OnboardingForm'
import { ProjectInformation } from './ProjectInformation'

export const Onboarding: React.FC = () => {
  return (
    <Wrapper>
      <Helmet>
        <title>Project Aperture</title>
      </Helmet>
      <Background />
      <ContentWrapper>
        <Header direction="horizontal" size={14} split={<Divider type="vertical" />}>
          <Logo>
            <OfficialCurvoLogoSVG height="35" width="130" />
          </Logo>
          <Title>COLLABORATION</Title>
        </Header>
        <Body direction="horizontal">
          <BodyColumn>
            <OnboardingForm />
          </BodyColumn>
          <BodyColumn style={{ backgroundColor: 'var(--color-neutral-20)' }}>
            <ProjectInformation />
          </BodyColumn>
        </Body>
      </ContentWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  min-height: 100vh;
  width: 100%;
  background-color: var(--color-neutral-40);
  display: flex;
  justify-content: center;
`

const Background = styled.div`
  background-color: var(--color-neutral-90);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 420px;
`
const ContentWrapper = styled.div`
  width: 1000px;
  background-color: transparent;
  z-index: 1;
  margin-top: 100px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 48px;
`
const Header = styled(Space)`
  height: 40px;

  .ant-divider {
    border-left: 1px solid var(--color-neutral-70);
    height: 35px;
    top: 0;
  }
`
const Logo = styled.div`
  height: 35px;
`
const Title = styled(Typography.Text)`
  text-transform: uppercase;
  font-size: 36px;
  font-weight: 200;
  color: #ffffff;
  opacity: 0.66;
`

const Body = styled(Space.Compact)`
  display: flex;
  width: 100%;
  background-color: #ffffff;
`

const BodyColumn = styled.div`
  display: flex;
  padding: 40px;
  width: 50%;
  position: relative;
`

export default Onboarding
