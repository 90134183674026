import React from 'react'
import { Menu } from 'antd'
import styled from 'styled-components'
import { BulbOutlined, ReadOutlined, TeamOutlined } from '@ant-design/icons'
import { CustomRedirectSVG } from '../../ui/svgs'

export const HelpMenu = () => {
  return (
    <StyledMenu
      items={[
        {
          label: (
            <a href="https://info.curvolabs.com/knowledge" target="_blank" className="card-container">
              <div className="card-icon-container knowledge-styles">
                <ReadOutlined className="card-icon" />
              </div>
              <div className="text-container">
                <div className="card-header-container">
                  <div className="display-sm">Knowledge Base</div>
                  <div className="redirect-icon">
                    <CustomRedirectSVG />
                  </div>
                </div>
                <div className="text-primary-base">
                  Search our collection of information and FAQs about the Curvo platform.
                </div>
              </div>
            </a>
          ),
          key: '0',
        },
        {
          label: (
            <a href="https://curvo-labs-prod.ideas.aha.io" target="_blank" className="card-container">
              <div className="card-icon-container idea-styles">
                <BulbOutlined className="card-icon" />
              </div>
              <div className="text-container">
                <div className="card-header-container">
                  <div className="display-sm">Ideas Portal</div>
                  <div className="redirect-icon">
                    <CustomRedirectSVG />
                  </div>
                </div>
                <div className="text-primary-base">Submit, comment, and vote on Curvo product ideas.</div>
              </div>
            </a>
          ),
          key: '1',
        },
        {
          label: (
            <a href="https://info.curvolabs.com/tickets-view" target="_blank" className="card-container">
              <div className="card-icon-container community-styles">
                <TeamOutlined className="card-icon" />
              </div>
              <div className="text-container">
                <div className="card-header-container">
                  <div className="display-sm">Customer Support</div>
                  <div className="redirect-icon">
                    <CustomRedirectSVG />
                  </div>
                </div>
                <div className="text-primary-base">
                  Open a support ticket or request a demo from our customer success team.
                </div>
              </div>
            </a>
          ),
          key: '2',
        },
      ]}
    />
  )
}

const StyledMenu = styled(Menu)`
  --transition-time: 200ms;
  width: 370px;
  margin: 0;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  li {
    margin: 0;
    padding: 0;
  }

  .card-container {
    margin: 0;
    padding: 1rem;
    border-radius: 0.25rem;

    &:hover,
    &:focus-visible {
      text-decoration: none;
      background: ${props => props.theme.ColorNeutral20};
      .redirect-icon {
        opacity: 100% !important;
        transition: var(--transition-time);
      }
    }

    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 1rem;

    .card-icon-container {
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      position: relative;
      top: 6px; // align top of icon with top of header

      &.knowledge-styles {
        background: ${props => props.theme.ColorBlue70};
      }
      &.idea-styles {
        background: ${props => props.theme.ColorGreen70};
      }
      &.community-styles {
        background: ${props => props.theme.ColorAccentVolcano70};
      }

      .card-icon {
        font-size: 22px;
        color: ${props => props.theme.ColorNeutral0};
      }
    }

    .card-header-container {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .redirect-icon {
        display: flex;
        opacity: 0%;
        transition: var(--transition-time);
      }
    }

    .text-container {
      display: flex;
      gap: 0.25rem;
      flex-direction: column;
    }
  }
`
