import React from 'react'
import { useNavigate } from 'react-router'
import { InitiativeSortEnum, useInitiativesQuery } from '../../../api'
import { InitiativeCard } from '../../ProjectAperture/Initiatives/InitiativeCard'
import { CreateItemCard } from '../components/CreateItemCard'
import { HomeCarousel } from '../components/HomeCarousel'
import { HomeSection } from '../components/HomeSection'

export const InitiativesSection: React.FC = () => {
  const navigate = useNavigate()

  const { data, loading } = useInitiativesQuery({
    variables: {
      input: {
        sort: [
          { field: InitiativeSortEnum.Interested, dir: 'DESC' },
          { field: InitiativeSortEnum.CreatedAt, dir: 'DESC' },
        ],
      },
      pagination: {
        limit: 8,
        offset: 0,
      },
    },
    fetchPolicy: 'network-only',
  })

  return (
    <HomeSection title="Initiatives" showAllLink={'/aperture/initiatives'}>
      <HomeCarousel
        loading={loading}
        getRowKey={item => item.id}
        items={data?.initiatives.hits || []}
        renderItem={item => <InitiativeCard initiative={item} />}
        slidesToShow={2}
        extraItem={
          <CreateItemCard
            title="Add an Initiative"
            description="Tell your peers about a project you've done that made a Clinical or Financial impact for patients."
            onClick={() => navigate('/aperture/initiatives/create')}
          />
        }
      />
    </HomeSection>
  )
}
