import { gql, QueryHookOptions, useQuery } from '@apollo/client'
import { Query, QueryOrganizationTypesArgs } from '../gen/models'

export const OrganizationTypeFragment = gql`
  fragment OrganizationTypeFragment on OrganizationType {
    id
    name
  }
`

const ORGANIZATION_TYPES = gql`
  query organizationTypes($input: GetOrganizationTypeByCriteriaInput!) {
    organizationTypes(input: $input) {
      ...OrganizationTypeFragment
    }
  }
  ${OrganizationTypeFragment}
`

export function useOrganizationTypesQuery(
  basicOptions: QueryHookOptions<Pick<Query, 'organizationTypes'>, QueryOrganizationTypesArgs>,
) {
  return useQuery(ORGANIZATION_TYPES, basicOptions)
}
