import { useAuth0 } from '@auth0/auth0-react'
import { Form, Input, message, Modal } from 'antd'
import FormItem from 'antd/es/form/FormItem'
import { ModalProps } from 'antd/es/modal'
import React, { useCallback, useEffect, useState } from 'react'
import { ME_QUERY, User } from '../../api'
import { useUpdateMeMutation } from '../../api/hooks/users'

type PasswordInput = {
  email?: string
  pass?: string
  newEmail?: string
}

type Props = {
  user: User
  onSuccess: (newEmail?: string) => any
} & Pick<ModalProps, 'visible' | 'onCancel'>

export const UserEmailModal: React.FC<Props> = ({ user, onSuccess, visible, onCancel }) => {
  const [values, setValues] = useState<PasswordInput>()
  const [requestingToken, setRequestingToken] = useState<boolean>(false)
  const [submitting, setSubmitting] = useState<boolean>(false)
  const [form] = Form.useForm()

  const { getAccessTokenWithPopup, getIdTokenClaims } = useAuth0()

  const [updateUser] = useUpdateMeMutation()

  const submit = useCallback(async () => {
    try {
      await form.validateFields()
    } catch (e) {
      return
    }

    setRequestingToken(true)
    setSubmitting(true)
    await getAccessTokenWithPopup({
      authorizationParams: {
        max_age: 0,
        login_hint: user?.email,
        prompt: 'login',
        ignoreCache: true,
      },
    }).finally(() => setRequestingToken(false))

    const claims = await getIdTokenClaims()

    const input = {
      email: String(form.getFieldValue('newEmail')),
      token: claims?.__raw!,
    }
    user &&
      user.id &&
      (await updateUser({
        variables: { input },
        refetchQueries: [{ query: ME_QUERY }],
      })
        .then(() => {
          message.success('Please check your new email address to confirm.')
          onSuccess(input.email)
        })
        .catch(() => message.error('Cannot update your email'))
        .finally(() => setSubmitting(false)))
  }, [getAccessTokenWithPopup, getIdTokenClaims, user, updateUser, onSuccess, form])

  useEffect(() => {
    form.resetFields()
  }, [form, visible])

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      onOk={submit}
      title={user ? 'Edit User' : 'Invite New User'}
      okButtonProps={{ loading: submitting }}>
      {!requestingToken ? (
        <Form
          form={form}
          size="middle"
          layout="vertical"
          onValuesChange={v => {
            setValues({ ...values, ...v })
          }}>
          <FormItem
            label="New Email"
            name="newEmail"
            rules={[
              {
                type: 'email',
                message: 'The input is not valid email!',
              },
              {
                required: true,
                message: 'Please input your new email',
              },
            ]}>
            <Input />
          </FormItem>
        </Form>
      ) : (
        <span>Please complete re-authentication before continuing.</span>
      )}
    </Modal>
  )
}
