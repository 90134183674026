import { Spin } from 'antd'
import React, { CSSProperties } from 'react'
import styled from 'styled-components'

export const PageLoading = ({ style }: { style?: CSSProperties }) => (
  <SpinWrapper style={style}>
    <Spin size="large" />
  </SpinWrapper>
)

const SpinWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`
